import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CourseModuleListComponent } from '@components/pages';

function CourseModuleManagement() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Módulos de curso');
  }, []);

  return (
    <div className="card">
      <CourseModuleListComponent />
    </div>
  );
}

export default CourseModuleManagement;
