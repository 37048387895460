import React, { useState, useEffect } from 'react';
import { ExamService, CourseService, CourseModuleService } from '@services';
import TextEditor from '@components/shared/texteditor/TextEditor';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';
import { v4 as uuidv4 } from 'uuid';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.get(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

async function createEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const ExamCreate = ({ onUpdatedEntity, setIsOpen }) => {
  const { openSnackbar } = useSnackbar();

  const initialState = {
    name: '',
    courseId: '',
    course: {},
    module: '',
    is_active: false,
    min_score: 0,
    time_limit: 0,
    max_attempts: 0,
    questionsTemp: [],
    questions: '',
  };
  const [entity, setEntity] = useState(initialState);

  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const responseCourse = await getEntity({ all: true }, CourseService);

      if (responseCourse) {
        const responseModule = await getEntity(
          { courseid: responseCourse.id },
          CourseModuleService,
        );
        setModules(responseModule.result);
      }
      setCourses(responseCourse.result);
      setModules([]);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (entity.courseId) {
        const responseModule = await getEntity(
          { courseid: entity.courseId },
          CourseModuleService,
        );
        setModules(responseModule.result);
      } else {
        setModules([]);
      }
    };

    fetchData().catch(console.error);
  }, [entity.courseId]);

  const handleaddQuestionItemOnClick = (e, type) => {
    e.preventDefault();
    const defaultItem = {
      order: '',
      question_statement: '',
      answer: '',
      items: [{ body: '' }],
      type: type,
      score: '',
    };
    setEntity((prevUserData) => ({
      ...prevUserData,
      questionsTemp: [...prevUserData.questionsTemp, defaultItem],
    }));
  };

  const handleTextEditChange = (data) => {
    setEntity((prevUserData) => {
      const updatedQuestionsTemp = [...prevUserData.questionsTemp];

      if (updatedQuestionsTemp[data.index]) {
        updatedQuestionsTemp[data.index].question_statement = data.model;
      }

      return {
        ...prevUserData,
        questionsTemp: updatedQuestionsTemp,
      };
    });
  };

  const handleOptionsChange = (e, questionIndex, itemIndex, newValue) => {
    e.preventDefault();

    setEntity((prevEntity) => {
      const newEntity = { ...prevEntity };
      if (
        newEntity.questionsTemp[questionIndex] &&
        newEntity.questionsTemp[questionIndex].items[itemIndex]
      ) {
        newEntity.questionsTemp[questionIndex].items[itemIndex].body = newValue;
      }
      return newEntity;
    });
  };
  const handleUpdateDataQuestionsTemp = (e, index, propName, newValue) => {
    e.preventDefault();

    const updatedQuestionsTemp = [...entity.questionsTemp];

    updatedQuestionsTemp[index][propName] = newValue;

    setEntity((prevEntity) => ({
      ...prevEntity,
      questionsTemp: updatedQuestionsTemp,
    }));
  };

  const removeQuestionOnClick = (e, indexToRemove) => {
    e.preventDefault();
    setEntity((prevData) => ({
      ...prevData,
      questionsTemp: prevData.questionsTemp.filter(
        (_, index) => index !== indexToRemove,
      ),
    }));
  };

  const addOptionItemOnClick = (e, entity, index) => {
    e.preventDefault();
    const defaultItem = { body: '' };

    setEntity((prevUserData) => {
      const updatedQuestionsTemp = [...prevUserData.questionsTemp];

      if (updatedQuestionsTemp[index]) {
        updatedQuestionsTemp[index].items = [...entity, defaultItem];
      }

      return {
        ...prevUserData,
        questionsTemp: updatedQuestionsTemp,
      };
    });
  };
  const removeOptionOnClick = (e, indexEntity, indexOption) => {
    e.preventDefault();
    setEntity((prevUserData) => {
      const updatedQuestionsTemp = [...prevUserData.questionsTemp];

      if (
        updatedQuestionsTemp[indexEntity] &&
        updatedQuestionsTemp[indexEntity].items
      ) {
        updatedQuestionsTemp[indexEntity].items = updatedQuestionsTemp[
          indexEntity
        ].items.filter((_, index) => index !== indexOption);
      }

      return {
        ...prevUserData,
        questionsTemp: updatedQuestionsTemp,
      };
    });
  };

  const indexToLetter = (index) => {
    var alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    index += 1;
    var printToLetter = function (number) {
      var charIndex = number % alphabet.length;
      var quotient = number / alphabet.length;
      if (charIndex - 1 === -1) {
        charIndex = alphabet.length;
        quotient--;
      }
      result = alphabet.charAt(charIndex - 1) + result;
      if (quotient >= 1) {
        printToLetter(parseInt(quotient));
      }
    };

    printToLetter(index);
    return result;
  };

  const handleValidation = (obj) => {
    const camposObligatorios = ['name', 'courseId'];
    return camposObligatorios.every((campo) => {
      const valor = obj[campo];
      return valor !== null && valor !== undefined && valor !== '';
    });
  };

  const confirmOnClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const getCourse = courses.find((_entity) => _entity.id === entity.courseId);
    if (!handleValidation(entity)) {
      openSnackbar('Ingresa un título para el examen y un curso.', 'info');
      setIsLoading(false);
      return;
    }

    try {
      const createData = {
        name: entity.name,
        course_id: entity.courseId,
        course: getCourse,
        module_id: entity.module,
        is_active: entity.is_active,
        min_score: entity.min_score,
        time_limit: entity.time_limit,
        max_attempts: entity.max_attempts,
        questions: JSON.stringify(entity.questionsTemp),
      };

      const response = await createEntity(createData, ExamService);

      if (!response) {
        openSnackbar('Ocurrió un error al crear el examen!', 'error');
        return;
      }

      openSnackbar('Examen creado con éxito', 'success');

      onUpdatedEntity('create', response.result);
      setIsOpen(false);
    } catch (error) {
      openSnackbar('Ocurrió un error inesperado!', 'error');
    } finally {
      isLoading(false);
    }
  };

  return (
    <>
      {/* Title */}
      <section className="col-12 mx-auto d-block mb-4">
        <div className="container pt-2">
          <h4 className="card-title text-center">Crear nuevo Examen</h4>
        </div>
      </section>

      {/* Basic Information */}
      <section className="col-12 mx-auto d-block mb-3">
        <div className="card">
          <div className="container pt-2">
            <h4 className="card-title text-center">Información básica</h4>

            <form>
              {/* Name Field */}
              <div className="form-group mt-3">
                <label htmlFor="entityName">Nombre de examen</label>
                <input
                  type="text"
                  className="form-control"
                  id="entityName"
                  autoComplete="off"
                  value={entity.name}
                  onChange={(e) =>
                    setEntity({ ...entity, name: e.target.value })
                  }
                />
              </div>
              <section className="row mt-3">
                {/* Course Field */}
                <div className="form-group col-6">
                  <label htmlFor="entityCourse">Curso</label>
                  <select
                    className="form-select"
                    id="entityCourse"
                    value={entity.courseId}
                    onChange={(e) =>
                      setEntity({ ...entity, courseId: e.target.value })
                    }
                  >
                    <option value="">Seleccione un curso</option>
                    {courses.map((course, ExamCreate) => (
                      <option key={ExamCreate} value={course.id}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Module Field */}
                <div className="form-group col-6">
                  <label htmlFor="entityModule">Módulo</label>
                  <select
                    className="form-select"
                    id="entityModule"
                    value={entity.module}
                    onChange={(e) =>
                      setEntity({ ...entity, module: e.target.value })
                    }
                  >
                    <option value="">Seleccione un módulo</option>
                    {modules.length &&
                      modules.map((module, ExamCreate) => (
                        <option key={ExamCreate} value={module.id}>
                          {module.name}
                        </option>
                      ))}
                  </select>
                </div>
              </section>
              <section className="row d-flex justify-content-around align-items-end">
                {/* Is Active Field */}
                <div className="form-group col-12 col-md-3">
                  <div className="col-12 d-flex mt-3">
                    <label htmlFor="entityIsActive">¿Está activo?</label>
                  </div>
                  <div className="form-check form-switch d-flex mt-1">
                    <input
                      onChange={(e) =>
                        setEntity({ ...entity, is_active: !entity.is_active })
                      }
                      value={entity.is_active}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={entity.is_active}
                    />
                    <label className="form-check-label ms-1">
                      {entity.is_active ? 'Si' : 'No'}
                    </label>
                  </div>
                </div>

                {/* Min Score Field */}
                <section className="form-group col-12 col-md-3 mt-3">
                  <label htmlFor="entityMinScore">
                    Puntaje mínimo para aprobar
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="entityMinScore"
                    value={entity.min_score}
                    onChange={(e) =>
                      setEntity({ ...entity, min_score: e.target.value })
                    }
                  />
                </section>

                {/* Time Limit Field */}
                <section className="form-group col-12 col-md-3 mt-md-4 mt-3">
                  <label htmlFor="entityTimeLimit">
                    Límite de tiempo (mins)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="entityTimeLimit"
                    value={entity.time_limit}
                    onChange={(e) =>
                      setEntity({ ...entity, time_limit: e.target.value })
                    }
                  />
                </section>

                {/* Max Attempts Field */}
                <div className="form-group col-12 col-md-3 mt-md-4 pt-md-2 mt-3">
                  <label htmlFor="entityMaxAttempts">Max intentos</label>
                  <input
                    type="number"
                    className="form-control"
                    id="entityMaxAttempts"
                    value={entity.max_attempts}
                    onChange={(e) =>
                      setEntity({ ...entity, max_attempts: e.target.value })
                    }
                  />
                </div>
              </section>
            </form>
          </div>
        </div>
      </section>

      {/* Questions */}
      <section className="col-12 mx-auto d-block mb-3">
        <div className="container pt-2">
          <h4 className="card-title text-center">Preguntas</h4>
          <section className="d-flex flex-column">
            {entity.questionsTemp.map((_entity, index) => (
              <section key={index} className="border p-3 my-2">
                <div className="d-flex justify-content-between">
                  {(!_entity.type || _entity.type === 'question') && (
                    <h5>Pregunta #{index + 1}</h5>
                  )}
                  {_entity.type === 'textblock' && <h5>Bloque #{index + 1}</h5>}
                  <button
                    className="btn btn-outline-danger btn-rounded d-flex justify-content-center align-items-center"
                    onClick={(e) => removeQuestionOnClick(e, index)}
                  >
                    <i className="fe-trash fs-4"></i>
                  </button>
                </div>

                {(!_entity.type || _entity.type === 'question') && (
                  <article>
                    <div className="form-row row mt-3 ">
                      <div className="form-group col-12 col-md-4 mt-2">
                        <label className="form-control-label">Orden</label>
                        <input
                          type="number"
                          autoComplete="off"
                          className="form-control"
                          id={`questions-order-input-${index}`}
                          placeholder=""
                          value={_entity.order}
                          onChange={(e) => {
                            handleUpdateDataQuestionsTemp(
                              e,
                              index,
                              'order',
                              e.target.value,
                            );
                          }}
                        />
                      </div>
                      <article className="form-group col-12 col-md-4">
                        <label
                          className="form-control-label"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Es un valor numérico para hacer sumatoria de puntos"
                        >
                          Puntaje
                          <i className="mdi mdi-help-circle-outline fs-3 ms-1"></i>
                        </label>
                        <div className="d-flex">
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control"
                            id={`questions-score-input-${index}`}
                            placeholder=""
                            value={_entity.score}
                            onChange={(e) => {
                              handleUpdateDataQuestionsTemp(
                                e,
                                index,
                                'score',
                                e.target.value,
                              );
                            }}
                          />
                        </div>
                      </article>
                      <article className="form-group col-12 col-md-4">
                        <label
                          className="form-control-label"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Separa cada pregunta por el simbolo |, ejemplo: 'A|B|C'"
                        >
                          Opciones correctas
                          <i className="mdi mdi-help-circle-outline fs-3 ms-1"></i>
                        </label>
                        <div className="d-flex">
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id={`questions-answer-input-${index}`}
                            placeholder=""
                            value={_entity.answer}
                            onChange={(e) => {
                              handleUpdateDataQuestionsTemp(
                                e,
                                index,
                                'answer',
                                e.target.value,
                              );
                            }}
                          />
                        </div>
                      </article>
                      <article className="form-group col-12">
                        <label className="font-weight-light bg-primary p-3  text-center text-white fs-4 mt-5  w-100">
                          Enunciado de pregunta
                        </label>
                        <TextEditor
                          id={uuidv4()}
                          index={index}
                          modelraw={
                            entity.questionsTemp[index].question_statement
                          }
                          onModelChange={handleTextEditChange}
                        />
                      </article>
                      <div className="col-12 col-md-12">
                        <label className="form-control-label mt-1">Opciones</label>
                        {_entity.items &&
                          _entity.items.map((_option, optionIndex) => (
                            <div key={optionIndex} className="form-group row">
                              <label className="col-1 col-form-label text-center">
                                {indexToLetter(optionIndex)}
                              </label>
                              <div className="col-10 mb-3">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control"
                                  id={`questions-options-input-${index}${optionIndex}`}
                                  placeholder=""
                                  value={_option.body}
                                  onChange={(e) => {
                                    handleOptionsChange(
                                      e,
                                      index,
                                      optionIndex,
                                      e.target.value,
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-1">
                                <button
                                  className="btn btn-outline-danger btn-rounded"
                                  onClick={(e) => {
                                    removeOptionOnClick(e, index, optionIndex);
                                  }}
                                >
                                  <i className="fe-trash fs-4"></i>
                                </button>
                              </div>
                            </div>
                          ))}
                        <div className="mt-3">
                          <button
                            className="btn btn-outline-primary"
                            onClick={(e) =>
                              addOptionItemOnClick(e, _entity.items, index)
                            }
                          >
                            Agregar opción
                          </button>
                        </div>
                      </div>
                    </div>
                  </article>
                )}
                {_entity.type === 'textblock' && (
                  <article>
                    <div className="form-row">
                      <div className="form-group col-12 col-md-4">
                        <label className="form-control-label">Orden</label>
                        <input
                          type="number"
                          autoComplete="off"
                          className="form-control"
                          id={`questions-order-input-${index}`}
                          placeholder=""
                          value={_entity.order}
                          onChange={(e) => {
                            handleUpdateDataQuestionsTemp(
                              e,
                              index,
                              'order',
                              e.target.value,
                            );
                          }}
                        />
                      </div>
                      <div className="form-group col-12 col-md-12">
                        <div className="dummy-header">
                          <label className="font-weight-light bg-primary p-3  text-center text-white fs-4 mt-5  w-100">
                            Enunciado de pregunta
                          </label>
                          <TextEditor
                            id={uuidv4()}
                            index={index}
                            modelraw={
                              entity.questionsTemp[index].question_statement
                            }
                            onModelChange={handleTextEditChange}
                          />
                        </div>
                      </div>
                    </div>
                  </article>
                )}
              </section>
            ))}

            <div className="mt-3">
              <button
                className="btn btn-primary me-2 mt-3"
                onClick={(e) => handleaddQuestionItemOnClick(e, 'question')}
              >
                Agregar pregunta
              </button>
              <button
                className="btn btn-primary me-2 mt-3"
                onClick={(e) => handleaddQuestionItemOnClick(e, 'textblock')}
              >
                Agregar bloque de texto
              </button>
            </div>
          </section>
        </div>
      </section>

      {/* Buttons */}
      <>
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <div className="col-12 mx-auto d-block mb-3">
            <div className="card">
              <div className="card-body">
                <div className="container pt-2 d-flex justify-content-center">
                  <button
                    title="Submit"
                    type="submit"
                    className="btn btn-primary  me-2"
                    onClick={(e) => confirmOnClick(e)}
                  >
                    <i className="mdi mdi-check"></i> Crear nuevo examen
                  </button>
                  <button
                    title="Cancel"
                    type="button"
                    className="btn btn-light btn-action ms-2"
                    onClick={() => setIsOpen(false)}
                  >
                    <i className="mdi mdi-close"></i> Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default ExamCreate;
