import React from 'react';
import { useAuth } from '../../hooks/useAuth.hook';

function SidebarAdmin() {
  const { user } = useAuth();

  return (
    <div className="left-side-menu">
      <div className="h-100 mt-4" data-simplebar>
        {/* <!-- User box --> */}
        <div className="user-box text-center">
          <div className="dropdown">
            <a
              href="#"
              className="user-name dropdown-toggle h5 mt-2 mb-1 d-block"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {user.firstname} {user.lastname}
            </a>
            <div className="dropdown-menu user-pro-dropdown">
              <a href="/admin/account/overview" className="dropdown-item notify-item">
                <i className="fe-user me-1"></i>
                <span>Mi cuenta</span>
              </a>
              <a href="/auth/lock-screen" className="dropdown-item notify-item">
                <i className="fe-lock me-1"></i>
                <span>Bloquear</span>
              </a>
              <a href="/auth/logout" className="dropdown-item notify-item">
                <i className="fe-log-out me-1"></i>
                <span>Salir</span>
              </a>
            </div>
          </div>

          <p className="text-muted left-user-info">{user.role.title}</p>

          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/auth/lock-screen">
                <i className="mdi mdi-power"></i>
              </a>
            </li>
          </ul>
        </div>

        {/* <!--- Sidemenu --> */}
        <div id="sidebar-menu">
          <ul id="side-menu">
            <li className="menu-title">Navegación</li>

            <li>
              <a href="/admin/dashboard">
                <i className="mdi mdi-view-dashboard-outline"></i>
                <span> Dashboard </span>
              </a>
            </li>

            <li className="menu-title mt-2">Apps</li>

            <li>
              <a href="/admin/user/course/management">
                <i className="fe-credit-card"></i>
                <span> Cursos pagados </span>
              </a>
            </li>

            <li>
              <a href="/admin/user/exam/management">
                <i className="fe-file-text"></i>
                <span> Asignación examenes </span>
              </a>
            </li>

            <li>
              <a href="/admin/interested/management">
                <i className="mdi mdi-account-plus"></i>
                <span> Interesados </span>
              </a>
            </li>

            <li>
              <a href="/admin/course/management">
                <i className="mdi mdi-laptop"></i>
                <span> Cursos </span>
              </a>
            </li>

            <li>
              <a href="/admin/course/module/management">
                <i className="mdi mdi-format-list-bulleted"></i>
                <span> Módulos </span>
              </a>
            </li>

            <li>
              <a href="/admin/course/resource/management">
                <i className="mdi mdi-file-pdf-box"></i>
                <span> Recursos </span>
              </a>
            </li>

            <li>
              <a href="/admin/exam/management">
                <i className="fe-edit-1"></i>
                <span> Exámenes </span>
              </a>
            </li>

            <li>
              <a href="/admin/certification/management">
                <i className="fe-award"></i>
                <span> Certificiones </span>
              </a>
            </li>

            <li>
              <a href="/admin/feedback/management">
                <i className="fe-message-circle"></i>
                <span> Comentarios </span>
              </a>
            </li>

            <li>
              <a href="/admin/user/management">
                <i className="mdi mdi-account-group"></i>
                <span> Usuarios</span>
              </a>
            </li>
            
            <li>
              <a href="/admin/landpage/management">
                <i className="fe-monitor"></i>
                <span> LandPage </span>
              </a>
            </li>  
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
