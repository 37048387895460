import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LandPageService } from '@services';
import TextEditor from '@components/shared/texteditor/TextEditor';
import Alert from '@components/shared/alert/Alert';
import Upload from '@components/shared/uploader/Uploader';
import { v4 as uuidv4 } from 'uuid';

async function getEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }
  return entityResponse.result;
}

function LandPagePreview() {
  const navigate = useNavigate();

  const initialState = {
    hero: {
      items: [{ attachment: { url: '' } }],
    },
    benefits: {
      items: [{ attachment: { url: '' } }],
    },
    about: {
      items: [{ attachment: { url: '' } }],
    },
    gallery: {
      items: [{ attachment: { url: '' } }],
    },
    team: {
      items: [{ attachment: { url: '' } }],
    },
    testimonials: {
      items: [{ attachment: { url: '' } }],
    },
    courses: {
      items: [{ attachment: { url: '' } }],
    },
    form: {
      items: [{ attachment: { url: '' } }],
    },
  };

  const [isAlertActive, setIsAlertActive] = useState(null);
  const [isconfirm, setIsConfirm] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');

  const [entity, setEntity] = useState(initialState);

  useEffect(() => {
    if (isconfirm === false) {
      setIsAlertActive(null);
      setIsConfirm(null);
    }
  }, [isconfirm, isAlertActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityResponse = await getEntity(
          {
            queryselector: 'landpage',
          },
          LandPageService,
        );
        if (!entityResponse) {
          openAlert(
            'No tienes una landpage creada',
            'habilita el modo de edicion para crearla',
            'info',
            true,
          );
        }

        if (entityResponse) {
          const dataLandPage = entityResponse[0];
          parseBody(dataLandPage);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  setTimeout((_) => {
    entity.benefits.items.forEach((question) => {
      question.description += ' ';
    });
  }, 200);

  const openAlert = (title, message, icon, isSuccess) => {
    setAlertMessage((prevData) => ({
      ...prevData,
      title,
      message,
      icon,
    }));
    setIsAlertActive(isSuccess);
  };

  const parseBody = (entity) => {
    if (!entity || !entity.body) {
      setEntity(initialState);
      return;
    }

    const bodyTemp = JSON.parse(entity.body);

    setEntity((prevData) => ({
      ...prevData,
      hero: bodyTemp.hero || {},
      benefits: bodyTemp.benefits || {},
      about: bodyTemp.about || {},
      gallery: bodyTemp.gallery || {},
      team: bodyTemp.team || {},
      testimonials: bodyTemp.testimonials || {},
      courses: bodyTemp.courses || {},
      form: bodyTemp.form || {},
    }));
  };

  const returnOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    navigate('/admin/');
  };

  return (
    <>
      {isAlertActive !== null && isAlertActive && (
        <Alert
          config={{
            title: alertMessage.title,
            description: alertMessage.message,
            typeIcon: alertMessage.icon,
            showCancelButton: false,
          }}
          setConfirm={setIsConfirm}
        />
      )}
      <div className="col-12 col-lg-10 mx-auto d-block mb-3 mt-2">
        <div className="container">
          <h4 className="card-title text-center">Vista previa del landpage</h4>
        </div>
      </div>

      {/* Hero banner */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Hero Banner</h4>

        {/* Items */}
        <section className="d-flex flex-column">
          {entity.hero.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <h5>Slide # {index + 1}</h5>
              <article>
                <div className="form-row row">
                  <div className="form-group col-12 col-md-6">
                    <label className="form-control-label">Título</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.title || ''}
                      disabled
                      id={`title-input- + ${index}`}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label className="form-control-label">Subtítulo</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.subtitle || ''}
                      id={`hero-subtitle-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-12 mt-3">
                    <label className="form-control-label">
                      Link vídeo Youtube
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.video_link || ''}
                      id={`hero-videobutton-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 mt-3">
                    <label className="form-control-label">Texto Botón</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.textbutton || ''}
                      id={`hero-textbutton-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 mt-3">
                    <label className="form-control-label">Enlace</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.link || ''}
                      id={`hero-link-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 mt-3">
                    <label
                      className="form-control-label"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="1366x768 pixeles, JPG o PNG, Max 2MB"
                    >
                      Imagen principal <i data-feather="help-circle"></i>
                    </label>
                    <div className="d-flex mt-3 pe-none">
                      <Upload
                        id={`"hero-image-input-" +${index}`}
                        imgpath={_entity.attachment.url}
                        enablepreview={false}
                        enablecopy={false}
                        enabledelete={false}
                        accept={'image/x-png,image/gif,image/jpeg'}
                      />
                    </div>
                  </div>
                  <div className="form-group col-12 mt-3">
                    <label className="form-control-label">Descripción</label>
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      value={_entity.description || ''}
                      id={`hero-description-input- + ${index}`}
                      disabled
                    />
                  </div>
                </div>
              </article>
            </div>
          ))}
        </section>
      </div>

      {/* Benefits */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Beneficios</h4>

        {/* Principal info */}
        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="benefits-title-input"
                value={entity.benefits.title || ''}
                disabled
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="benefits-subtitle-input"
                value={entity.benefits.subtitle || ''}
                disabled
              />
            </div>
          </div>
        </section>
        {/* Items */}
        <section className="d-flex flex-column mt-3">
          {entity.benefits.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <div className="d-flex justify-content-between">
                <h5>Beneficio # {index + 1} </h5>
              </div>

              <article>
                <div className="form-row row mt-3">
                  <div className="form-group col-12 col-md-10">
                    <label className="form-control-label">Título</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.title || ''}
                      id={`benefits-title-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-2">
                    <label className="form-control-label">ícono</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.icon || ''}
                      id={`benefits-icon-input- + ${index}`}
                      disabled
                    />
                  </div>

                  <div className="col-12">
                    <label className="font-weight-light bg-primary p-3  text-center text-white fs-4 mt-3  w-100">
                      Descripción
                    </label>

                    <div className="col-12 pe-none">
                      <TextEditor
                        id={uuidv4()}
                        index={index}
                        modelraw={_entity.description || ''}
                      />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </section>
      </div>

      {/* About */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Acerca de</h4>

        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                value={entity.about.title || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="about-title-input"
                disabled
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.about.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="about-subtitle-input"
                disabled
              />
            </div>
            <div className="form-group col-12 mt-3">
              <label className="form-control-label">Descripción</label>
              <textarea
                value={entity.about.description || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="about-description-input"
                disabled
              />
            </div>
          </div>
        </section>
      </div>

      {/* Gallery */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Galería</h4>

        {/* Principal info */}
        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="gallery-title-input"
                value={entity.gallery.title || ''}
                disabled
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="gallery-subtitle-input"
                value={entity.gallery.subtitle || ''}
                disabled
              />
            </div>
          </div>
        </section>
        {/* Items */}
        <section className="d-flex flex-column mt-3">
          {entity.gallery.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <div className="d-flex justify-content-between">
                <h5>Foto # {index + 1}</h5>
              </div>
              <article>
                <div className="form-row row mt-3 d-flex align-items-center">
                  <div className="form-group col-12 col-md-2 ">
                    <label className="form-control-label">Orden</label>
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control mt-3"
                      value={_entity.order || ''}
                      id={`gallery-order-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-10 ">
                    <label
                      className="form-control-label mt-3 mt-md-0"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="1366x768 pixeles, JPG o PNG, Max 2MB"
                    >
                      Imagen <i data-feather="help-circle"></i>
                    </label>
                    <div className="d-flex mt-3 pe-none">
                      <Upload
                        id={`gallery-image-input- +${index}`}
                        imgpath={_entity.attachment.url}
                        enablepreview={false}
                        enablecopy={false}
                        enabledelete={false}
                        accept={'image/x-png,image/gif,image/jpeg'}
                      />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </section>
      </div>

      {/* Team members */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Equipo</h4>

        {/* Principal info */}
        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                v-model="entity.team.title"
                value={entity.team.title || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="team-title-input"
                disabled
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.team.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="team-subtitle-input"
                disabled
              />
            </div>
          </div>
        </section>
        {/* Items */}
        <section className="d-flex flex-column mt-3">
          {entity.team.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <div className="d-flex justify-content-between">
                <h5>Docente # {index + 1}</h5>
              </div>
              <article>
                <div className="form-row row mt-3">
                  <div className="form-group col-12 col-md-2">
                    <label className="form-control-label">Orden</label>
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control"
                      id={`team-order-input- + ${index}`}
                      value={_entity.order || ''}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-5">
                    <label className="form-control-label">Nombre</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.name || ''}
                      id={`team-name-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-5">
                    <label className="form-control-label">Cargo</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.job || ''}
                      id={`team-job-input- + ${index}`}
                      disabled
                    />
                  </div>
                  <div className="form-group col-12 col-md-12 mt-3">
                    <label
                      className="form-control-label"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="1366x768 pixeles, JPG o PNG, Max 2MB"
                    >
                      Imagen <i data-feather="help-circle"></i>
                    </label>
                    <div className="d-flex mt-3 pe-none">
                      <Upload
                        id={`"team-image-input-" +${index}`}
                        imgpath={_entity.attachment.url}
                        enablepreview={false}
                        enablecopy={false}
                        enabledelete={false}
                        accept={'image/x-png,image/gif,image/jpeg'}
                      />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}
        </section>
      </div>

      {/* Testimonials */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Testimonios</h4>

        <section>
          <div className="form-row row mt-3">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                value={entity.testimonials.title || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="testimonials-title-input"
                disabled
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.testimonials.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="testimonials-subtitle-input"
                disabled
              />
            </div>
          </div>
        </section>
      </div>

      {/* Courses */}
      <div className="col-12 mx-auto d-block mb-3 mt-4">
        <h4 className="card-title text-center">Calendario de cursos</h4>

        <section>
          <div className="form-row row mt-3">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="courses-title-input"
                value={entity.courses.title || ''}
                disabled
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.courses.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="courses-subtitle-input"
                disabled
              />
            </div>
          </div>
        </section>
      </div>

      {/* Form */}
      <div className="col-12 mx-auto d-block mb-3 mt-4">
        <h4 className="card-title text-center">Formulario de contacto</h4>

        <section>
          <div className="form-row row mt-3">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="form-title-input"
                value={entity.form.title || ''}
                disabled
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.form.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="form-subtitle-input"
                disabled
              />
            </div>
          </div>
        </section>
      </div>

      {/* Buttons */}
      <div className="col-12 mx-auto d-block mb-3">
        <div className="card">
          <div className="card-body">
            <div className="container pt-2 d-flex justify-content-center">
              <button
                title="Cancel"
                type="button"
                className="btn btn-primary btn-action ms-2"
                onClick={(e) => returnOnClick(e)}
              >
                Regresar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandPagePreview;
