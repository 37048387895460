const formatHelpers = {
  formatPrice(value) {
    if (!value) {
      return '0';
    }
    value = value + '';
    const rawNum = value.replace(/[$,]/g, '');
    const nums = rawNum.split('.');
    const num = nums.length > 1 ? nums[0].split(/(?=(?:...)*$)/).join(',') : rawNum.split(/(?=(?:...)*$)/).join(',');
    if (rawNum.length > 0) {
      if (nums.length > 1) {
        const decimals = nums[1];
        return `${num}.${decimals}`;
      }
    }
    return num;
  },

  phoneNumber(value) {
    if (!value) {
      return '';
    }
    value = value + '';
    value = ('' + value).replace(/\D/g, '');

    let regex = '';

    if (value.length === 12) {
      regex = /(\d{2})(\d{3})(\d{3})(\d{4})$/;
    } else if (value.length === 11) {
      regex = /(\d{1})(\d{3})(\d{3})(\d{4})$/;
    }

    if (regex) {
      const match = value.match(regex);
      if (match) {
        const intlCode = `+${match[1]}`;
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
    }

    return value;
  },

  getAvatarUri() {
    const pathRoot = 'https://www.gravatar.com/avatar/';
    const defaultUri = '/private/img/90x90.jpg';
    const emailHash = window.md5(window.context.email);
    if (!window.context || !window.context.email) {
      return defaultUri;
    }
    return pathRoot + emailHash;
  },

  extractTextFromHtml(richText) {
    var span = document.createElement('span');
    span.innerHTML = richText;
    return span.textContent || span.innerText;
  },

  dateWithSeparators(value) {
    if (!value) {
      return '-/-/-';
    }
    const date = new Date(+value);
    return ('0' + date.getDate()).slice(-2) + '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '/' +
      date.getFullYear();
  },

  dateWithoutSeparators(value) {
    if (!value) {
      return '-/-/-';
    }
    const date = new Date(+value);
    return date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2);
  }
};

export default formatHelpers;