import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth.hook';
import { UserService } from '../../services/index';

async function getEntity (payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse.result;
}

function removeMyUser (arr, identity) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].dni === identity) {
      arr.splice(i, 1);
      i--;
    }
  }
  return arr;
}

function AccountOverview () {
  const { setPageName } = useOutletContext();
  const { user } = useAuth();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setPageName('Cuenta');
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dashboardPinnedUserList = await getEntity({
        pinnedDashboard: true
      }, UserService);

      let segregatedUserList = removeMyUser(dashboardPinnedUserList || [], user.identity);
      segregatedUserList = segregatedUserList.length > 3 ? segregatedUserList.slice(0, 3) : segregatedUserList;

      console.log(segregatedUserList);
      setUserList(segregatedUserList);
    }

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-md-6 mx-auto">
          <section className="card">
            <div className="card-body">
              <div className="dropdown float-end">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="mdi mdi-dots-vertical"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a href={user.role.name === 'admin' ? '/admin/account/profile' : '/business/account/profile'} className="dropdown-item">Editar</a>
                  <a href="/" className="dropdown-item">Eliminar cuenta</a>
                </div>
              </div>

              <h4 className="header-title mt-0 mb-3">Mi información</h4>

              <div className="d-flex flex-column">
                <img src={user.profile_pic_uri || '/assets/images/characters/character-unknown.svg'} className="rounded-circle avatar-xxl img-thumbnail mb-3 mx-auto bg-pic-profile" alt="profile-image" />

                <div className="text-start">
                  <p className="text-muted font-13"><strong>Nombre :</strong> <span className="ms-2">{user.fullname}</span></p>
                  <p className="text-muted font-13"><strong>Teléfono :</strong><span className="ms-2">{user.phone}</span></p>
                  <p className="text-muted font-13"><strong>Email :</strong> <span className="ms-2">{user.email}</span></p>
                  <p className="text-muted font-13"><strong>Identidad :</strong> <span className="ms-2">{user.identity}</span></p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="col-xl-6 col-md-6">
          <section className="card">
            <div className="card-body">
              <div className="dropdown float-end">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="mdi mdi-dots-vertical"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a href={user.role.name === 'admin' ? '/admin/user/list' : '/business/user/list'} className="dropdown-item">Administrar</a>
                </div>
              </div>

              <h4 className="header-title mt-0 mb-3">Mi equipo</h4>

              <ul className="list-group mb-0 user-list">
                {userList.map((_user) => (
                  <li className="list-group-item" key={_user.id}>
                    <a href="#" className="user-list-item">
                      <div className="user avatar-sm float-start me-2">
                        <img src={_user.profile_pic_uri || '/assets/images/characters/character-unknown.svg'} alt="" className="img-fluid rounded-circle border" />
                      </div>
                      <div className="user-desc">
                        <h5 className="name mt-0 mb-1">{_user.firstname + ' ' + _user.lastname}</h5>
                        <p className="desc text-muted mb-0 font-12">{_user.position_name}</p>
                      </div>
                    </a>
                  </li>
                ))}

                <li className="list-group-item">
                  <div className="d-grid my-2 user-list-item">
                    <a href={user.role.name === 'admin' ? '/admin/user/list' : '/business/user/list'} className="btn btn-primary btn-block">Ver todos</a>
                  </div>
                </li>

              </ul>
            </div>
          </section>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 mx-auto">
          <section className="card">
            <div className="card-body  flex-column justify-content-between">
              {/* Signout */}

              <h4 className="header-title mt-0 mb-3">Cierra sesión en todas partes</h4>

              <p>Cierra sesión donde quiera que tengas Emcodesa abierto, incluyendo la web, el celular, tu computadora de escritorio o cualquier otro dispositivo.</p>

              <blockquote className="blockquote bg-soft-danger h6 font-weight-normal">Importante: Esto no incluye dispositivos y apps de socios, como VCA apps and Amazon Alexa devices, smart watches. Para obtener más información sobre cómo cerrar tu sesión (o desvincular tu cuenta) de Mi Retail en un dispositivo fabricado por uno de nuestros socios, consulta el manual del fabricante del dispositivo.</blockquote>

              <a href="/auth/logout" className="btn btn-outline-danger btn-fw">Cierra sesión en todas partes</a>
            </div>
          </section>
        </div>
      </div>
    </>

  );
}

export default AccountOverview;