import React, { useEffect, useState } from 'react';
import {
  CourseResourceService,
  CourseService,
  CourseModuleService,
} from '@services';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import Alert from '@components/shared/alert/Alert';
import Upload from '@components/shared/uploader/Uploader';
import TextEditor from '@components/shared/texteditor/TextEditor';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

function CourseResourceDetailEditComponent(props) {
  const { openSnackbar } = useSnackbar();
  const { courseResourceId } = useParams();
  const [entity, setEntity] = useState({
    name: '',
    order: '',
    icon: '',
    type_name: '',
    course: null,
    module: null,
    is_active: false,
    description: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [editorKey, setEditorKey] = useState(uuidv4());
  const [archive, setArchive] = useState([]);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const loadEntities = async () => {
    try {
      setIsLoading(true);
      const resourceEntityService = new CourseResourceService();
      const courseEntityService = new CourseService();
      const moduleEntityService = new CourseModuleService();

      const [courseResponse, moduleResponse, resourceResponse] =
        await Promise.all([
          courseEntityService.get({ all: true }),
          moduleEntityService.get({ all: true }),
          resourceEntityService.get({ id: courseResourceId }),
        ]);

      if (
        !courseResponse ||
        !courseResponse.success ||
        !moduleResponse ||
        !moduleResponse.success ||
        !resourceResponse ||
        !resourceResponse.success
      ) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return null;
      }

      setCourses(courseResponse.result);
      setModules(moduleResponse.result);
      setEntity(resourceResponse.result);
      setArchive(resourceResponse.result.attachments);
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEntities();
  }, []);

  useEffect(() => {
    if (isConfirm === true) {
      alertInfo.type === 'confirm-cancel' && props.setIsEditMode(false);
    } else if (isConfirm === false) {
      alertInfo.type === 'success' && props.setIsEditMode(false);
    }

    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const confirmOnClick = async () => {
    try {
      setIsLoading(true);
      const updatedData = {
        ...entity,
        course: entity.course ? entity.course : {},
        course_id: entity.course?.id || '',
        module: entity.module || null,
        module_id: entity.module?.id || '',
        attachments: archive.length ? archive : [],
      };

      setEditorKey(uuidv4());

      const entityService = new CourseResourceService();
      const entityResult = await entityService.update(updatedData);

      if (!entityResult || !entityResult.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }
      setAlertInfo({
        message:
          'Tus cambios han sido guardados. ¿Quieres seguir editando o prefieres salir?',
        type: 'success',
      });

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    }
  };

  const returnOnClick = () => {
    setAlertInfo({
      message:
        '¿Estás seguro de salir de la vista de edición? Se perderán los cambios realizados.',
      type: 'confirm-cancel',
    });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    if (name === 'course') {
      const selectedCourse = courses.find((course) => course.id === value);
      setEntity((prevState) => ({ ...prevState, course: selectedCourse }));
    } else if (name === 'module') {
      const selectedModule = modules.find((module) => module.id === value);
      setEntity((prevState) => ({ ...prevState, module: selectedModule }));
    }
  };

  const onFileLoaded = async (event) => {
    setIsLoadingImage(true);
    if (!event || !event.formData) {
      return;
    }

    const entityService = new CourseResourceService();
    const entityResponse = await entityService.uploadFile(event.formData);
    const today = new Date();

    if (!entityResponse || !entityResponse.success) {
      return;
    }

    setArchive([
      {
        uri: entityResponse.result.url || '',
        filename: entityResponse.result.filename || '',
        path: entityResponse.result.path || '',
        attachmentType: event.metadata.attachmentType,
        dateCreated: `${today.getFullYear()}/${
          today.getMonth() + 1
        }/${today.getDate()}`,
        isPrincipal: true,
      },
    ]);

    setIsLoadingImage(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEntity((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleCheckChange = (check) => {
    setEntity((prevState) => ({
      ...prevState,
      [check]: !prevState[check],
    }));
  };

  const onFileDeleted = async (event) => {
    if (!event || !event.formData) {
      return;
    }

    const fileSplit = event.formData.get('filename').split('/');
    const filename = fileSplit[fileSplit.length - 1];
    event.formData.set('filename', filename);

    setArchive([]);
  };

  const handleTextEditChange = (data) => {
    setEntity((prevState) => ({ ...prevState, description: data.model }));
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Cambios Guardados Exitosamente',
          description: alertInfo.message,
          typeIcon: 'success',
          confirmButtonText: 'Seguir Editando',
          cancelButtonText: 'Salir',
          showCancelButton: true,
        };
      default:
        return null;
    }
  };

  return (
    <div>
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <form onSubmit={confirmOnClick}>
            <div className="row">
              <div className="form-group col-12 mb-3">
                <label className="form-label">Nombre del Recurso*</label>
                <input
                  type="text"
                  required
                  autoComplete="off"
                  className="form-control"
                  id="name"
                  placeholder=""
                  value={entity.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-4 mb-3">
                <label className="form-label" htmlFor="order">
                  Orden*
                </label>
                <input
                  value={entity.order}
                  onChange={handleChange}
                  type="number"
                  autoComplete="off"
                  className="form-control"
                  id="order"
                  placeholder=""
                />
              </div>

              <div className="form-group col-md-4 mb-3">
                <label className="form-label">Ícono*</label>
                <input
                  required
                  value={entity.icon}
                  onChange={handleChange}
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="icon"
                  placeholder=""
                />
              </div>

              <div className="form-group col-md-4 mb-3">
                <label className="form-label">Tipo*</label>
                <input
                  required
                  value={entity.type_name}
                  onChange={handleChange}
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="type_name"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label className="form-label">Asociar a curso</label>
                <select
                  className="form-select"
                  name="course"
                  value={entity.course?.id || 'default'}
                  onChange={handleSelectChange}
                >
                  <option value="default" disabled>
                    Seleccione un curso
                  </option>
                  {courses.map((_entity, key) => (
                    <option key={key} value={_entity.id}>
                      {_entity.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-6 mb-3">
                <label className="form-label">Asociar a módulo</label>
                <select
                  className="form-select"
                  name="module"
                  value={entity.module?.id || 'default'}
                  onChange={handleSelectChange}
                >
                  <option value="default" disabled>
                    Seleccione un módulo
                  </option>
                  {modules.map((_entity, key) => (
                    <option key={key} value={_entity.id}>
                      {_entity.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="image" className="form-label">
                  Archivo
                </label>
                {isLoadingImage ? (
                  <Loader />
                ) : (
                  <Upload
                    id="image-input"
                    imgpath={archive.length ? archive[0].uri : ''}
                    enablecopy={true}
                    enabledelete={true}
                    onLoaded={onFileLoaded}
                    onDeleted={onFileDeleted}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3">
                <label htmlFor="published-status-input" className="form-label">
                  ¿Está activo?
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="published-status-input"
                    checked={entity.is_active}
                    onChange={() => handleCheckChange('is_active')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="published-status-input"
                  >
                    {entity.is_active ? 'Si' : 'No'}
                  </label>
                </div>
              </div>
            </div>

            <div className="row my-3">
              <div className="form-group col-12 col-md-12">
                <div className="bg-primary py-2">
                  <h4 className="fw-light my-0 text-center text-white w-100">
                    Descripción
                  </h4>
                </div>
                <div>
                  <TextEditor
                    modelraw={entity.description}
                    onModelChange={handleTextEditChange}
                    id={editorKey}
                    index={editorKey}
                  />
                </div>
              </div>
            </div>

            <button
              title="submit"
              type="submit"
              className="btn btn-primary me-3"
            >
              <i className="feather-icon" data-feather="check"></i> Guardar
            </button>

            <button
              title="Cancel"
              type="button"
              className="btn btn-light"
              onClick={returnOnClick}
            >
              <i className="feather-icon" data-feather="x"></i> Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default CourseResourceDetailEditComponent;
