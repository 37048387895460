import React, { useEffect, useState } from 'react';
import { UserCourseService, UserService, CourseService } from '@services/index';
import SearchableSelect from './components/SearchableSelect';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function createEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const UserCourseCreate = (props) => {
  const { setIsOpen, onUpdatedEntity } = props;
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [entity, setEntity] = useState({
    status: { id: 0 },
  });

  const fetchAllStatuses = async () => {
    try {
      const entityService = new UserCourseService();
      const entityResponse = await entityService.get({
        status: true,
      });

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Ocurrio un error obteniendo los estados', 'error');
        return;
      }

      const result = entityResponse.result;
      setStatuses(Object.values(result));
    } catch (error) {
      openSnackbar('Ha ocurrido un error', 'error');
      console.log(error);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const entityService = new UserService();
      const entityResponse = await entityService.get({
        all: true,
      });
      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Ocurrio un error obteniendo los usuarios', 'error');
        return;
      }
      const entityAdapter = entityResponse.result.map((_entity) => {
        return {
          label: `${_entity.firstname} ${_entity.lastname} - ${_entity.email}`,
          value: _entity.id,
        };
      });

      setUserOptions(entityAdapter);
      setUsers(entityResponse.result);
    } catch (error) {
      openSnackbar('Ha ocurrido un error', 'error');
      console.log(error);
    }
  };

  const fetchAllCourses = async () => {
    try {
      const entityService = new CourseService();
      const entityResponse = await entityService.get({
        all: true,
      });
      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Ocurrio un error obteniendo los cursos', 'error');
        return;
      }

      const adapterForSelectOptions = entityResponse.result.map((_entity) => {
        return {
          label: _entity.name,
          value: _entity.id,
        };
      });

      setCourseOptions(adapterForSelectOptions);
      setCourses(entityResponse.result);
    } catch (error) {
      openSnackbar('Ha ocurrido un error', 'error');
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
    fetchAllCourses();
    fetchAllStatuses();
  }, []);

  const confirmOnClick = async (event) => {
    event.preventDefault();

    const user = users.find((_entity) => _entity.id === entity.user_id);
    const course = courses.find((_entity) => _entity.id === entity.course_id);

    try {
      if (!user) {
        openSnackbar('Selecciona un usuario', 'error');
        return;
      }

      setIsLoading(true);

      const entityData = {
        ...entity,
        user,
        course,
      };

      const response = await createEntity(entityData, UserCourseService);

      if (!response || !response.success) {
        openSnackbar('Ocurrio un error creando la entidad', 'error');
        return;
      }

      onUpdatedEntity({ ...response.result, status: entity.status });
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      openSnackbar('Ha ocurrido un error', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  function resetForm() {
    setEntity({
      status: { id: 0 },
    });
  }

  const cancelOnClick = () => {
    resetForm();
    setIsOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'status') {
      const status = statuses.find((_status) => _status.id === +value);
      setEntity((prevEntity) => ({ ...prevEntity, [name]: status }));
      return;
    }

    setEntity((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  return (
    <div className="card card-body">
      <form className="mt-3">
        <div className="row mb-3">
          <div className="form-group col-12 col-md-6">
            <label className="form-control-label">Usuario</label>
            <SearchableSelect
              options={userOptions}
              placeholder="Selecciona un usuario"
              onChange={(value) =>
                setEntity((prevState) => ({ ...prevState, user_id: value }))
              }
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-control-label">Curso</label>
            <SearchableSelect
              options={courseOptions}
              placeholder="Selecciona un curso"
              onChange={(value) =>
                setEntity((prevState) => ({ ...prevState, course_id: value }))
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label className="form-control-label">Estado</label>
            <select
              className="form-control status-list"
              value={entity.status.id}
              name="status"
              onChange={handleInputChange}
            >
              <option value="0" disabled>
                Selecciona un estado
              </option>
              {statuses.map((_entity) => (
                <option key={_entity.id} value={_entity.id}>
                  {_entity.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-4">
          {isLoading ? (
            <button class="btn btn-primary" disabled type="button">
              <span
                class="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <>
              <button
                title="Submit"
                type="submit"
                className="btn btn-primary btn-action mr-2"
                onClick={confirmOnClick}
              >
                <i className="fe-check"></i> Hacer curso pagado
              </button>
              <button
                title="Cancel"
                type="button"
                className="btn btn-light btn-action ml-2"
                onClick={cancelOnClick}
              >
                <i className="fe-x"></i> Cancelar
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserCourseCreate;
