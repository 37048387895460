import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UserExamDetail, UserExamEdit } from '@components/pages';
import Alert from '@components/shared/alert/Alert';

function UserExamDetailsView() {
  const { setPageName } = useOutletContext();
  const [isConfirm, setIsConfirm] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    setPageName('Usuarios');
  }, []);

  useEffect(() => {
    if (alertInfo.type === 'confirm-cancel' && isConfirm === true) {
      setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleEditModeToggle = () => {
    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      setAlertInfo({
        message: '¿Estás seguro de que deseas abandonar el modo de edición?',
        type: 'confirm-cancel',
      });
    }
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      default:
        return null;
    }
  };

  return (
    <section className="container-fluid">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      <article className="row card">
        <div className="d-flex border-bottom justify-content-between align-items-start mb-3 py-2 px-3">
          <h4>
            {isEditMode
              ? 'Editar asignación de examen'
              : 'Detalles del examen asignado'}
          </h4>

          <div className="form-check form-switch fs-4 align-self-center ">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                id="flexSwitchCheckDefault"
                checked={isEditMode}
                onChange={handleEditModeToggle}
              />
            </div>
            <label className="form-check-label">Modo Edición</label>
          </div>
        </div>

        {isEditMode ? (
          <UserExamEdit setIsEditMode={setIsEditMode} />
        ) : (
          <UserExamDetail />
        )}
      </article>
    </section>
  );
}

export default UserExamDetailsView;
