import React from 'react';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';

const UserExamAnswers = (props) => {
  const { isLoading, entity } = props;

  const orderedQuestions = () => {
    return entity.questions.sort((a, b) => +a.order - +b.order);
  };

  const getHtml = (body) => {
    if (!body) {
      return '';
    }

    return decodeURI(body);
  };

  const indexToLetter = (index) => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    index += 1;
    const printToLetter = (number) => {
      let charIndex = number % alphabet.length;
      let quotient = number / alphabet.length;
      if (charIndex - 1 === -1) {
        charIndex = alphabet.length;
        quotient--;
      }
      result = alphabet.charAt(charIndex - 1) + result;
      if (quotient >= 1) {
        printToLetter(parseInt(quotient));
      }
    };

    printToLetter(index);
    return result;
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <div className="col-12 grid-margin-md-0 stretch-card mx-auto d-block">
          <div className="mb-4">
            <h4 className="border-bottom mb-4 mt-4 pb-3 text-center">
              Respuestas
            </h4>
            {orderedQuestions().map((_entity, index) => (
              <div
                key={index}
                className="col-12 col-md-10 grid-margin-md-0 stretch-card mx-auto d-block my-3"
              >
                <div className="pt-3">
                  <p>
                    {index + 1 + ')'}
                    <span
                      className="ps-1"
                      dangerouslySetInnerHTML={{
                        __html: getHtml(_entity.question_statement),
                      }}
                    ></span>
                  </p>
                  {!_entity.isCorrect && (
                    <div className="mb-3">
                      <p className="d-inline">Las respuestas correctas son:</p>
                      <span className="ps-1">
                        {_entity.answerTokens.map(
                          (_answerToken) => _answerToken + ', ',
                        )}
                      </span>
                    </div>
                  )}

                  {_entity.items.map((_option, j) => (
                    <div key={j} className="form-check">
                      <input
                        type="checkbox"
                        className={`form-check-input ${
                          _entity.isCorrect ? 'is-valid' : 'is-invalid'
                        }`}
                        checked={_option.selected}
                        onChange={(e) => (_option.selected = e.target.checked)}
                        id={`question-${index}-option-${j}`}
                      />
                      <label
                        className="form-check-label mb-2"
                        htmlFor={`question-${index}-option-${j}`}
                      >
                        {indexToLetter(j)}) {_option.body}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="question-overlay"></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UserExamAnswers;
