import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CourseModuleService, CourseService } from '@services';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import Alert from '@components/shared/alert/Alert';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

function CourseModuleDetailEditComponent(props) {
  const { courseModuleId } = useParams();
  const { openSnackbar } = useSnackbar();

  const [entity, setEntity] = useState({
    name: '',
    order: '',
    description: '',
    course: '',
    is_active: false,
  });
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const loadData = async (getEntityById, getAllCourses) => {
    try {
      setIsLoading(true);
      const [entityResponse, coursesResponse] = await Promise.all([
        getEntityById(),
        getAllCourses(),
      ]);

      if (entityResponse && entityResponse.success) {
        setEntity(entityResponse.result);
      } else {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        console.error(entityResponse);
      }

      if (coursesResponse && coursesResponse.success) {
        setCourses(coursesResponse.result);
      } else {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        console.error(coursesResponse);
      }
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getEntityById = async () => {
      const entityService = new CourseModuleService();
      return entityService.get({ id: courseModuleId });
    };

    const getAllCourses = async () => {
      const courseService = new CourseService();
      return courseService.get({ all: true });
    };

    loadData(getEntityById, getAllCourses);
  }, [courseModuleId]);

  useEffect(() => {
    if (isConfirm === true) {
      alertInfo.type === 'confirm-cancel' && props.setIsEditMode(false);
    } else if (isConfirm === false) {
      alertInfo.type === 'success' && props.setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleCourseChange = (event) => {
    const selectedCourseId = event.target.value;
    const selectedCourse = courses.find(
      (course) => course.id === selectedCourseId,
    );

    setEntity((prevEntity) => ({ ...prevEntity, course: selectedCourse }));
  };

  const handleCheckChange = (check) => {
    setEntity((prevState) => ({ ...prevState, [check]: !prevState[check] }));
  };

  const confirmOnClick = async () => {
    try {
      setIsLoading(true);
      const entityService = new CourseModuleService();
      const entityResult = await entityService.update(entity);

      if (!entityResult || !entityResult.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }

      setAlertInfo({
        message:
          'Tus cambios han sido guardados. ¿Quieres seguir editando o prefieres salir?',
        type: 'success',
      });

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    }
  };

  const returnOnClick = () => {
    setAlertInfo({
      message:
        '¿Estás seguro de salir de la vista de edición? Se perderán los cambios realizados.',
      type: 'confirm-cancel',
    });
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Cambios Guardados Exitosamente',
          description: alertInfo.message,
          typeIcon: 'success',
          confirmButtonText: 'Seguir Editando',
          cancelButtonText: 'Salir',
          showCancelButton: true,
        };
      default:
        return null;
    }
  };

  return (
    <div>
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <form onSubmit={confirmOnClick}>
            <div className="mb-3">
              <label className="form-label" htmlFor="name">
                Nombre del curso*
              </label>
              <input
                value={entity.name}
                onChange={(e) => setEntity({ ...entity, name: e.target.value })}
                type="text"
                autoComplete="off"
                className="form-control"
                id="name"
                placeholder=""
              />
            </div>

            <div className="row">
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="order">
                  Orden*
                </label>
                <input
                  value={entity.order}
                  onChange={(e) =>
                    setEntity({ ...entity, order: e.target.value })
                  }
                  type="number"
                  autoComplete="off"
                  className="form-control"
                  id="order"
                  placeholder=""
                />
              </div>

              <div className="mb-3 col-md-5">
                <label className="form-label" htmlFor="description">
                  Descripción breve*
                </label>
                <input
                  value={entity.description}
                  onChange={(e) =>
                    setEntity({ ...entity, description: e.target.value })
                  }
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="description"
                  placeholder=""
                />
              </div>

              <div className="mb-3 col-md-5">
                <label className="form-label" htmlFor="courseSelect">
                  Asociar a curso
                </label>
                <select
                  className="form-select"
                  id="courseSelect"
                  value={entity.course.id}
                  onChange={handleCourseChange}
                >
                  <option value="0" disabled>
                    Selecciona un curso
                  </option>
                  {courses.map((_entity, index) => (
                    <option key={index} value={_entity.id}>
                      {_entity.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col-md-6">
                <label className="form-label">¿Está activo?</label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="published-status-input"
                    checked={entity.is_active}
                    onChange={(e) => handleCheckChange('is_active')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="published-status-input"
                  >
                    {entity.is_active ? 'Si' : 'No'}
                  </label>
                </div>
              </div>
            </div>

            <button
              title="submit"
              type="submit"
              className="btn btn-primary me-3"
            >
              <i className="feather-icon" data-feather="check"></i> Guardar
            </button>

            <button
              title="Cancel"
              type="button"
              className="btn btn-light"
              onClick={returnOnClick}
            >
              <i className="feather-icon" data-feather="x"></i> Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default CourseModuleDetailEditComponent;
