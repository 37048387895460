import React, { useState, useEffect } from 'react';
import { UserExamService } from '@services/index';
import { useParams } from 'react-router-dom';
import { formatHelpers } from '@components/utilities';
import UserExamAnswers from './UserExamAnswers.jsx';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const UserExamDetail = () => {
  const { id: userExamId } = useParams();
  const { openSnackbar } = useSnackbar();
  const [entity, setEntity] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!userExamId) {
      openSnackbar(
        'Por favor regresa y selecciona el elemento de nuevo',
        'error',
      );
      return;
    }

    const fetchUserExamByQuery = async (query) => {
      try {
        const entityService = new UserExamService();
        const entityResponse = await entityService.get(query);

        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Ocurrio un error al obtener el examen', 'error');
          return;
        }

        try {
          entityResponse.result.questions = JSON.parse(
            entityResponse.result.questions,
          );
        } catch (error) {
          entityResponse.result.questions = [];
        }

        setEntity(entityResponse.result);
      } catch (error) {
        console.log(error);
        openSnackbar('Ha ocurrido un error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserExamByQuery({ id: userExamId });
  }, []);

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className="card-body col-12 mx-auto d-block">
            <div className="mb-4">
              <h2 className="border-bottom mb-4 mt-4 pb-3 text-center">
                {entity.user.firstname} {entity.user.lastname}
              </h2>
              <div className="row">
                <div className="col-12 col-md-4">
                  <p className="text-muted mb-0">Email</p>
                  <p className="mb-2">{entity.user.email}</p>
                </div>
                <div className="col-12 col-md-4">
                  <p className="text-muted mb-0">Documento</p>
                  <p className="mb-2">{entity.user.dni}</p>
                </div>
                <div className="col-12 col-md-4">
                  <p className="text-muted mb-0">Examen</p>
                  <p className="mb-2">{entity.exam.name}</p>
                </div>
                <div className="col-12 col-md-4">
                  <p className="text-muted mb-0">Estado</p>
                  <span className="badge bg-info fs-6 p-1">
                    {entity.status.title}
                  </span>
                </div>
                <div className="col-12 col-md-4">
                  <p className="text-muted mb-0">Última fecha de intento</p>
                  <p className="mb-2">
                    {formatHelpers.dateWithSeparators(entity.last_date_attempt)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body fs-5">
            <UserExamAnswers entity={entity} />
          </div>
        </>
      )}
    </>
  );
};

export default UserExamDetail;
