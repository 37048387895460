import { useState, useEffect } from 'react';
import { PopUp } from '@components/shared/popup/PopUp';
import {
  UserEnrolledCourseDelete,
  UserEnrolledCourseBlock,
  UserEnrolledCourseApprove,
} from '@components/pages';
import { formatHelpers } from '@components/utilities/';
import { CourseService } from '@services/index';
import { useParams, Link } from 'react-router-dom';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from '@mui/material/';

const UserEnrolledCourses = () => {
  const { id: userId } = useParams();
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [myCourses, setMyCourses] = useState([]);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [menuActionsAnchorElement, setMenuActionsAnchorElement] =
    useState(null);
  const [menuActionsSelected, setActionsSelected] = useState(null);

  useEffect(() => {
    const fetchCoursesByQuery = async (query, Entity) => {
      try {
        const entityService = new Entity();
        const entityResponse = await entityService.getMyCourses(query);
        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Ocurrio un error al obtener cursos inscritos', 'error');
          return;
        }

        setMyCourses(entityResponse.result);
      } catch (error) {
        console.log(error);
        openSnackbar('Ha ocurrido un error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoursesByQuery({ user_id: userId }, CourseService);
  }, [userId]);

  const handleDeleteEntity = (entity) => {
    setMyCourses((prevState) => {
      const entityIndex = prevState.findIndex(
        (_entity) => _entity.id === entity.id,
      );

      if (entityIndex > -1) {
        prevState.splice(entityIndex, 1);
      }

      return prevState;
    });
  };

  const handleUpdateEntity = (entity) => {
    setMyCourses((prevEntities) => {
      const updatedEntities = prevEntities.map((prevEntity) => {
        if (prevEntity.id === entity.id) {
          return entity;
        }
        return prevEntity;
      });
      return updatedEntities;
    });
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'approve':
        setIsOpenApproveModal(false);
        handleUpdateEntity(entity);
        break;
      case 'block':
        setIsOpenBlockModal(false);
        handleUpdateEntity(entity);
        break;
      case 'delete':
        setIsOpenDeleteModal(false);
        handleDeleteEntity(entity);
        break;
      default:
        break;
    }
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'approve':
        setIsOpenApproveModal(true);
        setSelectedEntity(entity);
        break;
      case 'block':
        setIsOpenBlockModal(true);
        setSelectedEntity(entity);
        break;
      case 'delete':
        setIsOpenDeleteModal(true);
        setSelectedEntity(entity);
        break;
      default:
        break;
    }
  };

  const closeMenuActions = () => {
    setActionsSelected(null);
    setMenuActionsAnchorElement(null);
  };

  const actionsMenuOnClick = (selector, anchorElement) => {
    setActionsSelected(selector || null);
    setMenuActionsAnchorElement(anchorElement || null);
  };

  const MenuDots = (row) => {
    const menuItemOnClick = (event, action) => {
      event?.stopPropagation(); // Prevent select this row after clicking

      itemOnAction(action, row);
    };

    return (
      <>
        <IconButton
          data-testid="user-action-menu-btn"
          aria-label="actions button"
          id={`basic-menu-${row.id}`}
          aria-haspopup="true"
          onClick={(event) =>
            actionsMenuOnClick(`basic-menu-${row.id}`, event.currentTarget)
          }
        >
          <i className="mdi mdi-dots-vertical fs-4"></i>
        </IconButton>
        <Menu
          elevation={1}
          id={'basic-menu-' + row.id}
          anchorEl={menuActionsAnchorElement}
          open={menuActionsSelected === `basic-menu-${row.id}`}
          onClose={closeMenuActions}
          MenuListProps={{
            'aria-labelledby': 'user-action-menu-btn',
          }}
        >
          <MenuItem
            component={Link}
            to={`/admin/user/course/detail/${row.usercourse_id}`}
            target="_blank"
          >
            <i className="fe-search me-1"></i>
            <ListItemText>Ver/Editar</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              menuItemOnClick(event, 'approve');
              closeMenuActions();
            }}
          >
            <i className="fe-shield-off me-1"></i>
            <ListItemText>Aprobar</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              menuItemOnClick(event, 'block');
              closeMenuActions();
            }}
          >
            <i className="fe-shield-off me-1"></i>
            <ListItemText>Bloquear</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              menuItemOnClick(event, 'delete');
              closeMenuActions();
            }}
          >
            <i className="fe-trash me-1"></i>
            <ListItemText>Eliminar</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <div className="col-12 col-md-10 mx-auto d-block">
      <div className="mb-4">
        <h4 className="mt-4 text-center">Cursos inscritos</h4>

        <div className="row">
          <div className="table-responsive mt-3">
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Curso</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myCourses?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>
                          <span className="d-none">
                            {formatHelpers.dateWithoutSeparators(
                              row.last_modification,
                            )}
                          </span>
                          {formatHelpers.dateWithSeparators(
                            row.last_modification,
                          )}
                        </TableCell>
                        <TableCell>
                          <span className="badge bg-info fs-6 p-1">
                            {row.status.title}
                          </span>
                        </TableCell>
                        <TableCell>{MenuDots(row)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
      <PopUp
        title="Eliminar"
        data-testid="delete-usercourse-modal"
        id="delete-usercourse-modal"
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        styles={{ width: '30%' }}
      >
        <UserEnrolledCourseDelete
          setIsOpen={setIsOpenDeleteModal}
          onUpdatedEntity={(entity) => onUpdatedEntity('delete', entity)}
          selectedEntity={selectedEntity}
        />
      </PopUp>
      <PopUp
        title="Aprobar"
        data-testid="approve-usercourse-modal"
        id="create-usercourse-modal"
        isOpen={isOpenApproveModal}
        setIsOpen={setIsOpenApproveModal}
        styles={{ width: '30%' }}
      >
        <UserEnrolledCourseApprove
          setIsOpen={setIsOpenApproveModal}
          onUpdatedEntity={(entity) => onUpdatedEntity('approve', entity)}
          selectedEntity={selectedEntity}
        />
      </PopUp>
      <PopUp
        title="Bloquear"
        data-testid="block-usercourse-modal"
        id="block-usercourse-modal"
        isOpen={isOpenBlockModal}
        setIsOpen={setIsOpenBlockModal}
        styles={{ width: '30%' }}
      >
        <UserEnrolledCourseBlock
          setIsOpen={setIsOpenBlockModal}
          onUpdatedEntity={(entity) => onUpdatedEntity('block', entity)}
          selectedEntity={selectedEntity}
        />
      </PopUp>
    </div>
  );
};

export default UserEnrolledCourses;
