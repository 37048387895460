import BaseApi from '../base/apiBase.service'

export default class SecurityService extends BaseApi {
  constructor (args) {
    super(args)

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      login: '/api/login',
      logout: '/api/logout'
    }
  }

  async login(data) {
    return super.post(data, this.serviceEndpoints.login);
  }

  async logout(data) {
    return super.post(data, this.serviceEndpoints.logout);
  }
}