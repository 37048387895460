import React from 'react';

function PageTitlePlaceholder({ children, count }) {
  const renderLines = () => {
    return [...Array(count)].map((_, index) => (
      <div key={index} className="row">
        {' '}
        <p className="placeholder col-3"></p>
      </div>
    ));
  };
  return (
    <>
      {renderLines()}
      <div className="text-muted font-14 mb-3">{children}</div>
    </>
  );
}

export default PageTitlePlaceholder;
