import React, { useState, useEffect } from 'react';
import { useAuth } from '@hooks/useAuth.hook';
import Alert from '@components/shared/alert/Alert';
import { CertificationService } from '@services';
import { formatHelpers } from '@components/utilities';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import styled from 'styled-components';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

const StyledImg = styled.img`
  width: 100%; 
  height: 160px; 
  object-fit: cover;
`;


function MyCertification() {
  const { user } = useAuth();

  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    entity: {},
  });

  const [isAlertActive, setIsAlertActive] = useState(null);
  const [isconfirm, setIsConfirm] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isconfirm === false) {
      setIsAlertActive(null);
      setIsConfirm(null);
    }
  }, [isconfirm, isAlertActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const entityResponse = await getEntity(
          {
            queryselector: '/certification/my-certifications',
            user_id: user.id,
          },
          CertificationService,
        );
        if (!entityResponse) {
          openAlert('Certificado no encontrado.', '', 'error', true);
          return;
        }

        setModel((prevData) => ({
          ...prevData,
          entity: entityResponse.result,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [user.id]);

  const openAlert = (title, message, icon, isSuccess) => {
    setAlertMessage((prevData) => ({
      ...prevData,
      title,
      message,
      icon,
    }));
    setIsAlertActive(isSuccess);
  };
  const getPrincipalImage = (_entity) => {
    const defaultItem = {
      uri: `${process.env.REACT_APP_BACKEND_URL}/img/404.svg`,
      name:
        !_entity || !_entity.name
          ? 'Entidad no disponible'
          : `Adjunto inexistente de "${_entity.name}"`,
      attachmentType: 'image/svg+xml',
    };

    if (!_entity || !_entity.attachments || !_entity.attachments.length) {
      return defaultItem;
    }

    const element = [..._entity.attachments].find(
      (el) => el.attachmentType.includes('image') && el.isPrincipal === true,
    );

    if (!element || !element.uri) {
      return defaultItem;
    }

    return element;
  };

  const getBody = (body) => {
    if (!body) {
      return '';
    }
    var text = formatHelpers.extractTextFromHtml(window.decodeURI(body));
    return text.length > 85 ? text.slice(0, 85) + '...' : text;
  };

  return (
    <>
      {isAlertActive !== null && isAlertActive && (
        <Alert
          config={{
            title: alertMessage.title,
            description: alertMessage.message,
            typeIcon: alertMessage.icon,
            showCancelButton: false,
          }}
          setConfirm={setIsConfirm}
        />
      )}
      <div className="col-12 d-block mx-auto py-5">
        <ul
          className="border-0 nav nav-tabs custom-tabs"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="mycertifications-tab"
              data-toggle="tab"
              href="#mycertifications"
              role="tab"
              aria-controls="mycertifications"
              aria-selected="false"
            >
              Mis certificados
            </a>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          {/* My certifications */}
          <div
            className="tab-pane fade show active"
            id="mycertifications"
            role="tabpanel"
            aria-labelledby="mycertifications-tab"
          >
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <div className="d-flex row">
                {Array.isArray(model.entity) &&
                  model.entity.map((_entity, index) => (
                    <div className="col-12 col-md-4 my-3" key={index}>
                      <div className="card shadow h-100">
                        <StyledImg
                          src={
                            getPrincipalImage(_entity.course).uri ||
                            getPrincipalImage(_entity.course).filename
                          }
                          alt=""
                        />
                        <div className="card-body">
                          <h5 className="card-title">{`${_entity.course.name}`}</h5>
                          <p className="card-text">
                            {getBody(_entity.course.description)}
                          </p>
                          <a
                            className="btn btn-primary"
                            href={`/admin/certification/detail/${_entity.id}`}
                          >
                            Ver más
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyCertification;
