import React, { useEffect, useState } from 'react';
import { UserExamService, UserService, ExamService } from '@services/index';
import SearchableSelect from './components/SearchableSelect';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function createEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const UserExamCreate = (props) => {
  const { setIsOpen, onUpdatedEntity } = props;
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [exams, setExams] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [examOptions, setExamOptions] = useState([]);
  const [entities, setEntities] = useState([]);

  const fetchAllStatuses = async () => {
    try {
      const entityService = new UserExamService();
      const entityResponse = await entityService.get({
        status: true,
      });

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Error al obtener los estados', 'error');
        return;
      }

      const result = entityResponse.result;
      setStatuses(Object.values(result));
    } catch (error) {
      openSnackbar('Ocurrio un error', 'error');
      console.log(error);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const entityService = new UserService();
      const entityResponse = await entityService.get({
        all: true,
      });
      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Error al obtener los usuarios', 'error');
        return;
      }
      const entityAdapter = entityResponse.result.map((_entity) => {
        return {
          label: `${_entity.firstname} ${_entity.lastname} - ${_entity.email}`,
          value: _entity.id,
        };
      });

      setUserOptions(entityAdapter);
      setUsers(entityResponse.result);
    } catch (error) {
      console.log(error);
      openSnackbar('Ocurrio un error', 'error');
    }
  };

  const fetchAllExams = async () => {
    try {
      const entityService = new ExamService();
      const entityResponse = await entityService.get({
        all: true,
      });
      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Error al obtener los examenes', 'error');
        return;
      }

      const adapterForSelectOptions = entityResponse.result.map((_entity) => {
        return {
          label: _entity.name,
          value: _entity.id,
        };
      });

      setExamOptions(adapterForSelectOptions);
      setExams(entityResponse.result);
    } catch (error) {
      console.log(error);
      openSnackbar('Ocurrio un error', 'error');
    }
  };

  useEffect(() => {
    fetchAllUsers();
    fetchAllExams();
    fetchAllStatuses();
  }, []);

  const confirmOnClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const createdEntities = await Promise.all(
        entities.map(async (userExam) => {
          const user = users.find((_entity) => _entity.id === userExam.user_id);
          const exam = exams.find((_entity) => _entity.id === userExam.exam_id);

          const entityData = {
            ...userExam,
            attempts: +userExam.attempts,
            user,
            exam,
          };

          const response = await createEntity(entityData, UserExamService);

          if (!response || !response.success) {
            openSnackbar('Error al crear la entidad', 'error');
          }

          return { ...response.result, status: userExam.status };
        }),
      );

      onUpdatedEntity(createdEntities);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      openSnackbar('Ocurrio un error', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;

    setEntities((prevEntities) => {
      const updatedEntities = [...prevEntities];
      const updatedEntity = { ...updatedEntities[index] };

      if (name === 'status') {
        updatedEntity.status = statuses.find(
          (_status) => _status.id === +value,
        );
      } else {
        updatedEntity[name] = value;
      }

      updatedEntities[index] = updatedEntity;
      return updatedEntities;
    });
  };

  const handleSearchableInputChange = (optionSelected, index) => {
    const { name, value } = optionSelected;
    setEntities((prevEntities) => {
      const updatedEntities = [...prevEntities];
      updatedEntities[index] = {
        ...updatedEntities[index],
        [name]: value,
      };
      return updatedEntities;
    });
    return;
  };

  const removeItemOnClick = (index) => {
    removeItem(index);
  };

  const removeItem = (index) => {
    if (index === undefined) return null;

    setEntities((prevEntities) => {
      const newEntities = [...prevEntities];
      newEntities.splice(index, 1);
      return newEntities;
    });
  };

  const addEntityOnClick = () => {
    const newEntity = {
      is_active: true,
      user: {},
      exam: {},
      user_id: '',
      exam_id: '',
      attempts: 0,
      status: {},
    };

    setEntities((prevState) => [...prevState, newEntity]);
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="col-12 mx-auto d-block mb-1">
        <div className="card">
          <div className="card-body">
            <div className="container pt-2">
              <h4 className="card-title text-center">Asignar examen</h4>
              <p className="card-description"></p>
            </div>
          </div>
        </div>
      </div>

      {entities.map((_entity, index) => (
        <div
          key={index}
          className="col-12 col-lg-12 col-xl-12 mx-auto d-block my-3"
        >
          <div className="card">
            <div className="card-body">
              <div className="container pt-2">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title">Examen #{index + 1}</h4>
                  <button
                    className="btn btn-outline-danger btn-rounded"
                    onClick={() => removeItemOnClick(index)}
                  >
                    <i className="fe-x"></i>
                  </button>
                </div>

                <form>
                  <div className="row mb-3">
                    <div className="form-group col-12 col-md-6">
                      <label className="form-label">Usuario</label>
                      <SearchableSelect
                        options={userOptions}
                        placeholder="Selecciona un usuario"
                        name="user_id"
                        onChange={(optionSelected) =>
                          handleSearchableInputChange(optionSelected, index)
                        }
                      />
                    </div>

                    <div className="form-group col-12 col-md-6">
                      <label className="form-label">Examen</label>
                      <SearchableSelect
                        options={examOptions}
                        placeholder="Selecciona un examen"
                        name="exam_id"
                        onChange={(optionSelected) =>
                          handleSearchableInputChange(optionSelected, index)
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-12 col-md-6">
                      <label className="form-label">Estado</label>
                      <select
                        className="form-control status-list"
                        value={_entity.status.id}
                        name="status"
                        onChange={(e) => handleInputChange(index, e)}
                      >
                        <option value="0" disabled>
                          Selecciona un estado
                        </option>
                        {statuses.map((_entity) => (
                          <option key={_entity.id} value={_entity.id}>
                            {_entity.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-12 col-md-6">
                      <label className="form-label">
                        Intentos hechos por el estudiante
                      </label>
                      <input
                        type="number"
                        value={_entity.attempts}
                        className="form-control"
                        name="attempts"
                        id={`attempts${index}`}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="col-12 mx-auto d-block my-1">
        <div className="container pt-2 d-flex justify-content-between">
          <button
            title="Submit"
            type="submit"
            className="btn btn-primary btn-action mr-2"
            onClick={addEntityOnClick}
          >
            <i data-feather="plus"></i> Agregar examen
          </button>
        </div>
      </div>

      <div className="col-12 mx-auto d-block mt-1">
        <div className="card">
          <div className="card-body">
            <div className="container pt-2 d-flex justify-content-center">
              {isLoading ? (
                <button class="btn btn-primary" disabled type="button">
                  <span
                    class="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Creando...
                </button>
              ) : (
                <>
                  <button
                    title="Submit"
                    type="submit"
                    className="btn btn-primary btn-action mr-2"
                    onClick={confirmOnClick}
                  >
                    <i className="fe-check"></i> Hacer asignación de examen
                  </button>
                  <button
                    title="Cancel"
                    type="button"
                    className="btn btn-light btn-action ml-2"
                    onClick={returnOnClick}
                  >
                    <i className="fe-x"></i> Cancelar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserExamCreate;
