import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ExamEdit, ExamDetail } from '@components/pages/index';

function ExamDetailView() {
  const [isEditMode, setIsEditMode] = useState(false);
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Examenes');
  }, []);

  const handleEditChange = () => {
    setIsEditMode((prevEditMode) => !prevEditMode);
  };
  return (
    <article className="col-12">
      <section className="card">
        <header className="row">
          <section className="col-12 d-flex justify-content-end mt-3">
            <div className="form-check form-switch d-flex justify-content-center col-2 col-sm-1">
              <input
                onChange={handleEditChange}
                className="form-check-input form-control fs-4"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={isEditMode}
              />
            </div>
            <label className="form-check-label col-3 col-sm-2 d-flex justify-content-start align-items-center">
              Modo Edición
            </label>
          </section>
        </header>
        <section className="row">
          {isEditMode ? <ExamEdit setIsEditMode={setIsEditMode} /> : <ExamDetail />}
        </section>
      </section>
    </article>
  );
}

export default ExamDetailView;
