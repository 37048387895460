import BaseApi from '../base/apiBase.service';

export default class LandPageService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      get: '/api/',
      create: '/api/landpage',
      update: '/api/landpage',
      delete: '/api/landpage',
    };
  }
  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }

  async delete(data) {
    return super.delete(data);
  }
  
  async get(payload, uri) {
    return super.get(payload, uri);
  }
}
