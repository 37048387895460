import React, { useState, useEffect } from 'react';
import { CertificationService } from '@services';
import Upload from '@components/shared/uploader/Uploader';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import SearchableSelect from '@components/shared/searchable-select/SearchableSelect';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

async function createEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

async function uploadFile(payload, path, folder, entity) {
  const entityService = new entity();
  payload.append('folder', folder);
  const entityResponse = await entityService.post(payload, path);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }
  return entityResponse.result;
}

function CertificationCreate({ setIsOpen, onUpdatedEntity }) {
  const { openSnackbar } = useSnackbar();

  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    entity: {
      is_active: false,
    },
  });

  const [entities, setEntities] = useState([]);
  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);

  const [isLoadingFiles, setIsLoadingFiles] = useState(
    Array(entities.length).fill(false),
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const [responseCourse, responseUser] = await Promise.all([
        getEntity(
          { queryselector: 'course', search: '' },
          CertificationService,
        ),
        getEntity({ queryselector: 'user', search: '' }, CertificationService),
      ]);

      if (!responseCourse) {
        openSnackbar('Ocurrio un error al cargar los cursos!', 'error');
        return;
      }

      if (!responseUser) {
        openSnackbar('Ocurrio un error al cargar los usuarios!', 'error');
        return;
      }

      const UserAdapter = responseUser.result.map((_entity) => {
        return {
          label: `${_entity?.firstname} ${_entity?.lastname} - ${_entity.email}`,
          value: _entity.id,
        };
      });

      const courseAdapter = responseCourse.result.map((_entity) => {
        return {
          label: _entity.name,
          value: _entity.id,
        };
      });

      setUserOptions(UserAdapter);
      setCourseOptions(courseAdapter);

      setCourses(responseCourse.result);
      setUsers(responseUser.result);
    };

    fetchData().catch(console.error);
  }, []);

  const handleSearchableInputChange = (optionSelected, index) => {
    const { name, value } = optionSelected;

    setEntities((prevEntities) => {
      const updatedEntities = [...prevEntities];

      updatedEntities[index] = {
        ...updatedEntities[index],
        [name]: value,
      };

      return updatedEntities;
    });

    return;
  };

  const confirmOnClick = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setIsLoading(true);

    try {
      for (let index = 0; index < entities.length; index++) {
        const entity = entities[index];
        entity.user = users.find((_entity) => _entity.id === entity.user_id);
        entity.user_dni = entity.user.dni;
        entity.course = courses.find(
          (_entity) => _entity.id === entity.course_id,
        );
        entity.attachments = [
          // Filter by pdf files with uri
          ...((_) => {
            return !entity.attachmentFiles || !entity.attachmentFiles.length
              ? []
              : entity.attachmentFiles.filter(
                  (it) => it.uri && it.uri.length > 0,
                );
          })(),
        ];

        const entityResponse = await createEntity(entity, CertificationService);
        if (!entityResponse) {
          openSnackbar('Ocurrio un error al crear los certificados!', 'error');
          return;
        }
        onUpdatedEntity('create', entityResponse.result);
      }
      openSnackbar('Certificados creados!', 'success');
      setIsOpen(false);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const returnOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setIsOpen(false);
  };

  const onFileLoaded = async (event, index, entity) => {
    if (!event || !event.formData) {
      return;
    }

    setIsLoadingFiles((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });

    try {
      const entityResponse = await uploadFile(
        event.formData,
        '/api/upload/file',
        'certification',
        CertificationService,
      );
      const today = new Date();

      if (!entityResponse) {
        openSnackbar('Ocurrio un error al cargar el certificado!', 'error');
        return;
      }
      const updatedEntity = { ...entity };
      updatedEntity.attachmentFiles[index] = {
        uri: entityResponse.url || '',
        filename: entityResponse.filename || '',
        path: entityResponse.path || '',
        attachmentType: event.metadata.attachmentType,
        dateCreated: `${today.getFullYear()}/${
          today.getMonth() + 1
        }/${today.getDate()}`,
      };

      setModel((prevModel) => ({
        ...prevModel,
        entity: {
          ...prevModel.entity,
          attachmentFiles: updatedEntity,
        },
      }));
    } catch (error) {
      openSnackbar('Ocurrio un error inesperado!', 'error');
    } finally {
      setIsLoadingFiles((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const onFileDeleted = async (event, index, entity) => {
    if (!event || !event.formData) {
      return;
    }

    const fileSplit = event.formData.get('filename').split('/');
    const filename = fileSplit[fileSplit.length - 1];
    event.formData.set('filename', filename);

    if (
      index !== undefined &&
      index !== null &&
      entity.attachmentFiles &&
      entity.attachmentFiles.length > 1
    ) {
      const updatedEntity = { ...entity };
      updatedEntity.attachmentFiles.splice(index, 1);

      const updatedModel = {
        ...model,
        entity: model.entity.map((item, i) =>
          i === index ? updatedEntity : item,
        ),
      };
      setModel(updatedModel);
    } else {
      model.principalImage = { uri: '', name: '' };
    }
  };

  const addSecondaryFileOnClick = (event, entity) => {
    if (event) {
      event.preventDefault();
    }

    entity.attachmentFiles.push({ uri: '' });
    setEntities([...entities]);
  };

  const addEntityOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setEntities([
      ...entities,
      {
        is_active: true,
        user_id: '',
        course_id: '',
        attachmentFiles: [{ uri: '' }],
      },
    ]);
  };

  return (
    <>
      {/* Title */}
      <div className="col-12 mx-auto d-block mb-1">
        <div className="card">
          <div className="container pt-2">
            <h4 className="card-title text-center">Crear nuevo Certificado</h4>
          </div>
        </div>
      </div>

      {/* Content */}
      {entities.map((_entity, index) => (
        <div className="col-12  mx-auto d-block my-3" key={index}>
          <div className="container pt-2">
            <h4 className="card-title">Certificado # {index + 1}</h4>

            <form>
              <div className="form-row row">
                <div className="form-group col-12 col-md-6 mt-3">
                  <label className="form-control-label">Curso</label>
                  <div className="mt-3">
                    <SearchableSelect
                      options={courseOptions}
                      placeholder="Selecciona un curso"
                      name="course_id"
                      onChange={(optionSelected) =>
                        handleSearchableInputChange(optionSelected, index)
                      }
                    />
                  </div>
                </div>
                <div className="form-group col-12 col-md-6 mt-3">
                  <label className="form-control-label">Usuario</label>
                  <div className="mt-3">
                    <SearchableSelect
                      options={userOptions}
                      placeholder="Selecciona un usuario"
                      name="user_id"
                      onChange={(optionSelected) =>
                        handleSearchableInputChange(optionSelected, index)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12 mt-4">
                  <label className="form-control-label">
                    Documentos adjuntos
                  </label>
                  <table className="table table-bordered mb-0 mt-2">
                    <tbody>
                      {_entity.attachmentFiles.map((_file, i) => (
                        <tr key={i}>
                          {isLoadingFiles[i] ? (
                            <td>
                              {' '}
                              <SpinnerLoader />
                            </td>
                          ) : (
                            <td className="py-4">
                              <Upload
                                id={`"attachment-input-" +${index + i}`}
                                imgpath={_file.uri}
                                enablecopy={true}
                                accept={'application/pdf'}
                                onLoaded={(e) => onFileLoaded(e, i, _entity)}
                                onDeleted={(e) => onFileDeleted(e, i, _entity)}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button
                    className="btn btn-outline-primary mt-4"
                    onClick={(e) => addSecondaryFileOnClick(e, _entity)}
                  >
                    <i className="text-primary mr-2 mdi mdi-plus"></i>
                    Agregar documento
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ))}

      {/* Add item */}
      <div className="col-12 mx-auto d-block my-1">
        <div className="container pt-2 d-flex justify-content-between">
          <button
            title="Submit"
            type="submit"
            className="btn btn-primary btn-action mr-2"
            onClick={(e) => addEntityOnClick(e)}
          >
            <i className="mdi mdi-plus"></i> Agregar certificado
          </button>
        </div>
      </div>

      {/* Actions */}
      <div className="col-12 mx-auto d-block mt-1">
        <div className="card">
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <div className="card-body">
              <div className="container pt-2 d-flex justify-content-center">
                <button
                  title="Submit"
                  type="submit"
                  className="btn btn-primary btn-action me-2"
                  onClick={(e) => confirmOnClick(e)}
                >
                  <i className="mdi mdi-check"></i> Crear certificado
                </button>
                <button
                  title="Cancel"
                  type="button"
                  className="btn btn-light btn-action ms-2"
                  onClick={(e) => returnOnClick(e)}
                >
                  <i className="mdi mdi-close"></i> Cancelar
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CertificationCreate;
