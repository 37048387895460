import React, { useState, useEffect } from 'react';
import { CertificationService } from '@services';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }
  return entityResponse.result;
}

function CertificationBlock({ id, onUpdatedEntity, setIsOpen }) {
  const { openSnackbar } = useSnackbar();

  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    entity: {},
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityResponse = await getEntity(
          {
            queryselector: 'certification',
            id: id,
          },
          CertificationService,
        );

        if (!entityResponse) {
          openSnackbar('Certificado no encontrado.', 'error');
          return;
        }

        setModel((prevData) => ({
          ...prevData,
          entity: entityResponse.result,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const confirmOnClick = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setIsLoading(true);

    try {
      const updateStatusDelete = {
        id: id,
        status: { id: 4, name: 'blocked', title: 'Bloqueado' },
      };

      const entityResult = await updateEntity(
        updateStatusDelete,
        CertificationService,
      );

      if (!entityResult) {
        setIsLoading(false);
        openSnackbar('Ocurrio un error al bloquear el certificado', 'error');
        return;
      }

      openSnackbar('Elemento bloqueado!', 'success');

      onUpdatedEntity('block', entityResult);
      setIsOpen(false);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const returnOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setIsOpen(false);
  };
  return (
    <>
      <div className="col-12 mx-auto d-block">
        <div className="pt-2 text-center">
          <h2 className="mb-3">
            Bloquear
            <span className="ms-2">"{model.entity.user?.firstname || ''}"</span>
          </h2>
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <div>
              <p className="card-description fs-4">
                ¿Estás seguro de que quieres bloquear?
              </p>
              <div className="mt-4">
                <button
                  className="btn btn-primary mr-2"
                  onClick={confirmOnClick}
                >
                  <i className="fe-shield-off"></i> Bloquear
                </button>

                <button
                  className="btn btn-light btn-action ms-2"
                  onClick={returnOnClick}
                >
                  <i className="fe-x"></i> Cancelar
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CertificationBlock;
