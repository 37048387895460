import React, { useState, useEffect } from 'react';
import { CertificationService } from '@services';
import { PopUp } from '@components/shared/popup/PopUp';
import { DataGrid, GridToolbarQuickFilter, esES } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Box,
  Pagination,
} from '@mui/material';
import PageTablePlaceholder from '@components/shared/loaders/placeholder/PageTablePlaceholder';
import PageTitlePlaceholder from '@components/shared/loaders/placeholder/PageTitlePlaceholder';
import PageDescriptionPlaceholder from '@components/shared/loaders/placeholder/PageDescriptionPlaceholder';
import {
  CertificationDelete,
  CertificationCreate,
  CertificationBlock,
} from '@components/pages/index';
import { useSnackbar } from '@components/utilities/SnackbarProvider';
import { formatHelpers } from '@components/utilities';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse.result;
}

const Loader = () => (
  <PageTablePlaceholder count={4}>
    <PageTitlePlaceholder count={1}>
      <PageDescriptionPlaceholder count={1} />
    </PageTitlePlaceholder>
  </PageTablePlaceholder>
);

function CertificationList() {
  const { openSnackbar } = useSnackbar();

  const [displayEntities, setDisplayEntities] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 7,
    page: 0,
  });

  const [selectedEntity, setSelectedEntity] = useState({});

  // Popup state
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [menuActionsAnchorElement, setMenuActionsAnchorElement] =
    useState(null);
  const [menuActionsSelected, setActionsSelected] = useState(null);

  const { pageSize, page } = paginationModel;
  const totalPages = Math.ceil(displayEntities.length / pageSize);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityResponse = await getEntity(
          { queryselector: 'certification' },
          CertificationService,
        );

        if (!entityResponse) {
          openSnackbar('Ocurrió un error al cargar los exámenes!', 'error');
          setIsLoading(false);
          return;
        }

        setDisplayEntities(entityResponse);
      } catch (error) {
        openSnackbar('Ocurrió un error in esperado!', 'error');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handlePage = (event, value) => {
    setPaginationModel({ ...paginationModel, page: value - 1 });
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel({ page: 0, pageSize: Number(e.target.value) });
  };

  const closeMenuActions = () => {
    setActionsSelected(null);
    setMenuActionsAnchorElement(null);
  };

  const actionsMenuOnClick = (selector, anchorElement) => {
    setActionsSelected(selector || null);
    setMenuActionsAnchorElement(anchorElement || null);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return `${params.row.user.firstname} ${params.row.user.lastname}`;
      },
    },
    {
      field: 'course',
      headerName: 'Curso',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.course.name;
      },
    },
    {
      field: 'last_modification',
      headerName: 'Modificado',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return formatHelpers.dateWithSeparators(params.row.last_modification);
      },
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.status.title;
      },
      renderCell: (params) => {
        return (
          <span className="badge bg-info fs-6 p-1">
            {params.row.status.title}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'string',
      disableColumnMenu: true,
      renderCell: (params) => {
        const menuItemOnClick = (event, action) => {
          event?.stopPropagation();

          itemOnAction(action, params.row);
        };

        return (
          <>
            <IconButton
              disableRipple
              data-testid="user-action-menu-btn"
              aria-label="actions button"
              id={`basic-menu-${params.row.id}`}
              aria-haspopup="true"
              onClick={(event) =>
                actionsMenuOnClick(
                  `basic-menu-${params.row.id}`,
                  event.currentTarget,
                )
              }
            >
              <i className="mdi mdi-dots-vertical fs-4"></i>
            </IconButton>
            <Menu
              elevation={1}
              id={'basic-menu-' + params.row.id}
              anchorEl={menuActionsAnchorElement}
              open={menuActionsSelected === `basic-menu-${params.row.id}`}
              onClose={closeMenuActions}
              MenuListProps={{
                'aria-labelledby': 'user-action-menu-btn',
              }}
            >
              <div>
                <MenuItem
                  component={Link}
                  onClick={(event) => {
                    closeMenuActions();
                  }}
                  to={`/admin/certification/detail/${params.row.id}`}
                  target="_blank"
                  data-testid="user-resend-invitation-action-btn"
                >
                  <i className="fe-search me-1"></i>
                  <ListItemText>Ver Detalle</ListItemText>
                </MenuItem>
              </div>

              <MenuItem
                onClick={(event) => {
                  menuItemOnClick(event, 'block');
                  closeMenuActions();
                }}
                data-testid="user-edit-action-btn"
              >
                <i className="fe-lock me-1"></i>
                <ListItemText>Bloquear</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={(event) => {
                  menuItemOnClick(event, 'delete');
                  closeMenuActions();
                }}
              >
                <i className="fe-trash me-1"></i>
                <ListItemText>Eliminar</ListItemText>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const itemOnSuccessCreated = (entity) => {
    setDisplayEntities((prevState) => {
      const newEntities = [...prevState, entity];
      return newEntities;
    });
  };
  const itemOnSuccessBloked = (entity) => {
    setDisplayEntities((prevState) =>
      prevState.map((obj) => {
        if (obj.id === entity.id) {
          return { ...obj, status: entity.status };
        }

        return obj;
      }),
    );
  };
  const itemOnSuccessDeleted = (entity) => {
    setDisplayEntities((prevState) => {
      const newEntities = prevState.filter(
        (_entity) => _entity.id !== entity.id,
      );
      return newEntities;
    });
  };

  const itemOnAction = (action, _entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateModal(true);
        break;
      case 'delete':
        setIsOpenDeleteModal(true);
        setSelectedEntity(_entity);
        break;
      case 'block':
        setIsOpenBlockModal(true);
        setSelectedEntity(_entity);
        break;

      default:
        break;
    }
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'create':
        itemOnSuccessCreated(entity);
        break;
      case 'delete':
        itemOnSuccessDeleted(entity);
        break;
      case 'block':
        itemOnSuccessBloked(entity);
        break;
      default:
        break;
    }
  };

  const CustomSearchToolbar = () => {
    return (
      <div className="mb-3 col-12 d-flex justify-content-between mt-3">
        <div className="d-flex justify-content-between mb-3 ms-3">
          <div className="d-flex align-items-center">
            <label htmlFor="resultsPerPage" className="form-label me-2">
              Resultados:
            </label>
            <select
              className="form-select"
              id="resultsPerPage"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={7}>7</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
        <GridToolbarQuickFilter
          className="me-3 border-1"
          placeholder="Buscar..."
        />
      </div>
    );
  };
  const CustomPagination = () => (
    <div className="col-12 d-flex justify-content-center mt-3 mb-2">
      <Pagination
        count={totalPages}
        page={page + 1}
        onChange={handlePage}
        variant="outlined"
        color="primary"
      />
    </div>
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {displayEntities.length > 0 ? (
            <div className="col-12 mx-auto d-block shadow-lg">
              <div className="card">
                <div className="card-body">
                  <div className="pt-2">
                    <article className="mb-3 d-flex justify-content-between">
                      <h4 className="card-title mt-3 pl-3 mr-auto">
                        Lista de Certificados
                      </h4>
                      <p className=""></p>

                      <button
                        type="button"
                        className="btn btn-primary my-2 me-3"
                        onClick={() => itemOnAction('create')}
                      >
                        Crear nuevo
                      </button>
                    </article>

                    <Box sx={{ height: 600 }} className="w-100">
                      <DataGrid
                        localeText={
                          esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={displayEntities}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        slots={{
                          toolbar: CustomSearchToolbar,
                          pagination: CustomPagination,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12 col-xl-8 col-lg-10 mx-auto d-block">
              <div className="card shadow">
                <div className="card-body">
                  <div className="container pt-2 text-center">
                    <h3 className="text-center">
                      No existen datos, crea un Examen.
                    </h3>
                    <button
                      className="btn btn-primary my-2 me-3"
                      onClick={() => itemOnAction('create')}
                    >
                      Crear nuevo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <PopUp
        data-testid="create-exam-modal"
        id="create-exam-modal"
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        styles={{ width: '70%' }}
      >
        <>
          <CertificationCreate
            setIsOpen={setIsOpenCreateModal}
            onUpdatedEntity={onUpdatedEntity}
          />
          ,
        </>
      </PopUp>
      <PopUp
        data-testid="block-exam-modal"
        id="block-exam-modal"
        isOpen={isOpenBlockModal}
        setIsOpen={setIsOpenBlockModal}
        styles={{ width: '40%' }}
      >
        <>
          <CertificationBlock
            setIsOpen={setIsOpenBlockModal}
            id={selectedEntity.id}
            onUpdatedEntity={onUpdatedEntity}
          />
          ,
        </>
      </PopUp>
      <PopUp
        data-testid="delete-exam-modal"
        id="delete-exam-modal"
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        styles={{ width: '40%' }}
      >
        <>
          <CertificationDelete
            setIsOpen={setIsOpenDeleteModal}
            id={selectedEntity.id}
            onUpdatedEntity={onUpdatedEntity}
          />
          ,
        </>
      </PopUp>
    </>
  );
}

export default CertificationList;
