import React from 'react';

function PageTablePlaceholder({ children, count }) {
  const renderLines = () => {
    return [...Array(count)].map((_, index) => (
      <tr key={index} className="placeholder-wave">
        <th>
          <span className="placeholder col-12"></span>
        </th>
        <td>
          <span className="placeholder col-12"></span>
        </td>
        <td>
          <span className="placeholder col-12"></span>
        </td>
        <td>
          <span className="placeholder col-12"></span>
        </td>
        <td>
          <span className="placeholder col-12"></span>
        </td>
        <td>
          <span className="placeholder col-12"></span>
        </td>
        <td>
          <span className="placeholder col-12"></span>
        </td>
      </tr>
    ));
  };
  return (
    <section className="container-fluid">
      <div className="row">
        <div className="col-12">
          <article className="card shadow">
            <section className="card-body">
              <header className="placeholder-wave">{children}</header>
              <section className="table-responsive">
                <table className="table mb-0">
                  <tbody>{renderLines()}</tbody>
                </table>
              </section>
            </section>
          </article>
        </div>
      </div>
    </section>
  );
}

export default PageTablePlaceholder;
