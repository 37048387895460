import React, { useState, useEffect } from 'react';
import { ExamService } from '@services';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.get(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }
  return entityResponse.result;
}

function ExamBlock({ id, onUpdatedEntity, setIsOpen }) {
  const { openSnackbar } = useSnackbar();

  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    entity: {},
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityResponse = await getEntity(
          {
            id: id,
          },
          ExamService,
        );

        if (!entityResponse) {
          openSnackbar('Examen no encontrado.', 'error');
          return;
        }

        const examData = entityResponse.result;
        setModel((prevData) => ({
          ...prevData,
          entity: {
            name: examData.name,
            courseId: examData.course_id,
            course: examData.course,
            module: examData.module_id,
            is_active: examData.is_active,
            min_score: examData.min_score,
            time_limit: examData.time_limit,
            max_attempts: examData.max_attempts,
            questionsTemp: JSON.parse(examData.questions),
          },
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const confirmOnClick = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setIsLoading(true);

    try {
      const updateStatusDelete = {
        id: id,
        status: { id: 4, name: 'blocked', title: 'Bloqueado' },
      };

      const entityResult = await updateEntity(updateStatusDelete, ExamService);

      if (!entityResult) {
        openSnackbar('Ocurrio un error al bloquear el examen', 'error');
        setIsLoading(false);
        return;
      }

      openSnackbar('Examen bloqueado con exito!', 'success');

      onUpdatedEntity('block', entityResult);
      setIsOpen(false);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const returnOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setIsOpen(false);
  };
  return (
    <>
      <section className="col-12 mx-auto d-block">
        <div className=" pt-2 text-center">
          <h2 className="mb-3">
            Bloquear
            <p className="ms-2 mt-1 fs-3"> "{model.entity.name}"</p>
          </h2>
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <div>
              <p className="card-description fs-4">
                ¿Estás seguro de que quieres bloquear?
              </p>
              <div className="mt-4">
                <button
                  className="btn btn-primary mr-2"
                  onClick={confirmOnClick}
                >
                  <i className="fe-shield-off"></i> Bloquear
                </button>

                <button
                  className="btn btn-light btn-action ms-2"
                  onClick={returnOnClick}
                >
                  <i className="fe-x"></i> Cancelar
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ExamBlock;
