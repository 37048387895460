import { useState } from 'react';
import { formatHelpers } from '@components/utilities';
import { DataGrid, GridToolbarQuickFilter, esES } from '@mui/x-data-grid';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Box,
  Pagination,
} from '@mui/material';
import { Link } from 'react-router-dom';

const FeedbackDataTable = (props) => {
  const { data, itemOnAction } = props;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 7,
    page: 0,
  });
  const [menuActionsAnchorElement, setMenuActionsAnchorElement] =
    useState(null);
  const [menuActionsSelected, setActionsSelected] = useState(null);

  const getBody = (body) => {
    if (!body) {
      return '';
    }
    var text = formatHelpers.extractTextFromHtml(decodeURI(body));
    return text.length > 25 ? text.slice(0, 25) + '...' : text;
  };

  const closeMenuActions = () => {
    setActionsSelected(null);
    setMenuActionsAnchorElement(null);
  };

  const actionsMenuOnClick = (selector, anchorElement) => {
    setActionsSelected(selector || null);
    setMenuActionsAnchorElement(anchorElement || null);
  };

  const columns = [
    {
      field: 'fullname',
      headerName: 'Usuario',
      sortable: true,
      width: 300,
      flex: 2,
      valueGetter: (params) =>
        `${params.row.user.firstname || ''} ${params.row.user.lastname || ''}`,
    },
    {
      field: 'body',
      headerName: 'Mensaje',
      width: 500,
      flex: 2,
      valueGetter: (params) => getBody(params.row.body),
    },
    {
      field: 'is_private',
      headerName: 'Privado',
      width: 150,
      flex: 1,
    },
    {
      field: 'last_modification',
      headerName: 'Fecha',
      type: 'string',
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span className="d-none">
              {formatHelpers.dateWithoutSeparators(
                params.row.last_modification,
              )}
            </span>
            {formatHelpers.dateWithSeparators(params.row.last_modification)}
          </>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Estado',
      type: 'string',
      width: 150,
      flex: 1,
      valueGetter: (params) => params.row.status.title,
      renderCell: (params) => {
        return (
          <span className="badge bg-info fs-6 p-1">
            {params.row.status.title}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'string',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const menuItemOnClick = (event, action) => {
          event?.stopPropagation(); // Prevent select this row after clicking

          itemOnAction(action, params.row);
        };

        return (
          <>
            <IconButton
              disableRipple
              data-testid="user-action-menu-btn"
              aria-label="actions button"
              id={`basic-menu-${params.row.id}`}
              aria-haspopup="true"
              onClick={(event) =>
                actionsMenuOnClick(
                  `basic-menu-${params.row.id}`,
                  event.currentTarget,
                )
              }
            >
              <i className="mdi mdi-dots-vertical fs-4"></i>
            </IconButton>
            <Menu
              elevation={1}
              id={'basic-menu-' + params.row.id}
              anchorEl={menuActionsAnchorElement}
              open={menuActionsSelected === `basic-menu-${params.row.id}`}
              onClose={closeMenuActions}
              MenuListProps={{
                'aria-labelledby': 'user-action-menu-btn',
              }}
            >
              <MenuItem
                component={Link}
                to={`/admin/feedback/management/${params.row.id}`}
                target="_blank"
              >
                <i className="fe-search me-1"></i>
                <ListItemText>Ver/Editar</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={(event) => {
                  menuItemOnClick(event, 'delete');
                  closeMenuActions();
                }}
              >
                <i className="fe-trash me-1"></i>
                <ListItemText>Eliminar</ListItemText>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const { pageSize, page } = paginationModel;
  const totalPages = Math.ceil(data.length / pageSize);

  const handlePage = (event, value) => {
    setPaginationModel({ ...paginationModel, page: value - 1 });
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel({ page: 0, pageSize: Number(e.target.value) });
  };

  function CustomSearchToolbar() {
    return (
      <div className="mb-3 col-12 d-flex justify-content-between mt-3">
        <div className="d-flex justify-content-between mb-3 ms-3">
          <div className="d-flex align-items-center">
            <label htmlFor="resultsPerPage" className="form-label me-2">
              Resultados:
            </label>
            <select
              className="form-select"
              id="resultsPerPage"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={7}>7</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
        <GridToolbarQuickFilter
          className="me-3 border-1"
          placeholder="Buscar..."
        />
      </div>
    );
  }

  const CustomPagination = () => (
    <div className="col-12 d-flex justify-content-center mt-3 mb-2">
      <Pagination
        count={totalPages}
        page={page + 1}
        onChange={handlePage}
        variant="outlined"
        color="primary"
      />
    </div>
  );

  return (
    <>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomSearchToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
    </>
  );
};

export default FeedbackDataTable;
