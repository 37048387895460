import React, { useState } from 'react';
import { UserService } from '@services/index';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function updateEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const UserDelete = (props) => {
  const { selectedEntity, onUpdatedEntity, setIsOpen } = props;
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const confirmOnClick = async () => {
    try {
      setIsLoading(true);
      const status = { id: 3, name: 'deleted', title: 'Deleted' };
      const response = await updateEntity(
        { identity: selectedEntity.id, status },
        UserService,
      );

      if (!response || !response.success) {
        openSnackbar('Ocurrio un error al eliminar', 'error');
        return;
      }

      onUpdatedEntity(selectedEntity);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      openSnackbar('Ha ocurrido un error', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="col-12 mx-auto d-block">
      <div className="card">
        <div className="card-body">
          <div className="container pt-2 text-center">
            <h2 className="mb-3">Eliminar a {selectedEntity.firstname}</h2>
            <p className="card-description">
              ¿Estás seguro de que quieres eliminar?
            </p>
            <div>
              {isLoading ? (
                <button className="btn btn-danger" disabled type="button">
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-danger mr-2"
                    onClick={confirmOnClick}
                  >
                    <i className="fe-trash"></i> Eliminar
                  </button>
                  <button
                    className="btn btn-light btn-action ms-2"
                    onClick={returnOnClick}
                  >
                    <i className="fe-x"></i> Cancelar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDelete;
