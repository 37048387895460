import React, { useState } from 'react';
import { InterestedService } from '@services';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const InterestedDeleteComponent = ({
  entitySelected,
  onUpdatedEntity,
  setIsOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { openSnackbar } = useSnackbar();

  const confirmOnClick = async () => {
    try {
      setIsLoading(true);
      const updatedData = {
        ...entitySelected,
        status: { id: 3, name: 'deleted', title: 'Eliminado' },
      };

      const entityService = new InterestedService();
      const entityResponse = await entityService.update(updatedData);

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        return null;
      }

      onUpdatedEntity('delete', updatedData);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="card-body">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container pt-2 text-center mb-4">
          <h2 className="mb-3"> Eliminar "{entitySelected.name}"</h2>
          <p className="card-description">
            ¿Estás seguro de que quieres eliminar este elemento?
          </p>

          <button
            title="Submit"
            type="submit"
            className="btn btn-danger btn-action mx-2"
            onClick={confirmOnClick}
          >
            <i className="bi bi-check"></i> Eliminar
          </button>
          <button
            title="Cancel"
            type="button"
            className="btn btn-light btn-action mx-2"
            onClick={returnOnClick}
          >
            <i className="bi bi-x"></i> Cancelar
          </button>
        </div>
      )}
    </div>
  );
};

export default InterestedDeleteComponent;
