import React, { useEffect, useState } from 'react';
import { useAuth } from '@hooks/useAuth.hook';
import { InterestedService, CourseService, UserService } from '@services';
import { useParams } from 'react-router-dom';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import Alert from '@components/shared/alert/Alert';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

function InterestedDetailEditComponent(props) {
  const { user } = useAuth();
  const { interestedId } = useParams();
  const { openSnackbar } = useSnackbar();
  const [entity, setEntity] = useState({
    name: '',
    email: '',
    phone: '',
    occupation: '',
    course: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const loadEntities = async () => {
    try {
      setIsLoading(true);
      const interestedEntityService = new InterestedService();
      const courseEntityService = new CourseService();
      const userEntityService = new UserService();

      const [interestedResponse, courseResponse, userResponse] =
        await Promise.all([
          interestedEntityService.get({ id: interestedId }),
          courseEntityService.get({ all: true }),
          userEntityService.get({ id: user.id }),
        ]);

      if (
        !interestedResponse ||
        !interestedResponse.success ||
        !courseResponse ||
        !courseResponse.success ||
        !userResponse ||
        !userResponse.success
      ) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return null;
      }

      setCourses(courseResponse.result);
      setEntity({
        ...interestedResponse.result,
        user: userResponse.result,
        user_id: userResponse.result.id,
      });
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEntities();
  }, []);

  useEffect(() => {
    if (isConfirm === true) {
      alertInfo.type === 'confirm-cancel' && props.setIsEditMode(false);
    } else if (isConfirm === false) {
      alertInfo.type === 'success' && props.setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const confirmOnClick = async () => {
    try {
      setIsLoading(true);
      const updatedData = {
        ...entity,
      };

      const entityService = new InterestedService();
      const entityResult = await entityService.update(updatedData);

      if (!entityResult || !entityResult.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }

      setAlertInfo({
        message:
          'Tus cambios han sido guardados. ¿Quieres seguir editando o prefieres salir?',
        type: 'success',
      });

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    }
  };

  const returnOnClick = () => {
    setAlertInfo({
      message:
        '¿Estás seguro de salir de la vista de edición? Se perderán los cambios realizados.',
      type: 'confirm-cancel',
    });
  };

  const handleCourseChange = (event) => {
    const selectedCourse = courses.find(
      (course) => course.name === event.target.value,
    );
    setEntity((prevState) => ({ ...prevState, course: selectedCourse.name }));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEntity((prevState) => ({ ...prevState, [id]: value }));
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Cambios Guardados Exitosamente',
          description: alertInfo.message,
          typeIcon: 'success',
          confirmButtonText: 'Seguir Editando',
          cancelButtonText: 'Salir',
          showCancelButton: true,
        };
      default:
        return null;
    }
  };

  return (
    <div>
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <form onSubmit={(e) => confirmOnClick(e)}>
            <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre completo
                </label>
                <input
                  type="text"
                  required
                  autoComplete="off"
                  className="form-control"
                  id="name"
                  placeholder=""
                  value={entity.name}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  required
                  id="email"
                  placeholder=""
                  value={entity.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6 mb-3">
                <label className="form-label" htmlFor="phone">
                  Celular
                </label>
                <input
                  value={entity.phone}
                  onChange={handleChange}
                  type="text"
                  autoComplete="off"
                  required
                  className="form-control"
                  id="phone"
                  placeholder=""
                />
              </div>

              <div className="form-group col-md-6 mb-3">
                <label htmlFor="occupation" className="form-label">
                  Profesión
                </label>
                <input
                  value={entity.occupation}
                  onChange={handleChange}
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="occupation"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 mb-3">
                <label className="form-label" htmlFor="course">
                  Curso
                </label>
                <select
                  className="form-select"
                  name="course"
                  value={entity.course || 'default'}
                  onChange={handleCourseChange}
                >
                  <option value="default" disabled>
                    Seleccione un curso
                  </option>
                  {courses.map((_entity, key) => (
                    <option key={key} value={_entity.name}>
                      {_entity.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <button
              title="submit"
              type="submit"
              className="btn btn-primary mb-3 me-3"
            >
              <i className="feather-icon" data-feather="check"></i>
              Guardar
            </button>
            <button
              title="Cancel"
              type="button"
              className="btn mb-3 btn-light"
              onClick={() => returnOnClick()}
            >
              <i className="feather-icon" data-feather="x"></i> Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default InterestedDetailEditComponent;
