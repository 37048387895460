import React, { useEffect, useState } from 'react';
import { UserCourseService } from '@services/index';
import {
  UserCourseCreate,
  UserCourseDelete,
  UserCourseAccept,
  UserCourseBlock,
} from '@components/pages';
import { PopUp } from '@components/shared/popup/PopUp';
import UserCourseDataTable from './components/UserCourseDataTable';
import PageTablePlaceholder from '@components/shared/loaders/placeholder/PageTablePlaceholder';
import PageTitlePlaceholder from '@components/shared/loaders/placeholder/PageTitlePlaceholder';
import PageDescriptionPlaceholder from '@components/shared/loaders/placeholder/PageDescriptionPlaceholder';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const Loader = () => (
  <PageTablePlaceholder count={4}>
    <PageTitlePlaceholder count={1}>
      <PageDescriptionPlaceholder count={1} />
    </PageTitlePlaceholder>
  </PageTablePlaceholder>
);

function UserCourseList() {
  const { openSnackbar } = useSnackbar();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});

  const getExportData = async () => {
    try {
      const entityService = new UserCourseService();
      await entityService.exportAll({ all: true });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityService = new UserCourseService();
        const entityResponse = await entityService.get({
          all: true,
        });

        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Error al obtener la data', 'error');
          return;
        }

        setEntities(entityResponse.result);
      } catch (error) {
        openSnackbar('Ocurrio un error', 'error');
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteEntity = (entity) => {
    setEntities((prevState) => {
      const newEntities = prevState.filter(
        (_entity) => _entity.id !== entity.id,
      );

      return newEntities;
    });
  };

  const handleCreateEntity = (entity) => {
    setEntities((prevEntities) => [...prevEntities, entity]);
  };

  const handleUpdateEntity = (entity) => {
    setEntities((prevEntities) => {
      const updatedEntities = prevEntities.map((prevEntity) => {
        if (prevEntity.id === entity.id) {
          return entity;
        }
        return prevEntity;
      });
      return updatedEntities;
    });
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateModal(false);
        handleCreateEntity(entity);
        openSnackbar('Recomendacion creada con exito!', 'success');
        break;
      case 'approve':
        setIsOpenApproveModal(false);
        handleUpdateEntity(entity);
        openSnackbar('Aprobación exitosa!', 'success');
        break;
      case 'block':
        setIsOpenBlockModal(false);
        handleUpdateEntity(entity);
        openSnackbar('Bloqueo exitoso!', 'success');
        break;
      case 'delete':
        setIsOpenDeleteModal(false);
        handleDeleteEntity(entity);
        openSnackbar('Eliminación exitosa!', 'success');
        break;
      default:
        break;
    }
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateModal(true);
        break;
      case 'approve':
        setIsOpenApproveModal(true);
        setSelectedEntity(entity);
        break;
      case 'block':
        setIsOpenBlockModal(true);
        setSelectedEntity(entity);
        break;
      case 'delete':
        setIsOpenDeleteModal(true);
        setSelectedEntity(entity);
        break;
      default:
        break;
    }
  };

  const handleExportAll = () => {
    getExportData();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {(!entities || !entities.length) && (
            <div className="col-12 col-xl-8 col-lg-10 mx-auto d-block">
              <div className="card-body">
                <div className="container pt-2 text-center">
                  <h3 className="text-cente mb-4">
                    No existen datos, crea un curso pagado.
                  </h3>

                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/private/img/empty-content.svg`}
                    alt="empty content"
                    className="d-block mx-auto w-50 img-fluid"
                  />

                  <button
                    className="btn btn-primary text-wrap"
                    onClick={() => itemOnAction('create')}
                  >
                    Click aquí para crear un curso pagado
                  </button>
                </div>
              </div>
            </div>
          )}
          {entities && entities.length > 0 && (
            <div className="card-body pt-2">
              <article className="mb-3 d-flex">
                <h4 className="card-title mt-3 me-auto">
                  Lista de cursos pagados
                </h4>
                <button
                  className="btn btn-primary my-2 me-3"
                  onClick={handleExportAll}
                >
                  Descargar todo
                </button>
                <button
                  type="button"
                  className="btn btn-primary my-2 me-3"
                  onClick={() => itemOnAction('create')}
                >
                  Crear nuevo
                </button>
              </article>
              <div className="table-responsive mt-3">
                <UserCourseDataTable
                  data={entities}
                  itemOnAction={itemOnAction}
                />
              </div>
            </div>
          )}
        </>
      )}
      <PopUp
        title="Crear nuevo curso pagado"
        data-testid="create-usercourse-modal"
        id="create-usercourse-modal"
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        styles={{ width: '50%' }}
      >
        <>
          <UserCourseCreate
            setIsOpen={setIsOpenCreateModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('create', entity)}
          />
        </>
      </PopUp>
      <PopUp
        title="Eliminar"
        data-testid="delete-usercourse-modal"
        id="delete-usercourse-modal"
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        styles={{ width: '30%' }}
      >
        <>
          <UserCourseDelete
            setIsOpen={setIsOpenDeleteModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('delete', entity)}
            selectedEntity={selectedEntity}
          />
        </>
      </PopUp>
      <PopUp
        title="Aprobar"
        data-testid="approve-usercourse-modal"
        id="create-usercourse-modal"
        isOpen={isOpenApproveModal}
        setIsOpen={setIsOpenApproveModal}
        styles={{ width: '30%' }}
      >
        <>
          <UserCourseAccept
            setIsOpen={setIsOpenApproveModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('approve', entity)}
            selectedEntity={selectedEntity}
          />
        </>
      </PopUp>
      <PopUp
        title="Bloquear"
        data-testid="block-usercourse-modal"
        id="block-usercourse-modal"
        isOpen={isOpenBlockModal}
        setIsOpen={setIsOpenBlockModal}
        styles={{ width: '30%' }}
      >
        <>
          <UserCourseBlock
            setIsOpen={setIsOpenBlockModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('block', entity)}
            selectedEntity={selectedEntity}
          />
        </>
      </PopUp>
    </>
  );
}

export default UserCourseList;
