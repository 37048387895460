import React, { useState, useEffect } from 'react';
import { CourseResourceService } from '@services';
import { useParams } from 'react-router-dom';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';
import DocumentViewerComponent from '@components/shared/documentviewer/DocumentViewerComponent';

const CourseResourceDetailViewComponent = () => {
  const { openSnackbar } = useSnackbar();
  const [entity, setEntity] = useState({
    status: {},
    attachments: [{ uri: '' }],
  });
  const { courseResourceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState('lobby');

  const getSelectedEntity = async () => {
    try {
      const entityService = new CourseResourceService();
      const entityResponse = await entityService.get({
        id: courseResourceId,
      });

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }

      setEntity(entityResponse.result);
      setIsLoading(false);
    } catch (error) {
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSelectedEntity();
  }, [courseResourceId]);

  const getBody = (body) => {
    if (!body) {
      return '';
    }

    return window.decodeURI(body);
  };

  const onlineOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setView('viewer');
    setEntity((prevState) => ({
      ...prevState,
      attachments: prevState.attachments.length && [
        {
          ...prevState.attachments[0],
          uri: prevState.attachments[0].uri + ' ',
        },
      ],
    }));
  };

  const returnOnClick = () => {
    setView('lobby');
  };

  return (
    <div>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '300px' }}
        >
          <Loader />
        </div>
      ) : (
        <div className="card-body">
          <h4 className="card-title text-center my-3">{entity.name}</h4>
          {view === 'lobby' && (
            <div className="border pd-1 rounded bg-light shadow-sm">
              {entity.attachments.length > 0 || entity.description ? (
                <section className="d-flex flex-column flex-md-row justify-content-center my-4 py-3">
                  <a
                    className="btn btn-primary me-md-2 mb-2"
                    href={
                      entity.attachments.length && entity.attachments[0].uri
                    }
                  >
                    Descargar material
                  </a>
                  <button
                    className="btn btn-success ms-md-2 mb-2"
                    onClick={onlineOnClick}
                  >
                    Ver online
                  </button>
                </section>
              ) : (
                <section className="d-flex flex-column flex-md-row justify-content-center my-4 py-3">
                  <div>
                    Lo sentimos, no hay material disponible en este momento.
                  </div>
                </section>
              )}
            </div>
          )}

          {view === 'viewer' && (
            <div className="mt-4 border pd-1 rounded bg-light shadow-sm">
              {entity.description && (
                <div
                  className="body-content mb-4"
                  dangerouslySetInnerHTML={{
                    __html: getBody(entity.description),
                  }}
                ></div>
              )}
              {entity.attachments.length > 0 && (
                <div>
                  <section className="mb-3">
                    <DocumentViewerComponent
                      url={entity.attachments[0].uri}
                      fileType={entity.attachments[0].attachmentType}
                    />
                  </section>
                </div>
              )}
              <button
                title="Volver"
                type="button"
                className="btn btn-primary mb-3 btn-action mx-2"
                onClick={returnOnClick}
              >
                <i className="bi bi-x"></i> Volver
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CourseResourceDetailViewComponent;
