import React from 'react';

function PageDescriptionPlaceholder({ count }) {
  const renderLines = () => {
    return [...Array(count)].map((_, index) => (
      <div key={index}>
        <span className="placeholder col-5"></span>
      </div>
    ));
  };
  return <>{renderLines()}</>;
}

export default PageDescriptionPlaceholder;
