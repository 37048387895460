import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  CourseDetailEditComponent,
  CourseDetailViewComponent,
} from '@components/pages';
import { useState } from 'react';
import Alert from '@components/shared/alert/Alert';

function CourseDetailView() {
  const { setPageName } = useOutletContext();

  const [isConfirm, setIsConfirm] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    setPageName('Cursos');
  }, []);

  useEffect(() => {
    if (alertInfo.type === 'confirm-cancel' && isConfirm === true) {
      setIsEditMode(false);
    }

    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleEditModeToggle = () => {
    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      setAlertInfo({
        message: '¿Estás seguro de que deseas abandonar el modo de edición?',
        type: 'confirm-cancel',
      });
    }
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      default:
        return null;
    }
  };

  return (
    <div className="col-12 mx-auto d-block card">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      <section>
        <div className="card-body d-flex border-bottom justify-content-between px-3 py-2 align-items-center mb-1">
          <h4 className="align-items-center">
            {isEditMode ? 'Editar Curso' : 'Detalles del Curso'}
          </h4>

          <div className="form-check form-switch">
            <input
              className="form-check-input fs-4"
              role="switch"
              type="checkbox"
              id="editModeSwitch"
              checked={isEditMode}
              onChange={handleEditModeToggle}
              data-bs-toggle="tooltip"
              title="Toggle edit mode"
            />
            <label className="form-check-label fs-4" htmlFor="editModeSwitch">
              Modo Edición
            </label>
          </div>
        </div>
        {isEditMode ? (
          <CourseDetailEditComponent setIsEditMode={setIsEditMode} />
        ) : (
          <CourseDetailViewComponent />
        )}
      </section>
    </div>
  );
}

export default CourseDetailView;
