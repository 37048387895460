import { useState, useEffect } from 'react';
import { formatHelpers } from '@components/utilities/';
import { UserExamService } from '@services/index';
import { useParams, Link } from 'react-router-dom';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';
import { PopUp } from '@components/shared/popup/PopUp';
import { UserApprovedExamDelete } from '@components/pages';
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from '@mui/material/';

const UserApprovedExams = () => {
  const { id: userId } = useParams();
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [myExams, setMyExams] = useState([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [menuActionsAnchorElement, setMenuActionsAnchorElement] =
    useState(null);
  const [menuActionsSelected, setActionsSelected] = useState(null);

  useEffect(() => {
    const fetchExamsByQuery = async (query, Entity) => {
      try {
        const entityService = new Entity();
        const entityResponse = await entityService.get(query);
        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Ocurrio un error al obtener los examenes', 'error');
          return;
        }

        setMyExams(entityResponse.result);
      } catch (error) {
        console.log(error);
        openSnackbar('Ha ocurrido un error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchExamsByQuery(
      {
        all: true,
        userId,
        filterBy: [
          {
            key: 'status.name',
            value: 'passedexam',
          },
        ],
      },
      UserExamService,
    );
  }, [userId]);

  const handleDeleteEntity = (entity) => {
    setMyExams((prevState) => {
      const entityIndex = prevState.findIndex(
        (_entity) => _entity.id === entity.id,
      );

      if (entityIndex > -1) {
        prevState.splice(entityIndex, 1);
      }

      return prevState;
    });
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'delete':
        setIsOpenDeleteModal(false);
        handleDeleteEntity(entity);
        openSnackbar('Eliminación exitosa!', 'success');
        break;
      default:
        break;
    }
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'delete':
        setIsOpenDeleteModal(true);
        setSelectedEntity(entity);
        break;
      default:
        break;
    }
  };

  const closeMenuActions = () => {
    setActionsSelected(null);
    setMenuActionsAnchorElement(null);
  };

  const actionsMenuOnClick = (selector, anchorElement) => {
    setActionsSelected(selector || null);
    setMenuActionsAnchorElement(anchorElement || null);
  };

  const MenuDots = (row) => {
    const menuItemOnClick = (event, action) => {
      event?.stopPropagation(); // Prevent select this row after clicking

      itemOnAction(action, row);
    };

    return (
      <>
        <IconButton
          data-testid="user-action-menu-btn"
          aria-label="actions button"
          id={`basic-menu-${row.id}`}
          aria-haspopup="true"
          onClick={(event) =>
            actionsMenuOnClick(`basic-menu-${row.id}`, event.currentTarget)
          }
        >
          <i className="mdi mdi-dots-vertical fs-4"></i>
        </IconButton>
        <Menu
          elevation={1}
          id={'basic-menu-' + row.id}
          anchorEl={menuActionsAnchorElement}
          open={menuActionsSelected === `basic-menu-${row.id}`}
          onClose={closeMenuActions}
          MenuListProps={{
            'aria-labelledby': 'user-action-menu-btn',
          }}
        >
          <MenuItem
            component={Link}
            to={`/admin/user/exam/detail/${row.id}`}
            target="_blank"
          >
            <i className="fe-search me-1"></i>
            <ListItemText>Ver/Editar</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              menuItemOnClick(event, 'delete');
              closeMenuActions();
            }}
          >
            <i className="fe-trash me-1"></i>
            <ListItemText>Eliminar</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <section className="col-12 col-md-10 mx-auto d-block">
      <div className="mb-4">
        <h4 className="mt-4 text-center">Exámenes aprobados</h4>

        <div className="row">
          <div className="table-responsive mt-3">
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Examen</TableCell>
                      <TableCell>Último intento</TableCell>
                      <TableCell>Intentos</TableCell>
                      <TableCell>Último puntaje</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myExams?.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.exam.name}
                        </TableCell>
                        <TableCell>
                          <span className="d-none">
                            {formatHelpers.dateWithoutSeparators(
                              row.last_modification,
                            )}
                          </span>
                          {formatHelpers.dateWithSeparators(
                            row.last_modification,
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.attempts}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.last_score}
                        </TableCell>
                        <TableCell>
                          <span className="badge bg-info fs-6 p-1">
                            {row.status.title}
                          </span>
                        </TableCell>
                        <TableCell>{MenuDots(row)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
      <PopUp
        title="Eliminar"
        data-testid="delete-myexam-modal"
        id="delete-myexam-modal"
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        styles={{ width: '30%' }}
      >
        <UserApprovedExamDelete
          setIsOpen={setIsOpenDeleteModal}
          onUpdatedEntity={(entity) => onUpdatedEntity('delete', entity)}
          selectedEntity={selectedEntity}
        />
      </PopUp>
    </section>
  );
};

export default UserApprovedExams;
