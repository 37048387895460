import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CertificationList } from '../../components/pages/index';

function CertificationManagement() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Certificaciones');
  }, []);
  return (
    <div>
      <CertificationList />
    </div>
  );
}

export default CertificationManagement;
