import React, { useState } from 'react';
import { UserCourseService } from '@services/index';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function updateEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const UserEnrolledCourseApprove = (props) => {
  const { selectedEntity, setIsOpen } = props;
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const confirmOnClick = async () => {
    try {
      setIsLoading(true);
      const status = { id: 6, name: 'approved', title: 'Aprobado' };
      const response = await updateEntity(
        { id: selectedEntity.usercourse_id, status },
        UserCourseService,
      );

      if (!response || !response.success) {
        openSnackbar('Ha ocurrido un error al aprobar', 'error');
        return;
      }

      setIsOpen(false);
      openSnackbar('Actualización exitosa!', 'success');
    } catch (error) {
      openSnackbar('Ha ocurrido un error', 'error');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  return (
    <section className="col-12 mx-auto d-block">
      <div className="card card-body">
        <article className="container pt-2 text-center">
          <h2 className="mb-2">¿Estás seguro?</h2>
          <p className="fs-5">Intentas aceptar la solicitud</p>
          <div className="mt-4">
            {isLoading ? (
              <button class="btn btn-primary" disabled type="button">
                <span
                  class="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <>
                <button
                  className="btn btn-primary mr-2"
                  onClick={confirmOnClick}
                >
                  <i className="fe-check"></i> Aprobar
                </button>
                <button
                  className="btn btn-light btn-action ms-2"
                  onClick={returnOnClick}
                >
                  <i className="fe-x"></i> Cancelar
                </button>
              </>
            )}
          </div>
        </article>
      </div>
    </section>
  );
};

export default UserEnrolledCourseApprove;
