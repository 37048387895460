import React, { useEffect, useState } from 'react';
import { UserService } from '@services/index';
import { UserCreate, UserDelete, UserBlock } from '@components/pages';
import { PopUp } from '@components/shared/popup/PopUp';
import UserDataTable from './components/UserDataTable';
import PageTablePlaceholder from '@components/shared/loaders/placeholder/PageTablePlaceholder';
import PageTitlePlaceholder from '@components/shared/loaders/placeholder/PageTitlePlaceholder';
import PageDescriptionPlaceholder from '@components/shared/loaders/placeholder/PageDescriptionPlaceholder';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const Loader = () => (
  <PageTablePlaceholder count={4}>
    <PageTitlePlaceholder count={1}>
      <PageDescriptionPlaceholder count={1} />
    </PageTitlePlaceholder>
  </PageTablePlaceholder>
);

function UserList() {
  const { openSnackbar } = useSnackbar();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityService = new UserService();
        const entityResponse = await entityService.get({
          all: true,
        });

        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Error al obtener la data', 'error');
          return;
        }

        setEntities(entityResponse.result);
      } catch (error) {
        console.log(error);
        openSnackbar('Ocurrio un error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getExportAll = async () => {
    const entityService = new UserService();
    await entityService.exportAll({
      all: true,
    });
  };

  const handleExportAll = () => {
    try {
      getExportAll();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteEntity = (entity) => {
    setEntities((prevState) => {
      const newEntities = prevState.filter(
        (_entity) => _entity.id !== entity.id,
      );

      return newEntities;
    });
  };

  const handleCreateEntity = (entity) => {
    setEntities((prevEntities) => [...prevEntities, entity]);
  };

  const handleUpdateEntity = (entity) => {
    setEntities((prevEntities) => {
      const updatedEntities = prevEntities.map((prevEntity) => {
        if (prevEntity.id === entity.id) {
          return entity;
        }
        return prevEntity;
      });
      return updatedEntities;
    });
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateModal(false);
        handleCreateEntity(entity);
        openSnackbar('Usuario creado con exito!', 'success');
        break;
      case 'block':
        setIsOpenBlockModal(false);
        handleUpdateEntity(entity);
        openSnackbar('Bloqueo exitoso!', 'success');
        break;
      case 'delete':
        setIsOpenDeleteModal(false);
        handleDeleteEntity(entity);
        openSnackbar('Eliminación exitosa!', 'success');
        break;
      default:
        break;
    }
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateModal(true);
        break;
      case 'block':
        setIsOpenBlockModal(true);
        setSelectedEntity(entity);
        break;
      case 'delete':
        setIsOpenDeleteModal(true);
        setSelectedEntity(entity);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {(!entities || !entities.length) && (
            <div className="col-12 col-xl-8 col-lg-10 mx-auto d-block">
              <div className="card-body">
                <div className="container pt-2 text-center">
                  <h3 className="text-center mb-4">
                    No existen datos, crea un usuario.
                  </h3>

                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/private/img/empty-content.svg`}
                    alt="empty content"
                    className="d-block mx-auto w-50 img-fluid"
                  />

                  <button
                    type="button"
                    className="btn btn-primary my-2 me-3"
                    onClick={() => itemOnAction('create')}
                  >
                    Click aquí para crear un usuario
                  </button>
                </div>
              </div>
            </div>
          )}

          {entities && entities.length > 0 && (
            <div className="card-body pt-2">
              <article className="mb-3 d-flex">
                <h4 className="card-title mt-3 me-auto">Lista de usuarios</h4>
                <button
                  className="btn btn-primary my-2 me-3"
                  onClick={handleExportAll}
                >
                  Descargar todo
                </button>
                <button
                  type="button"
                  className="btn btn-primary my-2 me-3"
                  onClick={() => itemOnAction('create')}
                >
                  Crear nuevo
                </button>
              </article>
              <div className="table-responsive mt-3">
                <UserDataTable data={entities} itemOnAction={itemOnAction} />
              </div>
            </div>
          )}
        </>
      )}
      <PopUp
        title="Crear nuevo usuario"
        data-testid="create-user-modal"
        id="create-user-modal"
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        styles={{ width: '50%' }}
      >
        <>
          <UserCreate
            setIsOpen={setIsOpenCreateModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('create', entity)}
          />
        </>
      </PopUp>
      <PopUp
        title="Eliminar"
        data-testid="delete-user-modal"
        id="delete-user-modal"
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        styles={{ width: '30%' }}
      >
        <>
          <UserDelete
            setIsOpen={setIsOpenDeleteModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('delete', entity)}
            selectedEntity={selectedEntity}
          />
        </>
      </PopUp>
      <PopUp
        title="Bloquear"
        data-testid="block-user-modal"
        id="block-user-modal"
        isOpen={isOpenBlockModal}
        setIsOpen={setIsOpenBlockModal}
        styles={{ width: '30%' }}
      >
        <>
          <UserBlock
            setIsOpen={setIsOpenBlockModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('block', entity)}
            selectedEntity={selectedEntity}
          />
        </>
      </PopUp>
    </>
  );
}

export default UserList;
