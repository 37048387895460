import React from 'react';
import Select from 'react-select';

const SearchableSelect = (props) => {
  const {
    options,
    onChange,
    placeholder,
    isDisabled,
    defaultValue,
    ...rest
  } = props;

  const searchDefaultValue = (value) => {
    if (!value) {
      return null;
    }

    return options.find((_option) => _option.value === value);
  };

  return (
    <Select
      options={options}
      onChange={(selectedOption) => onChange(selectedOption.value)}
      isSearchable
      isDisabled={isDisabled}
      defaultValue={searchDefaultValue(defaultValue)}
      placeholder={placeholder}
      noOptionsMessage={() => "Cargando los datos"}
      {...rest}
    />
  );
};

export default SearchableSelect;
