import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FeedbackService, CourseService } from '@services/index';
import SearchableSelect from './components/SearchableSelect';
import Alert from '@components/shared/alert/Alert';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import Rating from '@mui/material/Rating';

async function updateEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const FeedbackEdit = (props) => {
  const { setIsEditMode } = props;
  const { id: feedbackId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });
  const [courses, setCourses] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [entity, setEntity] = useState({
    is_private: false,
    score: 5,
  });

  const showErrorAlert = (message) => setAlertInfo({ message, type: 'error' });
  const showConfirmCancelAlert = (message) =>
    setAlertInfo({ message, type: 'confirm-cancel' });
  const showSuccessAlert = (message) =>
    setAlertInfo({ message, type: 'success' });

  const getSelectedEntity = async () => {
    const entityService = new FeedbackService();
    const entityResponse = await entityService.get({
      id: feedbackId,
    });

    if (!entityResponse || !entityResponse.success) {
      setAlertInfo({
        message: 'Ha ocurrido un error. Por favor intenta mas tarde',
        type: 'error',
      });
      return;
    }

    setEntity(entityResponse.result);
  };

  const getAllCourses = async () => {
    try {
      const entityService = new CourseService();
      const entityResponse = await entityService.get({
        all: true,
      });
      if (!entityResponse || !entityResponse.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }

      const adapterForSelectOptions = entityResponse.result.map((_entity) => {
        return {
          value: _entity.id,
          label: _entity.name,
        };
      });

      setCourseOptions(adapterForSelectOptions);
      setCourses(entityResponse.result);
    } catch (error) {
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
      console.log(error);
    }
  };

  useEffect(() => {
    if (isConfirm === true) {
      alertInfo.type === 'confirm-cancel' && setIsEditMode(false);
    } else if (isConfirm === false) {
      alertInfo.type === 'success' && setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    Promise.all([getSelectedEntity(), getAllCourses()])
      .then((resp) => setIsLoading(false))
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const confirmOnClick = async (event) => {
    event.preventDefault();
    const course = courses.find((_entity) => _entity.id === entity.course_id);

    try {
      setIsLoadingUpdate(true);
      const entityData = {
        ...entity,
        score: +entity.score,
        course,
      };

      const response = await updateEntity(
        { ...entityData, id: feedbackId },
        FeedbackService,
      );

      if (!response || !response.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }

      showSuccessAlert(
        'Tus cambios han sido guardados. ¿Quieres seguir editando o prefieres salir?',
      );
    } catch (error) {
      console.error(error);
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const returnOnClick = () => {
    showConfirmCancelAlert(
      '¿Estás seguro de salir de la vista de edición? Se perderán los cambios realizados.',
    );
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setEntity((prevEntity) => ({
      ...prevEntity,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleUserInputChanges = (event) => {
    const { name, value } = event.target;

    setEntity((prevEntity) => ({
      ...prevEntity,
      user: {
        ...prevEntity.user,
        [name]: value,
      },
    }));
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Cambios Guardados Exitosamente',
          description: alertInfo.message,
          typeIcon: 'success',
          confirmButtonText: 'Seguir Editando',
          cancelButtonText: 'Salir',
          showCancelButton: true,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };

      default:
        return null;
    }
  };

  return isLoading ? (
    <SpinnerLoader />
  ) : (
    <div className="card-body mx-auto">
      <form>
        {alertInfo.type && (
          <Alert
            config={getAlertConfig(alertInfo.type)}
            setConfirm={setIsConfirm}
          />
        )}
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <label className="col-form-label">Usuario nombres</label>
            <input
              value={entity.user.firstname}
              onChange={handleUserInputChanges}
              type="text"
              name="firstname"
              autoComplete="off"
              className="form-control"
              id="firstname"
              placeholder=""
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label className="col-form-label">Usuario apellidos</label>
            <input
              value={entity.user.lastname}
              onChange={handleUserInputChanges}
              name="lastname"
              type="text"
              autoComplete="off"
              className="form-control"
              id="lastname"
              placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="form-label">Curso</label>
            <SearchableSelect
              options={courseOptions}
              defaultValue={entity.course_id}
              onChange={(event) =>
                setEntity((prevState) => ({
                  ...prevState,
                  course_id: event.value,
                }))
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 mb-3">
            <label className="col-form-label">¿Enviar como anónimo?</label>
            <div className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="published-status-input"
                name="is_private"
                checked={entity.is_private}
                onChange={handleInputChange}
              />
              <label
                className="form-check-label"
                htmlFor="published-status-input"
              >
                {entity.is_private ? 'Si' : 'No'}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3">
            <label className="col-form-label">Mostrar en landing</label>
            <div className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="landing-status-input"
                name="is_landing"
                checked={entity.is_landing}
                onChange={handleInputChange}
              />
              <label
                className="form-check-label"
                htmlFor="landing-status-input"
              >
                {entity.is_landing ? 'Si' : 'No'}
              </label>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3 d-flex flex-column ">
            <label className="form-label">Puntaje</label>
            <Rating
              name="score"
              defaultValue={entity.score}
              size="large"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label className="col-form-label">Mensaje</label>
            <textarea
              value={entity.body}
              onChange={handleInputChange}
              name="body"
              type="text"
              autoComplete="off"
              className="form-control"
              id="body"
              placeholder=""
            ></textarea>
          </div>
        </div>

        <div className="mt-4">
          {isLoadingUpdate ? (
            <button class="btn btn-primary" disabled type="button">
              <span
                class="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Guardando...
            </button>
          ) : (
            <>
              <button
                title="Submit"
                type="submit"
                className="btn btn-primary btn-action me-2"
                onClick={confirmOnClick}
              >
                <i className="fe-check me-1"></i>
                Guardar recomendación
              </button>
              <button
                title="Cancel"
                type="button"
                className="btn btn-light btn-action ms-2"
                onClick={returnOnClick}
              >
                <i className="fe-x"></i> cancelar
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default FeedbackEdit;
