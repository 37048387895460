import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LandPageService } from '@services';
import TextEditor from '@components/shared/texteditor/TextEditor';
import SpinnerLoader from '../../../components/shared/loaders/spinner/SpinnerLoader';
import Alert from '@components/shared/alert/Alert';
import Upload from '@components/shared/uploader/Uploader';
import { v4 as uuidv4 } from 'uuid';

async function getEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }
  return entityResponse.result;
}

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }
  return entityResponse.result;
}

async function uploadFile(payload, path, entity) {
  const entityService = new entity();
  payload.append('folder', 'landpage');
  const entityResponse = await entityService.post(payload, path);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }
  return entityResponse.result;
}

function LandPageEdit() {
  const navigate = useNavigate();

  const initialState = {
    hero: {
      items: [{ attachment: { url: '' } }],
    },
    benefits: {
      items: [{ attachment: { url: '' } }],
    },
    about: {
      items: [{ attachment: { url: '' } }],
    },
    gallery: {
      items: [{ attachment: { url: '' } }],
    },
    team: {
      items: [{ attachment: { url: '' } }],
    },
    testimonials: {
      items: [{ attachment: { url: '' } }],
    },
    courses: {
      items: [{ attachment: { url: '' } }],
    },
    form: {
      items: [{ attachment: { url: '' } }],
    },
  };

  const [isAlertActive, setIsAlertActive] = useState(null);
  const [isconfirm, setIsConfirm] = useState(null);

  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [entity, setEntity] = useState(initialState);

  const [isLoadingFiles, setIsLoadingFiles] = useState(
    Array(entity.length).fill(false),
  );

  useEffect(() => {
    if (isconfirm === false) {
      setIsAlertActive(null);
      setIsConfirm(null);
      navigate('/admin');
    }
  }, [isconfirm, isAlertActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityResponse = await getEntity(
          {
            queryselector: 'landpage',
          },
          LandPageService,
        );

        if (entityResponse) {
          const dataLandPage = entityResponse[0];
          parseBody(dataLandPage);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  setTimeout((_) => {
    entity.benefits.items.forEach((question) => {
      question.description += ' ';
    });
  }, 200);

  const openAlert = (title, message, icon, isSuccess) => {
    setAlertMessage((prevData) => ({
      ...prevData,
      title,
      message,
      icon,
    }));
    setIsAlertActive(isSuccess);
  };

  const parseBody = (entity) => {
    if (!entity || !entity.body) {
      setEntity(initialState);
      return;
    }

    const bodyTemp = JSON.parse(entity.body);

    setEntity((prevData) => ({
      ...prevData,
      hero: bodyTemp.hero || {},
      benefits: bodyTemp.benefits || {},
      about: bodyTemp.about || {},
      gallery: bodyTemp.gallery || {},
      team: bodyTemp.team || {},
      testimonials: bodyTemp.testimonials || {},
      courses: bodyTemp.courses || {},
      form: bodyTemp.form || {},
    }));
  };

  const removeItem = (property, index) => {
    if (!property || index === undefined) {
      return;
    }

    const items = entity[property].items;

    if (!items || !items.length) {
      return;
    }
    setEntity((prevEntity) => {
      return {
        ...prevEntity,
        [property]: {
          ...prevEntity[property],
          items: prevEntity[property].items.filter((_, i) => i !== index),
        },
      };
    });
  };

  const addItem = (property, item) => {
    if (!property || !item) {
      return;
    }

    setEntity((prevEntity) => {
      const itemsArray = prevEntity[property]?.items
        ? [...prevEntity[property].items, item]
        : [item];

      return {
        ...prevEntity,
        [property]: {
          ...prevEntity[property],
          items: itemsArray,
        },
      };
    });
  };

  const removeItemOnClick = (event, property, index) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    removeItem(property, index);
  };
  const addHeroItemOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const defaultItem = {
      title: '',
      subtitle: '',
      textbutton: '',
      link: '',
      description: '',
      attachment: '',
    };

    addItem('hero', defaultItem);
  };
  const addBenefitItemOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const defaultItem = {
      title: '',
      icon: '',
      description: '',
    };

    addItem('benefits', defaultItem);
  };
  const addGalleryItemOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const defaultItem = {
      order: '',
      attachment: '',
    };

    addItem('gallery', defaultItem);
  };
  const addTeamItemOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const defaultItem = {
      order: '',
      name: '',
      job: '',
      attachment: '',
    };

    addItem('team', defaultItem);
  };

  const onFileImageLoaded = async (event, index, property) => {
    if (!event || !event.formData) {
      return;
    }
    setIsLoadingFiles((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });

    try {
      const fileResponse = await uploadFile(
        event.formData,
        '/api/upload/file',
        LandPageService,
      );

      if (!fileResponse) {
        openAlert(
          '¡Ocurrio un problema al cargar la imagen!',
          '',
          'error',
          true,
        );
        return;
      }

      setEntity((prevEntity) => {
        const updatedItems = [...prevEntity[property].items];
        if (updatedItems[index]) {
          updatedItems[index] = {
            ...updatedItems[index],
            attachment: fileResponse,
          };
        }
        return {
          ...prevEntity,
          [property]: {
            ...prevEntity[property],
            items: updatedItems,
          },
        };
      });
    } catch (error) {
    } finally {
      setIsLoadingFiles((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const onFileDeleted = (event, index, property) => {
    if (!event || !event.formData) {
      return;
    }

    setEntity((prevEntity) => {
      const updatedItems = [...prevEntity[property].items];
      if (updatedItems[index]) {
        updatedItems[index] = {
          ...updatedItems[index],
          attachment: { url: '' },
        };
      }
      return {
        ...prevEntity,
        [property]: {
          ...prevEntity[property],
          items: updatedItems,
        },
      };
    });
  };

  const confirmOnClick = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setIsLoading(true);

    try {
      const updateData = {
        ...entity,
        body: JSON.stringify(entity),
      };

      const entityResult = await updateEntity(updateData, LandPageService);

      if (!entityResult) {
        openAlert(
          '¡Ocurrio un problema al actualizar el landpage!',
          '',
          'error',
          true,
        );
        return;
      }
      openAlert(
        'Landpage modificada!',
        'Confirmamos que fue modificada la landpage, puedes ver los cambios',
        'success',
        true,
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const returnOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    navigate('/admin/');
  };

  const onContentChanged = (event) => {
    setEntity((prevData) => {
      const updatedBenefitsItems = [...prevData.benefits.items];

      if (updatedBenefitsItems[event.index]) {
        updatedBenefitsItems[event.index].description = event.model;
        updatedBenefitsItems[event.index].descriptionTemp = event.model;
        updatedBenefitsItems[event.index].contentTempText = event.modelText;
      }

      return {
        ...prevData,
        benefits: {
          ...prevData.benefits,
          items: updatedBenefitsItems,
        },
      };
    });
  };

  const handleUpdateItems = (e, index, _entity, property) => {
    setEntity((prevEntity) => ({
      ...prevEntity,
      [_entity]: {
        ...prevEntity[_entity],
        items: prevEntity[_entity].items.map((item, idx) => {
          if (index !== idx) return item;
          return { ...item, [property]: e.target.value };
        }),
      },
    }));
  };
  const handleUpdateData = (e, _entity, property) => {
    setEntity((prevEntity) => ({
      ...prevEntity,
      [_entity]: {
        ...prevEntity[_entity],
        [property]: e.target.value,
      },
    }));
  };

  return (
    <>
      {isAlertActive !== null && isAlertActive && (
        <Alert
          config={{
            title: alertMessage.title,
            description: alertMessage.message,
            typeIcon: alertMessage.icon,
            showCancelButton: false,
          }}
          setConfirm={setIsConfirm}
        />
      )}
      <div className="col-12 col-lg-10 mx-auto d-block mb-3 mt-2">
        <div className="container pt-2">
          <h4 className="card-title text-center">Editar landpage</h4>
        </div>
      </div>

      {/* Hero banner */}
      <div className="col-12  mx-auto d-block mb-3">
        <h4 className="card-title text-center">Hero Banner</h4>

        {/* Items */}
        <section className="d-flex flex-column">
          {entity.hero.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <div className="d-flex justify-content-between">
                <h5>Slide # {index + 1}</h5>
                <button
                  className="btn btn-outline-danger btn-rounded d-flex justify-content-center align-items-center"
                  onClick={(e) => removeItemOnClick(e, 'hero', index)}
                >
                  <i className="fe-trash fs-4"></i>
                </button>
              </div>

              <article>
                <div className="form-row row">
                  <div className="form-group col-12 col-md-6">
                    <label className="form-control-label">Título</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.title || ''}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'hero', 'title')
                      }
                      id={`title-input- + ${index}`}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label className="form-control-label">Subtítulo</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.subtitle || ''}
                      id={`hero-subtitle-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'hero', 'subtitle')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-12 mt-3">
                    <label className="form-control-label">
                      Link vídeo Youtube
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.video_link || ''}
                      id={`hero-videobutton-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'hero', 'video_link')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 mt-3">
                    <label className="form-control-label">Texto Botón</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.textbutton || ''}
                      id={`hero-textbutton-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'hero', 'textbutton')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 mt-3">
                    <label className="form-control-label">Enlace</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.link || ''}
                      id={`hero-link-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'hero', 'link')
                      }
                    />
                  </div>
                  <div className="form-group col-12 mt-3">
                    <label
                      className="form-control-label"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="1366x768 pixeles, JPG o PNG, Max 2MB"
                    >
                      Imagen principal <i data-feather="help-circle"></i>
                    </label>
                    {isLoadingFiles[index] ? (
                      <SpinnerLoader />
                    ) : (
                      <div className="d-flex mt-3">
                        <Upload
                          id={`"hero-image-input-" +${index}`}
                          imgpath={_entity.attachment.url}
                          enablepreview={true}
                          enablecopy={true}
                          enabledelete={true}
                          accept={'image/x-png,image/gif,image/jpeg'}
                          onLoaded={(e) => onFileImageLoaded(e, index, 'hero')}
                          onDeleted={(e) => onFileDeleted(e, index, 'hero')}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-group col-12 mt-3">
                    <label className="form-control-label">Descripción</label>
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      value={_entity.description || ''}
                      id={`hero-description-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'hero', 'description')
                      }
                    />
                  </div>
                </div>
              </article>
            </div>
          ))}

          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={(e) => addHeroItemOnClick(e)}
            >
              Agregar slide
            </button>
          </div>
        </section>
      </div>

      {/* Benefits */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Beneficios</h4>

        {/* Principal info */}
        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="benefits-title-input"
                value={entity.benefits.title || ''}
                onChange={(e) => {
                  handleUpdateData(e, 'benefits', 'title');
                }}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="benefits-subtitle-input"
                value={entity.benefits.subtitle || ''}
                onChange={(e) => {
                  handleUpdateData(e, 'benefits', 'subtitle');
                }}
              />
            </div>
          </div>
        </section>
        {/* Items */}
        <section className="d-flex flex-column mt-3">
          {entity.benefits.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <div className="d-flex justify-content-between">
                <h5>Beneficio # {index + 1} </h5>
                <button
                  className="btn btn-outline-danger btn-rounded d-flex justify-content-center align-items-center"
                  onClick={(e) => removeItemOnClick(e, 'benefits', index)}
                >
                  <i className="fe-trash fs-4"></i>
                </button>
              </div>

              <article>
                <div className="form-row row mt-3">
                  <div className="form-group col-12 col-md-10">
                    <label className="form-control-label">Título</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.title || ''}
                      id={`benefits-title-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'benefits', 'title')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-2">
                    <label className="form-control-label">ícono</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.icon || ''}
                      id={`benefits-icon-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'benefits', 'icon')
                      }
                    />
                  </div>

                  <div className="form-group col-12 col-md-12">
                    <label className="font-weight-light bg-primary p-3  text-center text-white fs-4 mt-3  w-100">
                      Descripción
                    </label>

                    <div className="my-custom-editor-container">
                      <TextEditor
                        id={uuidv4()}
                        index={index}
                        modelraw={_entity.description || ''}
                        onModelChange={(e) => onContentChanged(e)}
                      />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          ))}

          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={(e) => addBenefitItemOnClick(e)}
            >
              Agregar beneficio
            </button>
          </div>
        </section>
      </div>

      {/* About */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Acerca de</h4>

        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                value={entity.about.title || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="about-title-input"
                onChange={(e) => {
                  handleUpdateData(e, 'about', 'title');
                }}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.about.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="about-subtitle-input"
                onChange={(e) => {
                  handleUpdateData(e, 'about', 'subtitle');
                }}
              />
            </div>
            <div className="form-group col-12 mt-3">
              <label className="form-control-label">Descripción</label>
              <textarea
                value={entity.about.description || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="about-description-input"
                onChange={(e) => {
                  handleUpdateData(e, 'about', 'description');
                }}
              />
            </div>
          </div>
        </section>
      </div>

      {/* Gallery */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Galería</h4>

        {/* Principal info */}
        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="gallery-title-input"
                value={entity.gallery.title || ''}
                onChange={(e) => {
                  handleUpdateData(e, 'gallery', 'title');
                }}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="gallery-subtitle-input"
                value={entity.gallery.subtitle || ''}
                onChange={(e) => {
                  handleUpdateData(e, 'gallery', 'subtitle');
                }}
              />
            </div>
          </div>
        </section>
        {/* Items */}
        <section className="d-flex flex-column mt-3">
          {entity.gallery.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <div className="d-flex justify-content-between">
                <h5>Foto # {index + 1}</h5>
                <button
                  className="btn btn-outline-danger btn-rounded d-flex justify-content-center align-items-center"
                  onClick={(e) => removeItemOnClick(e, 'gallery', index)}
                >
                  <i className="fe-trash fs-4"></i>
                </button>
              </div>
              <article>
                <div className="form-row row mt-3 d-flex align-items-center">
                  <div className="form-group col-12 col-md-2 ">
                    <label className="form-control-label">Orden</label>
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control mt-3"
                      value={_entity.order || ''}
                      id={`gallery-order-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'gallery', 'order')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-10 ">
                    <label
                      className="form-control-label mt-3 mt-md-0"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="1366x768 pixeles, JPG o PNG, Max 2MB"
                    >
                      Imagen <i data-feather="help-circle"></i>
                    </label>
                    {isLoadingFiles[index] ? (
                      <SpinnerLoader />
                    ) : (
                      <div className="d-flex mt-3">
                        <Upload
                          id={`gallery-image-input- +${index}`}
                          imgpath={_entity.attachment.url}
                          enablepreview={true}
                          enablecopy={true}
                          enabledelete={true}
                          accept={'image/x-png,image/gif,image/jpeg'}
                          onLoaded={(e) =>
                            onFileImageLoaded(e, index, 'gallery')
                          }
                          onDeleted={(e) => onFileDeleted(e, index, 'gallery')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </article>
            </div>
          ))}

          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={(e) => addGalleryItemOnClick(e)}
            >
              Agregar foto
            </button>
          </div>
        </section>
      </div>

      {/* Team members */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Equipo</h4>

        {/* Principal info */}
        <section>
          <div className="form-row row">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                v-model="entity.team.title"
                value={entity.team.title || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="team-title-input"
                onChange={(e) => {
                  handleUpdateData(e, 'team', 'title');
                }}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.team.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="team-subtitle-input"
                onChange={(e) => {
                  handleUpdateData(e, 'team', 'subtitle');
                }}
              />
            </div>
          </div>
        </section>
        {/* Items */}
        <section className="d-flex flex-column mt-3">
          {entity.team.items.map((_entity, index) => (
            <div className="border p-3 my-2" key={index}>
              <div className="d-flex justify-content-between">
                <h5>Docente # {index + 1}</h5>
                <button
                  className="btn btn-outline-danger btn-rounded d-flex justify-content-center align-items-center"
                  onClick={(e) => removeItemOnClick(e, 'team', index)}
                >
                  <i className="fe-trash fs-4"></i>
                </button>
              </div>
              <article>
                <div className="form-row row mt-3">
                  <div className="form-group col-12 col-md-2">
                    <label className="form-control-label">Orden</label>
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control"
                      id={`team-order-input- + ${index}`}
                      value={_entity.order || ''}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'team', 'order')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-5">
                    <label className="form-control-label">Nombre</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.name || ''}
                      id={`team-name-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'team', 'name')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-5">
                    <label className="form-control-label">Cargo</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={_entity.job || ''}
                      id={`team-job-input- + ${index}`}
                      onChange={(e) =>
                        handleUpdateItems(e, index, 'team', 'job')
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-md-12 mt-3">
                    <label
                      className="form-control-label"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="1366x768 pixeles, JPG o PNG, Max 2MB"
                    >
                      Imagen <i data-feather="help-circle"></i>
                    </label>
                    {isLoadingFiles[index] ? (
                      <SpinnerLoader />
                    ) : (
                      <div className="d-flex mt-3">
                        <Upload
                          id={`"team-image-input-" +${index}`}
                          imgpath={_entity.attachment.url}
                          enablepreview={true}
                          enablecopy={true}
                          enabledelete={true}
                          accept={'image/x-png,image/gif,image/jpeg'}
                          onLoaded={(e) => onFileImageLoaded(e, index, 'team')}
                          onDeleted={(e) => onFileDeleted(e, index, 'team')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </article>
            </div>
          ))}

          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={(e) => addTeamItemOnClick(e)}
            >
              Agregar foto
            </button>
          </div>
        </section>
      </div>

      {/* Testimonials */}
      <div className="col-12  mx-auto d-block mb-3">
        <h4 className="card-title text-center">Testimonios</h4>

        <section>
          <div className="form-row row mt-3">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                value={entity.testimonials.title || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="testimonials-title-input"
                onChange={(e) => {
                  handleUpdateData(e, 'testimonials', 'title');
                }}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.testimonials.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="testimonials-subtitle-input"
                onChange={(e) => {
                  handleUpdateData(e, 'testimonials', 'subtitle');
                }}
              />
            </div>
          </div>
        </section>
      </div>

      {/* Courses */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Calendario de cursos</h4>

        <section>
          <div className="form-row row mt-3">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="courses-title-input"
                value={entity.courses.title || ''}
                onChange={(e) => {
                  handleUpdateData(e, 'courses', 'title');
                }}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.courses.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="courses-subtitle-input"
                onChange={(e) => {
                  handleUpdateData(e, 'courses', 'subtitle');
                }}
              />
            </div>
          </div>
        </section>
      </div>

      {/* Form */}
      <div className="col-12 mx-auto d-block mb-3">
        <h4 className="card-title text-center">Formulario de contacto</h4>

        <section>
          <div className="form-row row mt-3">
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Título</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                id="form-title-input"
                value={entity.form.title || ''}
                onChange={(e) => {
                  handleUpdateData(e, 'form', 'title');
                }}
              />
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="form-control-label">Subtítulo</label>
              <input
                value={entity.form.subtitle || ''}
                type="text"
                autoComplete="off"
                className="form-control"
                id="form-subtitle-input"
                onChange={(e) => {
                  handleUpdateData(e, 'form', 'subtitle');
                }}
              />
            </div>
          </div>
        </section>
      </div>

      {/* Buttons */}
      <div className="col-12  mx-auto d-block mb-3">
        <div className="card">
          <div className="card-body">
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <div className="container pt-2 d-flex justify-content-center">
                <button
                  title="Submit"
                  type="submit"
                  className="btn btn-primary btn-action me-2"
                  onClick={(e) => confirmOnClick(e)}
                >
                  <i className="mdi mdi-check"></i> Guardar
                </button>
                <button
                  title="Cancel"
                  type="button"
                  className="btn btn-light btn-action ms-2"
                  onClick={(e) => returnOnClick(e)}
                >
                  <i className="mdi mdi-close"></i> Cancelar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LandPageEdit;
