import React from 'react';

const AdminDashboard = () => {
  const sectionItems = [
    {
      href: '/admin/user/course/management/',
      icon: 'fe-credit-card',
      title: 'Cursos pagados',
    },
    {
      href: '/admin/user/exam/management/',
      icon: 'fe-file-text',
      title: 'Asignación examenes',
    },
    {
      href: '/admin/interested/management/',
      icon: 'fe-database',
      title: 'Interesados',
    },
    {
      href: '/admin/course/management/',
      icon: 'fe-book-open',
      title: 'Cursos',
    },
    {
      href: '/admin/course/module/management/',
      icon: 'fe-book-open',
      title: 'Módulos',
    },
    {
      href: '/admin/course/resource/management/',
      icon: 'fe-book-open',
      title: 'Recursos',
    },
    { href: '/admin/exam/management/', icon: 'fe-edit-1', title: 'Exámenes' },
    {
      href: '/admin/certification/management/',
      icon: 'fe-award',
      title: 'Certificaciones',
    },
    {
      href: '/admin/feedback/management/',
      icon: 'fe-message-circle',
      title: 'Comentarios',
    },
    { href: '/admin/user/management/', icon: 'fe-users', title: 'Usuarios' },
    { href: '/admin/landpage/management/', icon: 'fe-monitor', title: 'Landpage' },
  ];

  return (
    <section className="d-flex flex-wrap my-0 ms-0 ms-lg-3 ms-xl-3 px-1">
      <div className="row layout-top-spacing">
        <div className="col-12 my-4">
          <h2 className="fw-bold text-center fs-1">Administración</h2>
        </div>

        {sectionItems.map((item) => (
          <article className="col-12 col-lg-4 my-2" key={item.href}>
            <a
              href={item.href}
              className="text-decoration-none"
            >
              <div className="widget card h-100 border-0 shadow-sm">
                <div className="mt-4 text-center fs-1 text-black">
                  <i className={`${item.icon}`}></i>
                </div>
                <div className="card-body">
                  <div className="container pt-2">
                    <h3 className="card-title text-center">{item.title}</h3>
                  </div>
                </div>
              </div>
            </a>
          </article>
        ))}
      </div>
    </section>
  );
};

export default AdminDashboard;
