import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import {
  AccountGettingStarted,
  AccountOverview,
  AccountProfile,
  AuthLogin,
  AuthLogout,
  AuthBridge,
  AuthLockscreen,
  AdminDashboard,
  BusinessCreate,
  BusinessList,
  BusinessUpdate,
  CourseManagement,
  CourseDetailView,
  CourseModuleManagement,
  CourseModuleDetailView,
  CourseResourceManagement,
  CourseResourceDetailView,
  DashboardAdmin,
  DashboardBusiness,
  FeedbackManagement,
  FeedbackDetailView,
  MaintenanceGeneral,
  InterestedManagement,
  InterestedDetailView,
  UserCreate,
  UserList,
  UserExamManagement,
  UserExamDetailsView,
  UserUpdate,
  UserManagement,
  UserDetailView,
  UserCourseManagementPage,
  UserCourseEditPage,
  ExamManagement,
  ExamDetailView,
  CertificationManagement,
  CertificationDetailView,
  LandPageManagement,
} from './pages/index';

import {
  LayoutAdmin,
  LayoutAuth,
  LayoutMaintenance,
  LayoutBusiness,
} from './layouts/index';
import {
  MyCertification,
  MyPublicCertification,
} from './components/pages/index';
import { AuthProvider } from './hooks/useAuth.hook';
import { SnackbarProvider } from '@components/utilities/SnackbarProvider';

function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<LayoutAuth />}>
              <Route index element={<AuthBridge />} />
            </Route>
            <Route path="/auth" element={<LayoutAuth />}>
              <Route index element={<MaintenanceGeneral />} />
              <Route path="login" element={<AuthLogin />} />
              <Route path="logout" element={<AuthLogout />} />
              <Route path="lock-screen" element={<AuthLockscreen />} />
            </Route>
            <Route path="/admin" element={<LayoutAdmin />}>
              <Route index element={<AdminDashboard />} />
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="account">
                <Route index path="overview" element={<AccountOverview />} />
                <Route element={<AccountGettingStarted />} />
                <Route path="profile" element={<AccountProfile />} />
              </Route>
              <Route path="course">
                <Route path="management" element={<CourseManagement />} />
                <Route path="detail/:courseId" element={<CourseDetailView />} />
                <Route path="module">
                  <Route
                    path="management"
                    element={<CourseModuleManagement />}
                  />
                  <Route
                    path="detail/:courseModuleId"
                    element={<CourseModuleDetailView />}
                  />
                </Route>
                <Route path="resource">
                <Route
                  path="management"
                  element={<CourseResourceManagement />}
                />
                <Route
                  path="detail/:courseResourceId"
                  element={<CourseResourceDetailView />}
                />
              </Route>
              </Route>
              <Route path="interested">
                <Route path="management" element={<InterestedManagement/>} />
                <Route
                  path="detail/:interestedId"
                  element={<InterestedDetailView />}
                />
              </Route>
              <Route path="business">
                <Route index element={<BusinessList />} />
                <Route path="create" element={<BusinessCreate />} />
                <Route path="update" element={<BusinessUpdate />} />
              </Route>
              <Route path="user">
                <Route path="management" element={<UserManagement />} />
                <Route path="detail/:id" element={<UserDetailView />} />
              </Route>
              <Route path="user/course">
                <Route
                  path="management"
                  element={<UserCourseManagementPage />}
                />
                <Route path="detail/:id" element={<UserCourseEditPage />} />
              </Route>
              <Route path="user/exam">
                <Route path="management" element={<UserExamManagement />} />
                <Route path="detail/:id" element={<UserExamDetailsView />} />
              </Route>
              <Route path="feedback">
                <Route path="management" element={<FeedbackManagement />} />
                <Route path="management/:id" element={<FeedbackDetailView />} />
              </Route>
              <Route path="exam">
                <Route path="management" element={<ExamManagement />} />
                <Route path="detail/:id" element={<ExamDetailView />} />
              </Route>
              <Route path="certification">
                <Route
                  path="management"
                  element={<CertificationManagement />}
                />
                <Route
                  path="detail/:id"
                  element={<CertificationDetailView />}
                />
                <Route path="my-certifications" element={<MyCertification />} />
                <Route
                  path="my-public-certifications"
                  element={<MyPublicCertification />}
                />
              </Route>
              <Route path="landpage">
                <Route path="management" element={<LandPageManagement />} />
              </Route>
              <Route path="*" element={<MaintenanceGeneral />} />
            </Route>
            <Route path="/business" element={<LayoutBusiness />}>
              <Route index element={<DashboardBusiness />} />
              <Route path="account">
                <Route index path="overview" element={<AccountOverview />} />
                <Route
                  path="getting-started"
                  element={<AccountGettingStarted />}
                />
                <Route path="profile" element={<AccountProfile />} />
              </Route>
              <Route path="user">
                <Route index element={<UserList />} />
                <Route path="create" element={<UserCreate />} />
                <Route path="update" element={<UserUpdate />} />
              </Route>
            </Route>
            <Route path="*" element={<LayoutMaintenance />}>
              <Route path="*" element={<MaintenanceGeneral />} />
            </Route>
          </Routes>
        </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
