import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {ExamList} from '@components/pages/index';

function ExamManagement() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Examenes');
  }, []);

  return (
    <div>
      <ExamList />
    </div>
  );
}

export default ExamManagement;
