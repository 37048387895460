import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import Upload from '@components/shared/uploader/Uploader';
import { CertificationService } from '@services';
import Alert from '@components/shared/alert/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }
  return entityResponse.result;
}

async function uploadFile(payload, path, folder, entity) {
  const entityService = new entity();
  payload.append('folder', folder);
  const entityResponse = await entityService.post(payload, path);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }
  return entityResponse.result;
}

function CertificationEdit({ setIsEditMode }) {
  const { id } = useParams();

  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    entity: {
      is_active: false,
      attachments: [],
    },
  });

  const [isLoadingFiles, setIsLoadingFiles] = useState(
    Array(model.entity.attachments.length).fill(false),
  );

  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);

  const [isAlertActive, setIsAlertActive] = useState(null);
  const [isconfirm, setIsConfirm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (isconfirm === false) {
      setIsAlertActive(null);
      setIsConfirm(null);
      setIsEditMode(false);
    }
  }, [isconfirm, isAlertActive]);

  useEffect(() => {
    const fetchData = async () => {
      const [responseCourse, responseUser, entityResponseCertification] =
        await Promise.all([
          getEntity(
            { queryselector: 'course', search: '' },
            CertificationService,
          ),
          getEntity(
            { queryselector: 'user', search: '' },
            CertificationService,
          ),
          getEntity(
            {
              queryselector: 'certification',
              id: id,
            },
            CertificationService,
          ),
        ]);

      if (!responseCourse) {
        openAlert('Ocurrio un error al cargar los cursos!', '', 'error', true);
        return;
      }

      if (!responseUser) {
        openAlert(
          'Ocurrio un error al cargar los usuarios!',
          '',
          'error',
          true,
        );
        return;
      }

      const UserAdapter = responseUser.result.map((_entity) => {
        return {
          title: `${_entity.firstname} ${_entity.lastname} - ${_entity.email}`,
          value: _entity.id,
        };
      });

      const courseAdapter = responseCourse.result.map((_entity) => {
        return {
          title: _entity.name,
          value: _entity.id,
        };
      });

      setUserOptions(UserAdapter);
      setCourseOptions(courseAdapter);

      setCourses(responseCourse.result);
      setUsers(responseUser.result);

      setModel((prevData) => ({
        ...prevData,
        entity: entityResponseCertification.result,
      }));
    };

    fetchData().catch(console.error);
  }, [id]);

  const openAlert = (title, message, icon, isSuccess) => {
    setAlertMessage((prevData) => ({
      ...prevData,
      title,
      message,
      icon,
    }));
    setIsAlertActive(isSuccess);
  };

  const onFileLoaded = async (event, index) => {
    if (!event || !event.formData) {
      return;
    }
    setIsLoadingFiles((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });

    try {
      const entityResponse = await uploadFile(
        event.formData,
        '/api/upload/file',
        'certification',
        CertificationService,
      );
      const today = new Date();

      if (!entityResponse) {
        openAlert(
          'Ocurrio un error al cargar el certificado!',
          '',
          'error',
          true,
        );
        return;
      }

      setModel((prevModel) => {
        const updatedEntity = { ...prevModel.entity };
        const attachmentFiles = updatedEntity.attachments || [];

        attachmentFiles[index] = {
          uri: entityResponse.url || '',
          filename: entityResponse.filename || '',
          path: entityResponse.path || '',
          attachmentType: event.metadata.attachmentType,
          dateCreated: `${today.getFullYear()}/${
            today.getMonth() + 1
          }/${today.getDate()}`,
        };

        updatedEntity.attachments = attachmentFiles;

        return {
          ...prevModel,
          entity: updatedEntity,
        };
      });
    } catch (error) {
      openAlert('Ocurrio un error inesperado!', '', 'error', true);
    } finally {
      setIsLoadingFiles((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const addSecondaryFileOnClick = (event) => {
    event.preventDefault();

    const updatedEntity = { ...model.entity };
    updatedEntity.attachments.push({ uri: '' });

    setModel((prevModel) => ({
      ...prevModel,
      entity: updatedEntity,
    }));
  };

  const confirmOnClick = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setIsLoading(true);

    const user = users.find((_entity) => _entity.id === model.entity.user_id);
    const course = courses.find(
      (_entity) => _entity.id === model.entity.course_id,
    );

    try {
      const updateData = {
        ...model.entity,
        id: id,
        user: user,
        course: course,
      };
      const entityResponse = await updateEntity(
        updateData,
        CertificationService,
      );

      if (!entityResponse) {
        openAlert(
          'Ocurrio un error al actualizar los certificados!',
          '',
          'error',
          true,
        );
        return;
      }
      openAlert(
        'Certificados creados!',
        'Se ha actualizado el registro del certificado exitosamente',
        'success',
        true,
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const returnOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setIsEditMode(false);
  };

  return (
    <>
      {isAlertActive !== null && isAlertActive && (
        <Alert
          config={{
            title: alertMessage.title,
            description: alertMessage.message,
            typeIcon: alertMessage.icon,
            showCancelButton: false,
          }}
          setConfirm={setIsConfirm}
        />
      )}
      <div className="col-12 mx-auto d-block mt-2">
        <div className=" pt-2">
          <h4 className="card-title">Editar Certificado</h4>

          <form>
            <div className="form-row row mt-3">
              <div className="form-group col-12 col-md-6">
                <label className="form-control-label">Curso</label>
                <Autocomplete
                  options={courseOptions}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, newValue) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      entity: {
                        ...prevModel.entity,
                        course_id: newValue?.value,
                      },
                    }))
                  }
                  value={
                    courseOptions.find(
                      (option) => option.value === model.entity?.course_id,
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <div className="form-group col-12 col-md-6">
                <label className="form-control-label">Usuario</label>
                <Autocomplete
                  options={userOptions}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, newValue) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      entity: {
                        ...prevModel.entity,
                        user_id: newValue?.value,
                      },
                    }))
                  }
                  value={
                    userOptions.find(
                      (option) => option.value === model.entity?.user_id,
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12 mt-3">
                <label className="form-control-label mb-2">
                  Documentos adjuntos
                </label>
                <table className="table table-bordered mb-0">
                  <tbody>
                    {model.entity.attachments?.map((_entity, i) => (
                      <tr key={i}>
                        {isLoadingFiles[i] ? (
                          <td>
                            {' '}
                            <SpinnerLoader />
                          </td>
                        ) : (
                          <td className="py-4">
                            <Upload
                              id={`"attachment-input-" +${i}`}
                              imgpath={_entity.uri}
                              enablecopy={true}
                              accept={'application/pdf'}
                              onLoaded={(e) => onFileLoaded(e, i, _entity)}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="btn btn-outline-primary mt-4"
                  onClick={(e) => addSecondaryFileOnClick(e)}
                >
                  <i data-feather="plus" className="text-primary mr-2"></i>
                  Agregar documento
                </button>
              </div>
              <div className="form-group col-12 col-md-6 mt-3">
                <label className="form-control-label">¿Está habilitado?</label>
                <div className="form-group col-12 col-md-3 mt-3">
                  <div className="form-check form-switch d-flex mt-1">
                    <input
                      onChange={(e) =>
                        setModel((prevModel) => ({
                          ...prevModel,
                          entity: {
                            ...prevModel.entity,
                            is_active: !prevModel.entity.is_active,
                          },
                        }))
                      }
                      value={model.entity.is_active}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={model.entity.is_active}
                    />
                    <label className="form-check-label ms-1">
                      {model.entity.is_active ? 'Si' : 'No'}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <>
                {' '}
                <button
                  title="Submit"
                  type="submit"
                  className="btn btn-primary btn-action me-2 mt-3"
                  onClick={(e) => confirmOnClick(e)}
                >
                  <i data-feather="check"></i> Guardar certificado
                </button>
                <button
                  title="Cancel"
                  type="button"
                  className="btn btn-light btn-action ms-2 mt-3"
                  onClick={(e) => returnOnClick(e)}
                >
                  <i data-feather="x"></i> Cancelar
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default CertificationEdit;
