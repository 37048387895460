import BaseApi from '../base/apiBase.service';

export default class CourseService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      get: '/api/course',
      create: '/api/course/',
      update: '/api/course/',
      getMyCourses: '/api/course/my-courses',
    };
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async get(data) {
    return super.get(data, this.serviceEndpoints.get);
  }

  async create(data) {
    return super.create(data);
  }

  async update(data) {
    return super.update(data);
  }

  async getMyCourses(data) {
    return super.get(data, this.serviceEndpoints.getMyCourses);
  }

  async uploadFile(data) {
    data.append('folder', 'course');
    return super.post(data, '/api/upload/file');
  }
}
