import { useState, useEffect } from 'react';
import { formatHelpers } from '@components/utilities/';
import { CertificationService } from '@services/index';
import { CertificationDelete } from '@components/pages';
import { useParams, Link } from 'react-router-dom';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';
import { PopUp } from '@components/shared/popup/PopUp';
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from '@mui/material/';

const UserCertifications = () => {
  const { id: userId } = useParams();
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [myCertifications, setMyCertifications] = useState([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [menuActionsAnchorElement, setMenuActionsAnchorElement] =
    useState(null);
  const [menuActionsSelected, setActionsSelected] = useState(null);

  useEffect(() => {
    const fetchCertificationsByQuery = async (query, Entity) => {
      try {
        const entityService = new Entity();
        const entityResponse = await entityService.getMyCertifications(query);

        if (!entityResponse || !entityResponse.success) {
          openSnackbar(
            'Ocurrio un el error al obtener las certificaciones',
            'error',
          );
          return;
        }

        setMyCertifications(entityResponse.result);
      } catch (error) {
        console.log(error);
        openSnackbar('Ha ocurrido un error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCertificationsByQuery({ user_id: userId }, CertificationService);
  }, [userId]);

  const handleDeleteEntity = (entity) => {
    setMyCertifications((prevState) => {
      const newEntities = prevState.filter(
        (_entity) => _entity.id !== entity.id,
      );

      return newEntities;
    });
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'delete':
        setIsOpenDeleteModal(false);
        handleDeleteEntity(entity);
        openSnackbar('Eliminación exitosa!', 'success');
        break;
      default:
        break;
    }
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'delete':
        setIsOpenDeleteModal(true);
        setSelectedEntity(entity);
        break;
      default:
        break;
    }
  };

  const closeMenuActions = () => {
    setActionsSelected(null);
    setMenuActionsAnchorElement(null);
  };

  const actionsMenuOnClick = (selector, anchorElement) => {
    setActionsSelected(selector || null);
    setMenuActionsAnchorElement(anchorElement || null);
  };

  const MenuDots = (row) => {
    const menuItemOnClick = (event, action) => {
      event?.stopPropagation(); // Prevent select this row after clicking

      itemOnAction(action, row);
    };

    return (
      <>
        <IconButton
          data-testid="user-action-menu-btn"
          aria-label="actions button"
          id={`basic-menu-${row.id}`}
          aria-haspopup="true"
          onClick={(event) =>
            actionsMenuOnClick(`basic-menu-${row.id}`, event.currentTarget)
          }
        >
          <i className="mdi mdi-dots-vertical fs-4"></i>
        </IconButton>
        <Menu
          elevation={1}
          id={'basic-menu-' + row.id}
          anchorEl={menuActionsAnchorElement}
          open={menuActionsSelected === `basic-menu-${row.id}`}
          onClose={closeMenuActions}
          MenuListProps={{
            'aria-labelledby': 'user-action-menu-btn',
          }}
        >
          <MenuItem
            component={Link}
            to={`/admin/certification/detail/${row.id}`}
            target="_blank"
          >
            <i className="fe-search me-1"></i>
            <ListItemText>Ver/Editar</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              menuItemOnClick(event, 'delete');
              closeMenuActions();
            }}
          >
            <i className="fe-trash me-1"></i>
            <ListItemText>Eliminar</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <div className="col-12 col-md-10 mx-auto d-block">
      <div className="mb-4">
        <h4 className="mt-4 text-center">Certificados</h4>

        <div className="row">
          <div className="table-responsive mt-3">
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Título emitido</TableCell>
                      <TableCell>Fecha emisión</TableCell>
                      <TableCell>Modificado</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myCertifications?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.title_issued}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date_issue}
                        </TableCell>
                        <TableCell>
                          <span className="d-none">
                            {formatHelpers.dateWithoutSeparators(
                              row.last_modification,
                            )}
                          </span>
                          {formatHelpers.dateWithSeparators(
                            row.last_modification,
                          )}
                        </TableCell>
                        <TableCell>
                          <span className="badge bg-info fs-6 p-1">
                            {row.status.title}
                          </span>
                        </TableCell>
                        <TableCell>{MenuDots(row)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
      <PopUp
        title="Eliminar"
        data-testid="delete-certification-modal"
        id="delete-certification-modal"
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        styles={{ width: '30%' }}
      >
        <CertificationDelete
          setIsOpen={setIsOpenDeleteModal}
          onUpdatedEntity={(entity) => onUpdatedEntity('delete', entity)}
          id={selectedEntity.id}
        />
      </PopUp>
    </div>
  );
};

export default UserCertifications;
