import React, { useState, useEffect } from 'react';
import { UserService } from '@services/index';
import { useParams } from 'react-router-dom';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import {
  UserEnrolledCourses,
  UserCertifications,
  UserApprovedExams,
} from '@components/pages';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const UserDetail = () => {
  const { id: userId } = useParams();
  const { openSnackbar } = useSnackbar();
  const [entity, setEntity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!userId) {
      openSnackbar(
        'Por favor regresa y selecciona de nuevo el elemento',
        'error',
      );
      return;
    }

    const fetchUserById = async (query) => {
      try {
        const entityService = new UserService();
        const entityResponse = await entityService.get(query);

        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Ocurrio un error al obtener el usuario', 'error');
          return;
        }

        setEntity(entityResponse.result);
      } catch (error) {
        console.log(error);
        openSnackbar('Ha ocurrido un error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserById({ identity: userId });
  }, []);

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <div className="col-12 col-md-10 mx-auto d-block">
          <div className="mb-4">
            <h2 className="border-bottom mb-4 mt-4 pb-3 text-center">
              {entity?.firstname} {entity?.lastname}
            </h2>
            <div className="row">
              <div className="col-12 col-md-4">
                <p className="text-muted mb-0">Email</p>
                <p className="mb-2">{entity?.email}</p>
              </div>
              <div className="col-12 col-md-4">
                <p className="text-muted mb-0">Documento</p>
                <p className="mb-2">{entity?.dni}</p>
              </div>
              <div className="col-12 col-md-4">
                <p className="text-muted mb-0">Teléfono</p>
                <p className="mb-2">{entity?.phone}</p>
              </div>
              <div className="col-12 col-md-4">
                <p className="text-muted mb-0">Ocupación</p>
                <p className="mb-2">{entity?.occupation}</p>
              </div>
              <div className="col-12 col-md-4">
                <p className="text-muted mb-0">Ciudad</p>
                <p className="mb-2">{entity?.city}</p>
              </div>
              <div className="col-12 col-md-4">
                <p className="text-muted mb-0">Rol</p>
                <p className="mb-2">{entity?.role?.title}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-4 card-body">
        <UserEnrolledCourses />
      </div>

      <div className="mt-4 card-body">
        <UserCertifications />
      </div>

      <div className="mt-4 card-body">
        <UserApprovedExams />
      </div>
    </>
  );
};

export default UserDetail;
