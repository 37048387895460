import React, { useEffect, useState } from 'react';
import {
  CourseResourceService,
  CourseService,
  CourseModuleService,
} from '@services';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import Upload from '@components/shared/uploader/Uploader';
import TextEditor from '@components/shared/texteditor/TextEditor';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

function CourseResourceCreateComponent({ onUpdatedEntity, setIsOpen }) {
  const { openSnackbar } = useSnackbar();
  const [entity, setEntity] = useState({
    name: '',
    order: '',
    icon: '',
    type_name: '',
    course: null,
    module: null,
    is_active: false,
    description: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [editorKey, setEditorKey] = useState(uuidv4());
  const [archive, setArchive] = useState([]);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);

  const loadEntities = async () => {
    try {
      setIsLoading(true);
      const courseEntityService = new CourseService();
      const moduleEntityService = new CourseModuleService();

      const [courseResponse, moduleResponse] = await Promise.all([
        courseEntityService.get({ all: true }),
        moduleEntityService.get({ all: true }),
      ]);

      if (
        !courseResponse ||
        !courseResponse.success ||
        !moduleResponse ||
        !moduleResponse.success
      ) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return null;
      }

      setCourses(courseResponse.result);
      setModules(moduleResponse.result);
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEntities();
  }, []);

  const confirmOnClick = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      const newData = {
        ...entity,
        course: entity.course ? entity.course : {},
        course_id: entity.course ? entity.course.id : '',
        module: entity.module ? entity.module : {},
        module_id: entity.module ? entity.module.id : '',
        attachments: archive.length ? archive : [],
      };

      setEditorKey(uuidv4());

      const entityService = new CourseResourceService();
      const entityResponse = await entityService.create(newData);

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }

      const entityResourceResponse = await entityService.get({
        id: entityResponse.result.id,
      });
      onUpdatedEntity('create', entityResourceResponse.result);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
      setIsLoading(false);
    }
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    if (name === 'course') {
      const selectedCourse = courses.find((course) => course.id === value);
      setEntity((prevState) => ({ ...prevState, course: selectedCourse }));
    } else if (name === 'module') {
      const selectedModule = modules.find((module) => module.id === value);
      setEntity((prevState) => ({ ...prevState, module: selectedModule }));
    }
  };

  const onFileLoaded = async (event) => {
    setIsLoadingImage(true);
    if (!event || !event.formData) {
      return;
    }

    const entityService = new CourseResourceService();
    const entityResponse = await entityService.uploadFile(event.formData);
    const today = new Date();

    if (!entityResponse || !entityResponse.success) {
      return;
    }

    setArchive([
      {
        uri: entityResponse.result.url || '',
        filename: entityResponse.result.filename || '',
        path: entityResponse.result.path || '',
        attachmentType: event.metadata.attachmentType,
        dateCreated: `${today.getFullYear()}/${
          today.getMonth() + 1
        }/${today.getDate()}`,
        isPrincipal: true,
      },
    ]);
    setIsLoadingImage(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEntity((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleCheckChange = (check) => {
    setEntity((prevState) => ({
      ...prevState,
      [check]: !prevState[check],
    }));
  };

  const onFileDeleted = async (event) => {
    if (!event || !event.formData) {
      return;
    }

    const fileSplit = event.formData.get('filename').split('/');
    const filename = fileSplit[fileSplit.length - 1];
    event.formData.set('filename', filename);

    setArchive([]);
  };

  const handleTextEditChange = (data) => {
    setEntity((prevState) => ({ ...prevState, description: data.model }));
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <form onSubmit={(e) => confirmOnClick(e)}>
              <div className="row">
                <div className="form-group col-12 mb-3">
                  <label className="form-label">Nombre del Recurso*</label>
                  <input
                    type="text"
                    required
                    autoComplete="off"
                    className="form-control"
                    id="name"
                    placeholder=""
                    value={entity.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-md-4 mb-3">
                  <label className="form-label" htmlFor="order">
                    Orden*
                  </label>
                  <input
                    value={entity.order}
                    onChange={handleChange}
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    id="order"
                    placeholder=""
                  />
                </div>

                <div className="form-group col-md-4 mb-3">
                  <label className="form-label">Ícono*</label>
                  <input
                    required
                    value={entity.icon}
                    onChange={handleChange}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="icon"
                    placeholder=""
                  />
                </div>

                <div className="form-group col-md-4 mb-3">
                  <label className="form-label">Tipo*</label>
                  <input
                    required
                    value={entity.type_name}
                    onChange={handleChange}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="type_name"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">Asociar a curso</label>
                  <select
                    className="form-select"
                    name="course"
                    value={entity.course?.id || 'default'}
                    onChange={handleSelectChange}
                  >
                    <option value="default" disabled>
                      Seleccione un curso
                    </option>
                    {courses.map((_entity, key) => (
                      <option key={key} value={_entity.id}>
                        {_entity.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">Asociar a módulo</label>
                  <select
                    className="form-select"
                    name="module"
                    value={entity.module?.id || 'default'}
                    onChange={handleSelectChange}
                  >
                    <option value="default" disabled>
                      Seleccione un módulo
                    </option>
                    {modules.map((_entity, key) => (
                      <option key={key} value={_entity.id}>
                        {_entity.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 mb-3">
                  <label htmlFor="image" className="form-label">
                    Archivo
                  </label>
                  {isLoadingImage ? (
                    <Loader />
                  ) : (
                    <Upload
                      id="image-input"
                      imgpath={archive.length ? archive[0].uri : ''}
                      enablecopy={true}
                      enabledelete={true}
                      onLoaded={onFileLoaded}
                      onDeleted={onFileDeleted}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-3">
                  <label
                    htmlFor="published-status-input"
                    className="form-label"
                  >
                    ¿Está activo?
                  </label>
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="published-status-input"
                      checked={entity.is_active}
                      onChange={() => handleCheckChange('is_active')}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="published-status-input"
                    >
                      {entity.is_active ? 'Si' : 'No'}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row my-3">
                <div className="form-group col-12 col-md-12">
                  <div className="bg-primary py-2">
                    <h4 className="fw-light my-0 text-center text-white w-100">
                      Descripción
                    </h4>
                  </div>
                  <div>
                    <TextEditor
                      modelraw={entity.description}
                      onModelChange={handleTextEditChange}
                      id={editorKey}
                      index={editorKey}
                    />
                  </div>
                </div>
              </div>

              <button
                title="submit"
                type="submit"
                className="btn btn-primary me-3"
              >
                <i className="feather-icon" data-feather="check"></i>
                Crear recurso
              </button>
              <button
                title="Cancel"
                type="button"
                className="btn btn-light"
                onClick={() => returnOnClick()}
              >
                <i className="feather-icon" data-feather="x"></i> Cancelar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseResourceCreateComponent;
