import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UserCourseList } from '@components/pages';

function UserCourseManagementPage() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Cursos pagados');
  }, []);

  return (
    <section className="container-fluid">
      <article className="row card">
        <UserCourseList />
      </article>
    </section>
  );
}

export default UserCourseManagementPage;
