import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FeedbackList } from '@components/pages';

function FeedbackManagement() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Recomendaciones');
  }, []);

  return (
    <section className="container-fluid">
      <article className="row card">
        <FeedbackList />
      </article>
    </section>
  );
}

export default FeedbackManagement;
