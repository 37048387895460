import React, { useState, useEffect } from 'react';
import Alert from '@components/shared/alert/Alert';
import { CertificationService } from '@services';
import { formatHelpers } from '@components/utilities';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';
import styled from 'styled-components';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

const StyledImg = styled.img`
width: 100%; 
height: 160px; 
object-fit: cover;
`;

function MyPublicCertification() {
  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    entities: [],
    entity: {
      status: {},
      user: {},
      course: {},
    },
    dni: '',
    view: 'lobby',
  });

  const [isConfirmClick, setIsConfirmClick] = useState(false);

  const [isAlertActive, setIsAlertActive] = useState(null);
  const [isconfirm, setIsConfirm] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isconfirm === false) {
      setIsAlertActive(null);
      setIsConfirm(null);
    }
  }, [isconfirm, isAlertActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const entityResponse = await getEntity(
          {
            queryselector: '/certification',
            dni: model.dni,
          },
          CertificationService,
        );
        if (!entityResponse) {
          openAlert('Certificado no encontrado.', '', 'error', true);
          return;
        }

        setModel((prevData) => ({
          ...prevData,
          entities: entityResponse.result,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (isConfirmClick) {
      fetchData();
    }
  }, [isConfirmClick]);

  const openAlert = (title, message, icon, isSuccess) => {
    setAlertMessage((prevData) => ({
      ...prevData,
      title,
      message,
      icon,
    }));
    setIsAlertActive(isSuccess);
  };

  const confirmOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (!model.dni) {
      openAlert('Ingrese el documento de identidad.', '', 'warning', true);
      return;
    }

    setIsConfirmClick(true);

    setModel((prevData) => ({
      ...prevData,

      view: 'my-certifications',
    }));
  };

  const certificationOnClick = async (event, entity) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setModel((prevData) => ({
      ...prevData,
      entity: entity,
      view: 'certification-detail',
    }));
  };
  const getPrincipalImage = (_entity) => {
    const defaultItem = {
      uri: `${process.env.REACT_APP_BACKEND_URL}/img/404.svg`,
      name:
        !_entity || !_entity.name
          ? 'Entidad no disponible'
          : `Adjunto inexistente de "${_entity.name}"`,
      attachmentType: 'image/svg+xml',
    };

    if (!_entity || !_entity.attachments || !_entity.attachments.length) {
      return defaultItem;
    }

    const element = [..._entity.attachments].find(
      (el) => el.attachmentType.includes('image') && el.isPrincipal === true,
    );

    if (!element || !element.uri) {
      return defaultItem;
    }

    return element;
  };

  const getBody = (body) => {
    if (!body) {
      return '';
    }
    var text = formatHelpers.extractTextFromHtml(window.decodeURI(body));
    return text.length > 85 ? text.slice(0, 85) + '...' : text;
  };

  return (
    <>
      {isAlertActive !== null && isAlertActive && (
        <Alert
          config={{
            title: alertMessage.title,
            description: alertMessage.message,
            typeIcon: alertMessage.icon,
            showCancelButton: false,
          }}
          setConfirm={setIsConfirm}
        />
      )}
      <section className="our-services section-padding30">
        {model.view === 'lobby' && (
          <div className="container">
            <div className="row justify-content-sm-center">
              <div className="cl-xl-7 col-lg-8 col-md-10">
                <div className="section-tittle text-center mb-3">
                  <h2>Certificados EMCODESA</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <article className="col-lg-12 my-4">
                <div className="card shadow">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <h5 className="text-center font-weight-bold mb-3 fs-3">
                      Ingresa tu número de documento
                    </h5>
                    <p className="text-center fs-4">Sin espacios, ni puntos</p>

                    <div className="d-flex justify-content-center my-5 w-50 mx-auto">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Documento de identidad"
                        value={model.dni}
                        onChange={(e) =>
                          setModel((prevData) => ({
                            ...prevData,
                            dni: e.target.value,
                          }))
                        }
                      />
                      <button
                        className="btn btn-primary mx-2"
                        onClick={(e) => confirmOnClick(e)}
                      >
                        Buscar
                      </button>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        )}
      </section>

      {model.view === 'my-certifications' && (
        <div className="col-12 d-block mx-auto section-padding30">
          <ul
            className="border-0 nav nav-tabs custom-tabs"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="mycertifications-tab"
                data-toggle="tab"
                href="#mycertifications"
                role="tab"
                aria-controls="mycertifications"
                aria-selected="false"
              >
                Mis certificados
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            {/* My certifications */}
            <div
              className="tab-pane fade show active"
              id="mycertifications"
              role="tabpanel"
              aria-labelledby="mycertifications-tab"
            >
              {isLoading ? (
                <SpinnerLoader />
              ) : (
                <div className="d-flex row">
                  {(!model.entities || !model.entities.length) && (
                    <div className="col-12 my-3">
                      <div className="card h-100 py-5 shadow">
                        <h2 className="text-center py-5">
                          No hay certificados
                        </h2>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="d-flex row">
                {model.entities && model.entities.length > 0 && (
                  <div className="d-flex row">
                    {Array.isArray(model.entities) &&
                      model.entities.map((_entity, index) => (
                        <div className="col-12 col-md-4 my-3" key={index}>
                          <div className="card shadow h-100">
                            <StyledImg
                              src={
                                getPrincipalImage(_entity.course).uri ||
                                getPrincipalImage(_entity.course).filename
                              }
                              alt=""
                            />
                            <div className="card-body">
                              <h5 className="card-title">{`${_entity.course.name}`}</h5>
                              <p className="card-text">
                                {getBody(_entity.course.description)}
                              </p>
                              <button
                                className="btn btn-primary"
                                onClick={(e) =>
                                  certificationOnClick(e, _entity)
                                }
                              >
                                Ver más
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="col-12 col-md-10 section-padding30 mx-auto d-block">
        {model.view === 'certification-detail' && (
          <div className="col-12 col-md-10 grid-margin-md-0 stretch-card mx-auto d-block">
            <div className="card">
              <div className="card-body">
                <div className="mb-4">
                  <h4 className="text-center fs-3">Constancia que</h4>
                  <h2 className="text-center fs-1">
                    {model.entity.user?.firstname} {model.entity.user?.lastname}
                  </h2>
                  <h6 className="text-center fs-5 mt-3">
                    Ha finalizado el curso "{model.entity.course.name}" en
                    la fecha{' '}
                    {new Date(+model.entity.date_creation).getDate() +
                      '/' +
                      (new Date(+model.entity.date_creation).getMonth() + 1) +
                      '/' +
                      new Date(+model.entity.date_creation).getFullYear()}
                  </h6>
                </div>

                {/* Files */}
                <section className="d-flex flex-column justify-content-center">
                  {model.entity.attachments
                    ? model.entity.attachments.map((_entity, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-center"
                        >
                          <a
                            className="btn btn-primary my-3"
                            target="_blank"
                            rel="noreferrer"
                            href={_entity.uri}
                          >
                            Descargar adjunto
                          </a>
                        </div>
                      ))
                    : null}
                </section>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MyPublicCertification;
