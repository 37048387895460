import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import React from 'react';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

export const PopUp = ({ children, title, id, isOpen, setIsOpen, styles }) => {
  const combinedStyles = {
    ...modalStyle,
    ...styles,
  };
  const handleClose = () => setIsOpen(false);
  return (
    <Modal
      id={id}
      data-testid={id}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={combinedStyles}>
        <IconButton
          sx={{ position: 'absolute', top: 10, right: 10 }}
          onClick={handleClose}
        >
          <i className="mdi mdi-close"></i>
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Box sx={{ maxHeight: '70vh', overflow: 'auto', marginTop:'1.5rem'}}>{children}</Box>
      </Box>
    </Modal>
  );
};