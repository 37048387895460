import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserService } from '@services/index';
import Alert from '@components/shared/alert/Alert';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';

async function updateEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const UserEdit = (props) => {
  const { setIsEditMode } = props;
  const { id: userId } = useParams();
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [entity, setEntity] = useState({
    role: { id: 0 },
  });
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    if (isConfirm === true) {
      alertInfo.type === 'confirm cancel' && setIsEditMode(false);
    } else if (isConfirm === false) {
      alertInfo.type === 'success' && setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    try {
      const fetchRoles = async () => {
        const entityService = new UserService();
        const entityResponse = await entityService.get({
          roles: true,
        });

        if (!entityResponse || !entityResponse.success) {
          setAlertInfo({
            message: 'Ha ocurrido un error. Por favor intenta mas tarde',
            type: 'error',
          });
          return;
        }

        const result = entityResponse.result;
        setRoles(Object.values(result));
      };

      fetchRoles();
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const fetchUserById = async (query) => {
      try {
        const entityService = new UserService();
        const entityResponse = await entityService.get(query);

        if (!entityResponse || !entityResponse.success) {
          setAlertInfo({
            message: 'Ha ocurrido un error. Por favor intenta mas tarde',
            type: 'error',
          });
          return;
        }

        delete entityResponse.result.password;
        setEntity(entityResponse.result);
      } catch (error) {
        setAlertInfo({
          message: 'Ha ocurrido un error. Por favor intenta mas tarde',
          type: 'error',
        });
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserById({ identity: userId });
  }, [userId]);

  const confirmOnClick = async (event) => {
    event.preventDefault();
    try {
      setIsLoadingUpdate(true);
      const response = await updateEntity(
        { ...entity, identity: userId },
        UserService,
      );

      if (!response || !response.success) {
        setAlertInfo({
          message: 'Ha ocurrido un error. Por favor intenta mas tarde',
          type: 'error',
        });
      }

      setAlertInfo({
        message:
          'Tus cambios han sido guardados. ¿Quieres seguir editando o prefieres salir?',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const returnOnClick = () => {
    setAlertInfo({
      message:
        '¿Estás seguro de salir de la vista de edición? Se perderán los cambios realizados.',
      type: 'confirm cancel',
    });
  };

  const selectRoleOnClick = (event) => {
    const { value } = event.target;

    const role = roles.find((rle) => rle.id === +value);
    setEntity((prevEntity) => ({ ...prevEntity, role }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEntity((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Cambios Guardados Exitosamente',
          description: alertInfo.message,
          typeIcon: 'success',
          confirmButtonText: 'Seguir Editando',
          cancelButtonText: 'Salir',
          showCancelButton: true,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };

      default:
        return null;
    }
  };

  return isLoading ? (
    <SpinnerLoader />
  ) : (
    <div className="mx-auto card-body">
      <form>
        {alertInfo.type && (
          <Alert
            config={getAlertConfig(alertInfo.type)}
            setConfirm={setIsConfirm}
          />
        )}
        <div className="row mb-3 gap-3 gap-md-0">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Email</label>
            <input
              type="email"
              autoComplete="off"
              className="form-control"
              id="email"
              name="email"
              placeholder="email@gmail.com"
              value={entity.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Constraseña:</label>
            <input
              type="password"
              autoComplete="off"
              className="form-control"
              id="password"
              name="password"
              placeholder=""
              value={entity.password}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row mb-3 gap-3 gap-md-0">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Nombres:</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="firstname"
              placeholder=""
              value={entity.firstname}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Apellidos:</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="lastname"
              placeholder=""
              value={entity.lastname}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row mb-3 gap-3 gap-md-0">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">
              Identificación (Identificación nacional):
            </label>
            <input
              type="number"
              className="form-control"
              autoComplete="off"
              name="identity"
              id="dni-input"
              placeholder="Solo números"
              value={entity.identity}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Celular</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="phone"
              placeholder=""
              value={entity.phone}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row mb-3 gap-3 gap-md-0">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Profesión:</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="occupation"
              placeholder=""
              value={entity.occupation}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Rol</label>
            <select
              className="form-control"
              value={entity.role.id}
              onChange={selectRoleOnClick}
            >
              <option value="0" disabled>
                Selecciona un rol...
              </option>
              {roles.map(({ id, title }) => (
                <option value={id} key={id}>
                  {title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-12">
            <label className="form-label">Ciudad:</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="city"
              placeholder=""
              value={entity.city}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mt-4">
          {isLoadingUpdate ? (
            <button className="btn btn-primary" disabled type="button">
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Guardando...
            </button>
          ) : (
            <>
              <button
                title="Submit"
                type="submit"
                className="btn btn-primary btn-action mr-2"
                onClick={confirmOnClick}
              >
                <i data-feather="check"></i> Guardar
              </button>
              <button
                title="Cancel"
                type="button"
                className="btn btn-light btn-action ml-2"
                onClick={returnOnClick}
              >
                <i data-feather="x"></i> Cancelar
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserEdit;
