import React, { useState, useRef } from 'react';
import { CourseService } from '@services';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import Upload from '@components/shared/uploader/Uploader';
import Tags from '@components/shared/tags/label-tag/LabelTags';
import TextEditor from '@components/shared/texteditor/TextEditor';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const CourseCreateComponent = ({ onUpdatedEntity, setIsOpen }) => {
  const { openSnackbar } = useSnackbar();

  const [principalImage, setPrincipalImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [formFields, setFormFields] = useState({
    name: '',
    duration: '',
    tags: '',
    price: '',
    teacher_name: '',
    payment_link: '',
    course_date: '',
    category: '',
    is_active: false,
    is_landing: false,
    description: '',
  });
  const editorId = useRef(`text-editor-${uuidv4()}`);

  const confirmOnClick = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      const newData = {
        ...formFields,
        attachments: principalImage.length ? principalImage : [],
      };

      const entityService = new CourseService();
      const entityResponse = await entityService.create(newData);

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return null;
      }

      const entityCourseResponse = await entityService.get({
        id: entityResponse.result.id,
      });
      onUpdatedEntity('create', entityCourseResponse.result);
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    }
  };

  const onFileLoaded = async (event) => {
    setIsLoadingImage(true);

    if (!event || !event.formData) {
      return;
    }

    const entityService = new CourseService();
    const entityResponse = await entityService.uploadFile(event.formData);
    const today = new Date();

    if (!entityResponse || !entityResponse.success) {
      return;
    }

    setPrincipalImage([
      {
        uri: entityResponse.result.url || '',
        filename: entityResponse.result.filename || '',
        path: entityResponse.result.path || '',
        attachmentType: event.metadata.attachmentType,
        dateCreated: `${today.getFullYear()}/${
          today.getMonth() + 1
        }/${today.getDate()}`,
        isPrincipal: true,
      },
    ]);

    setIsLoadingImage(false);
  };

  const onFileDeleted = async (event) => {
    if (!event || !event.formData) {
      return;
    }

    const fileSplit = event.formData.get('filename').split('/');
    const filename = fileSplit[fileSplit.length - 1];
    event.formData.set('filename', filename);

    setPrincipalImage([]);
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormFields((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleTagChange = (newTagsString) => {
    try {
      if (newTagsString) {
        const newTagsArray = JSON.parse(newTagsString);
        const tagsString = newTagsArray.map((tag) => tag.value).join(',');

        setFormFields((prevState) => ({ ...prevState, tags: tagsString }));
      } else {
        setFormFields((prevState) => ({ ...prevState, tags: '' }));
      }
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    }
  };

  const handleCheckChange = (check) => {
    setFormFields((prevState) => ({
      ...prevState,
      [check]: !prevState[check],
    }));
  };

  const handleTextEditChange = (data) => {
    setFormFields((prevState) => ({ ...prevState, description: data.model }));
  };

  const handleCancelButton = () => {
    setIsOpen(false);
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <form onSubmit={(e) => confirmOnClick(e)}>
            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre del curso
                </label>
                <input
                  type="text"
                  required
                  autoComplete="off"
                  className="form-control"
                  id="name"
                  placeholder=""
                  value={formFields.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="duration" className="form-label">
                  Duración estimada (horas)
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    id="duration"
                    placeholder=""
                    value={formFields.duration}
                    onChange={handleChange}
                  />
                  <span className="input-group-text">horas</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="tags" className="form-label">
                  Etiquetas
                </label>
                <div>
                  <Tags value={formFields.tags} onChange={handleTagChange} />
                </div>
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="price" className="form-label">
                  Precio
                </label>
                <div className="input-group is-invalid">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validatedInputGroupPrepend"
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="tel"
                    autoComplete="off"
                    className="form-control"
                    id="price"
                    placeholder=""
                    value={formFields.price}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="teacher_name" className="form-label">
                  Nombre del profesor
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="teacher_name"
                  placeholder=""
                  value={formFields.teacher_name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="category" className="form-label">
                  Categoría
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="category"
                  placeholder=""
                  value={formFields.category}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="image" className="form-label">
                  Imagen principal
                </label>
                {isLoadingImage ? (
                  <Loader />
                ) : (
                  <Upload
                    id="image-input"
                    imgpath={principalImage.length ? principalImage[0].uri : ''}
                    enablepreview={true}
                    enablecopy={true}
                    enabledelete={true}
                    accept="image/x-png,image/gif,image/jpeg"
                    onLoaded={onFileLoaded}
                    onDeleted={onFileDeleted}
                  />
                )}
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="published-status-input" className="form-label">
                  ¿Está activo?
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="published-status-input"
                    checked={formFields.is_active}
                    onChange={() => handleCheckChange('is_active')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="published-status-input"
                  >
                    {formFields.is_active ? 'Si' : 'No'}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="payment_link" className="form-label">
                  Link de pago
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="payment_link"
                  placeholder=""
                  value={formFields.payment_link}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="course_date" className="form-label">
                  Fecha
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  className="form-control"
                  id="course_date"
                  placeholder=""
                  value={formFields.course_date}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row border-bottom">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="islanding-status-input" className="form-label">
                  ¿Está público en landing?
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="islanding-status-input"
                    checked={formFields.is_landing}
                    onChange={() => handleCheckChange('is_landing')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="islanding-status-input"
                  >
                    {formFields.is_landing ? 'Si' : 'No'}
                  </label>
                </div>
              </div>
            </div>

            <div className="row my-3">
              <div className="form-group col-12 col-md-12">
                <div className="bg-primary py-2">
                  <h4 className="fw-light my-0 text-center text-white w-100">
                    Descripción
                  </h4>
                </div>
                <div>
                  <TextEditor
                    modelraw={formFields.description}
                    onModelChange={handleTextEditChange}
                    id={editorId.current}
                    index={editorId.current}
                  />
                </div>
              </div>
            </div>

            <button
              title="submit"
              type="submit"
              className="btn btn-primary btn-action mr-3"
            >
              <i className="feather-icon" data-feather="check"></i> Crear curso
            </button>

            <button
              title="Cancel"
              type="button"
              className="btn btn-light btn-action mx-2"
              onClick={() => handleCancelButton()}
            >
              <i className="feather-icon" data-feather="x"></i> Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CourseCreateComponent;
