import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserExamService, UserService, ExamService } from '@services/index';
import SearchableSelect from './components/SearchableSelect';
import Alert from '@components/shared/alert/Alert';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';

async function updateEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const UserExamEdit = (props) => {
  const { setIsEditMode } = props;
  const { id: userExamId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });
  const [exams, setExams] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [examOptions, setExamOptions] = useState([]);
  const [entity, setEntity] = useState({
    status: { id: 0 },
  });

  const showErrorAlert = (message) => setAlertInfo({ message, type: 'error' });
  const showConfirmCancelAlert = (message) =>
    setAlertInfo({ message, type: 'confirm-cancel' });
  const showSuccessAlert = (message) =>
    setAlertInfo({ message, type: 'success' });

  const getSelectedEntity = async () => {
    const entityService = new UserExamService();
    const entityResponse = await entityService.get({
      id: userExamId,
    });

    if (!entityResponse || !entityResponse.success) {
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
      return;
    }

    setEntity(entityResponse.result);
  };

  const getAllUsers = async () => {
    try {
      const entityService = new UserService();
      const entityResponse = await entityService.get({
        all: true,
      });
      if (!entityResponse || !entityResponse.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }
      const entityAdapter = entityResponse.result.map((_entity) => {
        return {
          label: `${_entity.firstname} ${_entity.lastname} - ${_entity.email}`,
          value: _entity.id,
        };
      });

      setUserOptions(entityAdapter);
      setUsers(entityResponse.result);
    } catch (error) {
      console.log(error);
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
    }
  };

  const getAllStatuses = async () => {
    try {
      const entityService = new UserExamService();
      const entityResponse = await entityService.get({
        status: true,
      });

      if (!entityResponse || !entityResponse.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }

      const result = entityResponse.result;
      setStatuses(Object.values(result));
    } catch (error) {
      console.log(error);
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
    }
  };

  const getAllExams = async () => {
    try {
      const entityService = new ExamService();
      const entityResponse = await entityService.get({
        all: true,
      });
      if (!entityResponse || !entityResponse.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }

      const adapterForSelectOptions = entityResponse.result.map((_entity) => {
        return {
          label: _entity.name,
          value: _entity.id,
        };
      });

      setExamOptions(adapterForSelectOptions);
      setExams(entityResponse.result);
    } catch (error) {
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
      console.log(error);
    }
  };

  useEffect(() => {
    if (isConfirm === true) {
      alertInfo.type === 'confirm-cancel' && setIsEditMode(false);
    } else if (isConfirm === false) {
      alertInfo.type === 'success' && setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    Promise.all([
      getSelectedEntity(),
      getAllUsers(),
      getAllStatuses(),
      getAllExams(),
    ])
      .then((resp) => setIsLoading(false))
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const confirmOnClick = async (event) => {
    event.preventDefault();
    const user = users.find((_entity) => _entity.id === entity.user_id);
    const exam = exams.find((_entity) => _entity.id === entity.exam_id);

    try {
      setIsLoadingUpdate(true);
      const entityData = {
        ...entity,
        attempts: +entity.attempts,
        user,
        exam,
      };

      const response = await updateEntity(
        { ...entityData, id: userExamId },
        UserExamService,
      );

      if (!response || !response.success) {
        showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
        return;
      }

      showSuccessAlert(
        'Tus cambios han sido guardados. ¿Quieres seguir editando o prefieres salir?',
      );
    } catch (error) {
      console.error(error);
      showErrorAlert('Ha ocurrido un error. Por favor intenta más tarde');
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const returnOnClick = () => {
    showConfirmCancelAlert(
      '¿Estás seguro de salir de la vista de edición? Se perderán los cambios realizados.',
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'status') {
      const status = statuses.find((_status) => _status.id === +value);
      setEntity((prevEntity) => ({ ...prevEntity, [name]: status }));
      return;
    }

    setEntity((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Cambios Guardados Exitosamente',
          description: alertInfo.message,
          typeIcon: 'success',
          confirmButtonText: 'Seguir Editando',
          cancelButtonText: 'Salir',
          showCancelButton: true,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };

      default:
        return null;
    }
  };

  return isLoading ? (
    <SpinnerLoader />
  ) : (
    <div className="card-body mx-auto">
      <form>
        {alertInfo.type && (
          <Alert
            config={getAlertConfig(alertInfo.type)}
            setConfirm={setIsConfirm}
          />
        )}

        <div className="row mb-3">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Usuario</label>
            <SearchableSelect
              options={userOptions}
              onChange={(event) =>
                setEntity((prevState) => ({
                  ...prevState,
                  user_id: event.value,
                }))
              }
              defaultValue={entity.user?.id}
            />
          </div>

          <div className="form-group col-12 col-md-6">
            <label className="form-label">Examen</label>
            <SearchableSelect
              options={examOptions}
              onChange={(event) =>
                setEntity((prevState) => ({
                  ...prevState,
                  exam_id: event.value,
                }))
              }
              defaultValue={entity.exam?.id}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Estado</label>
            <select
              className="form-control status-list"
              value={entity.status.id}
              name="status"
              onChange={handleInputChange}
            >
              <option value="0" disabled>
                Selecciona un estado
              </option>
              {statuses.map((_entity) => (
                <option key={_entity.id} value={_entity.id}>
                  {_entity.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-12 col-md-6">
            <label class="form-label">Intentos hechos por el estudiante</label>
            <input
              type="number"
              value={entity.attempts}
              class="form-control"
              name="attempts"
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mt-4">
          {isLoadingUpdate ? (
            <button class="btn btn-primary" disabled type="button">
              <span
                class="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Guardando...
            </button>
          ) : (
            <>
              <button
                title="Submit"
                type="submit"
                className="btn btn-primary btn-action mr-2"
                onClick={confirmOnClick}
              >
                <i className="fe-check"></i> Guardar asignación de examen
              </button>
              <button
                title="Cancel"
                type="button"
                className="btn btn-light btn-action ml-2"
                onClick={returnOnClick}
              >
                <i className="fe-x"></i> Cancelar
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserExamEdit;
