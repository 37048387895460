import React, { useEffect, useState } from 'react';
import { useAuth } from '@hooks/useAuth.hook';
import { InterestedService, UserService, CourseService } from '@services';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

function InterestedCreateComponent({ onUpdatedEntity, setIsOpen }) {
  const { openSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [entity, setEntity] = useState({
    name: '',
    email: '',
    phone: '',
    occupation: '',
    course: null,
    is_private: false,
    score: 5,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const loadEntities = async () => {
    try {
      setIsLoading(true);
      const courseEntityService = new CourseService();
      const userEntityService = new UserService();
      const [courseResponse, userResponse] = await Promise.all([
        courseEntityService.get({ all: true }),
        userEntityService.get({ id: user.id }),
      ]);

      if (
        !courseResponse ||
        !courseResponse.success ||
        !userResponse ||
        !userResponse.success
      ) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return null;
      }

      setCourses(courseResponse.result);
      setEntity((prevState) => ({
        ...prevState,
        user: userResponse.result,
        user_id: userResponse.result.id,
      }));
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEntities();
  }, []);

  const confirmOnClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const newData = {
      ...entity,
      course: entity.course ? entity.course.name : '',
      course_id: entity.course ? entity.course.id : '',
    };

    try {
      const entityService = new InterestedService();
      const entityResponse = await entityService.create(newData);

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }

      const entityInterestedResponse = await entityService.get({
        id: entityResponse.result.id,
      });
      onUpdatedEntity('create', entityInterestedResponse.result);
      setIsOpen(false);
    } catch (error) {
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
      console.error(error);
      setIsLoading(false);
    }
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;
    if (selectedCourseId === 'placeholder') {
      setEntity((prevEntity) => ({ ...prevEntity, course: null }));
    } else {
      const selectedCourse = courses.find(
        (course) => course.id === selectedCourseId,
      );

      setEntity((prevEntity) => ({ ...prevEntity, course: selectedCourse }));
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEntity((prevState) => ({ ...prevState, [id]: value }));
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <form onSubmit={(e) => confirmOnClick(e)}>
            <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre completo
                </label>
                <input
                  type="text"
                  required
                  autoComplete="off"
                  className="form-control"
                  id="name"
                  placeholder=""
                  value={entity.name}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  required
                  id="email"
                  placeholder=""
                  value={entity.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6 mb-3">
                <label className="form-label" htmlFor="phone">
                  Celular
                </label>
                <input
                  value={entity.phone}
                  onChange={handleChange}
                  type="text"
                  autoComplete="off"
                  required
                  className="form-control"
                  id="phone"
                  placeholder=""
                />
              </div>

              <div className="form-group col-md-6 mb-3">
                <label htmlFor="occupation" className="form-label">
                  Profesión
                </label>
                <input
                  value={entity.occupation}
                  onChange={handleChange}
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="occupation"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 mb-3">
                <label className="form-label" htmlFor="course">
                  Curso
                </label>
                <select
                  className="form-select"
                  id="course"
                  value={entity.course ? entity.course.id : ''}
                  onChange={handleCourseChange}
                  required
                >
                  <option value="" disabled>
                    Selecciona un curso
                  </option>
                  {courses.map((_entity, index) => (
                    <option key={index} value={_entity.id}>
                      {_entity.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <button
              title="submit"
              type="submit"
              className="btn btn-primary mb-3 me-3"
            >
              <i className="feather-icon" data-feather="check"></i>
              Hacer interesado
            </button>
            <button
              title="Cancel"
              type="button"
              className="btn mb-3 btn-light"
              onClick={() => returnOnClick()}
            >
              <i className="feather-icon" data-feather="x"></i> Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default InterestedCreateComponent;
