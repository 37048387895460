import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CourseService } from '@services';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import Alert from '@components/shared/alert/Alert';
import Upload from '@components/shared/uploader/Uploader';
import Tags from '@components/shared/tags/label-tag/LabelTags';
import TextEditor from '@components/shared/texteditor/TextEditor';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

function CourseDetailEditComponent(props) {
  const { courseId } = useParams();
  const { openSnackbar } = useSnackbar();

  const [entity, setEntity] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [editorKey, setEditorKey] = useState(uuidv4());
  const [principalImage, setPrincipalImage] = useState([]);
  const [isConfirm, setIsConfirm] = useState(null);
  const [formFields, setFormFields] = useState({
    name: '',
    duration: '',
    tags: '',
    price: '',
    teacher_name: '',
    payment_link: '',
    course_date: '',
    category: '',
    is_active: false,
    is_landing: false,
    description: '',
  });
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  const entityService = new CourseService();

  const getEntityById = async () => {
    try {
      const entityResponse = await entityService.get({
        id: courseId,
      });

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        return null;
      }

      setEntity(entityResponse.result);
      loadFormFields(entityResponse.result);
      setPrincipalImage(getPrincipalImage(entityResponse.result));
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEntityById();
  }, [courseId]);

  useEffect(() => {
    if (isConfirm === true) {
      alertInfo.type === 'confirm-cancel' && props.setIsEditMode(false);
    } else if (isConfirm === false) {
      alertInfo.type === 'success' && props.setIsEditMode(false);
    }

    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const loadFormFields = (entityData) => {
    setFormFields((prevState) => ({
      ...prevState,
      name: entityData.name || '',
      duration: entityData.duration || '',
      tags: entityData.tags || '',
      price: entityData.price || '',
      teacher_name: entityData.teacher_name || '',
      payment_link: entityData.payment_link || '',
      course_date: entityData.course_date || '',
      category: entityData.category || '',
      description: entityData.description || '',
      is_active: entityData.is_active || '',
      is_landing: entityData.is_landing || '',
    }));
  };

  const confirmOnClick = async () => {
    try {
      setIsLoading(true);

      const updatedEntity = {
        ...entity,
        ...formFields,
        description: formFields.description,
        attachments: principalImage[0].uri
          ? principalImage
          : getPrincipalImage(),
      };

      setEditorKey(uuidv4());

      const entityResult = await entityService.update(updatedEntity);

      if (!entityResult || !entityResult.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        return;
      }

      setAlertInfo({
        message:
          'Tus cambios han sido guardados. ¿Quieres seguir editando o prefieres salir?',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const getPrincipalImage = (_entity) => {
    const defaultItem = [
      {
        uri: `${process.env.REACT_APP_BACKEND_URL}/img/404.svg`,
        name:
          !_entity || !_entity.name
            ? 'Entidad no disponible'
            : `Adjunto inexistente de "${_entity.name}"`,
        attachmentType: 'image/svg+xml',
      },
    ];

    if (
      !_entity ||
      !_entity.attachments.length ||
      !_entity.attachments[0].uri
    ) {
      return defaultItem;
    }

    return _entity.attachments;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormFields((prevState) => ({ ...prevState, [id]: value }));
  };

  const onFileLoaded = async (event) => {
    setIsLoadingImage(true);

    if (!event || !event.formData) {
      return;
    }

    const entityResponse = await entityService.uploadFile(event.formData);
    const today = new Date();

    if (!entityResponse || !entityResponse.success) {
      return;
    }

    setPrincipalImage([
      {
        uri: entityResponse.result.url || '',
        filename: entityResponse.result.filename || '',
        path: entityResponse.result.path || '',
        attachmentType: event.metadata.attachmentType,
        dateCreated: `${today.getFullYear()}/${
          today.getMonth() + 1
        }/${today.getDate()}`,
        isPrincipal: true,
      },
    ]);
    setIsLoadingImage(false);
  };

  const onFileDeleted = async (event) => {
    if (!event || !event.formData) {
      return;
    }

    const fileSplit = event.formData.get('filename').split('/');
    const filename = fileSplit[fileSplit.length - 1];
    event.formData.set('filename', filename);

    setPrincipalImage([{ uri: '', name: '' }]);
  };

  const handleTagChange = (newTagsString) => {
    try {
      if (newTagsString) {
        const newTagsArray = JSON.parse(newTagsString);
        const tagsString = newTagsArray.map((tag) => tag.value).join(',');

        setFormFields((prevState) => ({ ...prevState, tags: tagsString }));
      } else {
        setFormFields((prevState) => ({ ...prevState, tags: '' }));
      }
    } catch (error) {
      console.error('Error al parsear los tags:', error);
    }
  };

  const handleCancelButton = () => {
    setAlertInfo({
      message:
        '¿Estás seguro de que deseas salir de la vista de edición? Se perderán los cambios realizados.',
      type: 'confirm-cancel',
    });
  };

  const handleCheckChange = (check) => {
    setFormFields((prevState) => ({
      ...prevState,
      [check]: !prevState[check],
    }));
  };

  const handleTextEditChange = (data) => {
    setFormFields((prevState) => ({ ...prevState, description: data.model }));
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm-cancel':
        return {
          title: 'Confirmar salida',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Cambios Guardados Exitosamente',
          description: alertInfo.message,
          typeIcon: 'success',
          confirmButtonText: 'Seguir Editando',
          cancelButtonText: 'Salir',
          showCancelButton: true,
        };
      default:
        return null;
    }
  };

  return (
    <div>
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <form onSubmit={(e) => confirmOnClick(e)}>
            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre del curso
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="name"
                  placeholder=""
                  value={formFields.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="duration" className="form-label">
                  Duración estimada (horas)
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    id="duration"
                    placeholder=""
                    value={formFields.duration}
                    onChange={handleChange}
                  />
                  <span className="input-group-text">horas</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="tags" className="form-label">
                  Etiquetas
                </label>
                <div>
                  <Tags value={formFields.tags} onChange={handleTagChange} />
                </div>
              </div>

              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="price" className="form-label">
                  Precio
                </label>
                <div className="input-group is-invalid">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validatedInputGroupPrepend"
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="tel"
                    autoComplete="off"
                    className="form-control"
                    id="price"
                    placeholder=""
                    value={formFields.price}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="teacher_name" className="form-label">
                  Nombre del profesor
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="teacher_name"
                  placeholder=""
                  value={formFields.teacher_name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="category" className="form-label">
                  Categoría
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="category"
                  placeholder=""
                  value={formFields.category}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6">
                <label htmlFor="image" className="form-label">
                  Imagen principal
                </label>
                {isLoadingImage ? (
                  <Loader />
                ) : (
                  <Upload
                    id="image-input"
                    imgpath={principalImage.length ? principalImage[0].uri : ''}
                    enablepreview={true}
                    enablecopy={true}
                    enabledelete={true}
                    accept="image/x-png,image/gif,image/jpeg"
                    onLoaded={onFileLoaded}
                    onDeleted={onFileDeleted}
                  />
                )}
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="published-status-input" className="form-label">
                  ¿Está activo?
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="published-status-input"
                    checked={formFields.is_active}
                    onChange={() => handleCheckChange('is_active')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="published-status-input"
                  >
                    {formFields.is_active ? 'Si' : 'No'}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="payment_link" className="form-label">
                  Link de pago
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="payment_link"
                  placeholder=""
                  value={formFields.payment_link}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="course_date" className="form-label">
                  Fecha
                </label>
                <input
                  type="date"
                  autoComplete="off"
                  className="form-control"
                  id="course_date"
                  placeholder=""
                  value={formFields.course_date}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row border-bottom">
              <div className="form-group col-12 col-md-6 mb-3">
                <label htmlFor="islanding-status-input" className="form-label">
                  ¿Está público en landing?
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="islanding-status-input"
                    checked={formFields.is_landing}
                    onChange={() => handleCheckChange('is_landing')}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="islanding-status-input"
                  >
                    {formFields.is_landing ? 'Si' : 'No'}
                  </label>
                </div>
              </div>
            </div>

            <div className="row my-3">
              <div className="form-group col-12 col-md-12">
                <div className="bg-primary py-2">
                  <h4 className="fw-light my-0 text-center text-white w-100">
                    Descripción
                  </h4>
                </div>
                <div>
                  <TextEditor
                    modelraw={formFields.description}
                    onModelChange={handleTextEditChange}
                    id={editorKey}
                    index={editorKey}
                  />
                </div>
              </div>
            </div>

            <button
              title="submit"
              type="submit"
              className="btn btn-primary btn-action mr-3"
            >
              <i className="feather-icon" data-feather="check"></i> Guardar
            </button>

            <button
              title="Cancel"
              type="button"
              className="btn btn-light btn-action mx-2"
              onClick={() => handleCancelButton()}
            >
              <i className="feather-icon" data-feather="x"></i> Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default CourseDetailEditComponent;
