import React, { useEffect, useState } from 'react';
import { CourseService, CourseModuleService } from '@services';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const CourseModuleCreateComponent = ({ onUpdatedEntity, setIsOpen }) => {
  const { openSnackbar } = useSnackbar();

  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState({
    name: '',
    order: '',
    description: '',
    is_active: false,
    course: '',
  });

  const getEntityById = async () => {
    try {
      const entityService = new CourseService();
      const entityResponse = await entityService.get({
        all: true,
      });

      if (!entityResponse || !entityResponse.success) {
        return null;
      }

      setCourses(entityResponse.result);
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEntityById();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEntity((prevEntity) => ({ ...prevEntity, [id]: value }));
  };

  const handleCheckboxChange = (e) => {
    setEntity((prevEntity) => ({ ...prevEntity, is_active: e.target.checked }));
  };

  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;
    if (selectedCourseId === 'placeholder') {
      setEntity((prevEntity) => ({ ...prevEntity, course: null }));
    } else {
      const selectedCourse = courses.find(
        (course) => course.id === selectedCourseId,
      );

      setEntity((prevEntity) => ({ ...prevEntity, course: selectedCourse }));
    }
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  const confirmOnClick = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      const newData = {
        ...entity,
        course_id: entity.course.id,
      };

      const entityService = new CourseModuleService();
      const entityResponse = await entityService.create(newData);

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }

      const entityModuleResponse = await entityService.get({
        id: entityResponse.result.id,
      });
      onUpdatedEntity('create', entityModuleResponse.result);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <form onSubmit={(e) => confirmOnClick(e)}>
            <div className="row mb-3">
              <div className="col-12">
                <label className="form-label" htmlFor="name">
                  Nombre del módulo*
                </label>
                <input
                  value={entity.name}
                  onChange={handleInputChange}
                  required
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="name"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-2">
                <label className="form-label" htmlFor="order">
                  Orden*
                </label>
                <input
                  value={entity.order}
                  onChange={handleInputChange}
                  type="number"
                  autoComplete="off"
                  className="form-control"
                  id="order"
                  placeholder=""
                />
              </div>
              <div className="mb-3 col-md-5">
                <label className="form-label" htmlFor="description">
                  Descripción breve*
                </label>
                <input
                  value={entity.description}
                  onChange={handleInputChange}
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="description"
                  placeholder=""
                />
              </div>
              <div className="mb-3 col-md-5">
                <label className="form-label" htmlFor="course">
                  Asociar a curso
                </label>
                <select
                  className="form-select"
                  id="course"
                  value={entity.course ? entity.course.id : ''}
                  onChange={handleCourseChange}
                  required
                >
                  <option value="" disabled>
                    Selecciona un curso
                  </option>
                  {courses.map((_entity, index) => (
                    <option key={index} value={_entity.id}>
                      {_entity.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-md-6">
                <label className="form-label">¿Está activo?</label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="published-status-input"
                    checked={entity.is_active}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="published-status-input"
                  >
                    {entity.is_active ? 'Si' : 'No'}
                  </label>
                </div>
              </div>
            </div>
            <button
              title="submit"
              type="submit"
              className="btn btn-primary me-3"
            >
              <i className="feather-icon" data-feather="check"></i> Crear módulo
            </button>
            <button
              title="Cancel"
              type="button"
              className="btn btn-light"
              onClick={() => returnOnClick()}
            >
              <i className="feather-icon" data-feather="x"></i> Cancelar
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CourseModuleCreateComponent;
