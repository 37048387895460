import React, { useEffect, useState } from 'react';
import { UserExamService } from '@services/index';
import { UserExamCreate, UserExamDelete } from '@components/pages';
import { PopUp } from '@components/shared/popup/PopUp';
import UserExamDataTable from './components/UserExamDataTable';
import PageTablePlaceholder from '@components/shared/loaders/placeholder/PageTablePlaceholder';
import PageTitlePlaceholder from '@components/shared/loaders/placeholder/PageTitlePlaceholder';
import PageDescriptionPlaceholder from '@components/shared/loaders/placeholder/PageDescriptionPlaceholder';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const Loader = () => (
  <PageTablePlaceholder count={4}>
    <PageTitlePlaceholder count={1}>
      <PageDescriptionPlaceholder count={1} />
    </PageTitlePlaceholder>
  </PageTablePlaceholder>
);

function UserExamList() {
  const { openSnackbar } = useSnackbar();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});

  const getExportData = async () => {
    try {
      const entityService = new UserExamService();
      await entityService.exportAll({
        columns:
          'NOMBRES,APELLIDOS,CEDULA,CAPACITACIÓN,CORREO,PROFESIÓN,TELEFONO,FECHA%20EVALUACION,FECHA%20CURSO',
        select:
          'user.firstname,user.lastname,user.dni,exam.course.name,user.email,user.occupation,user.phone,last_modification,date_creation',
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityService = new UserExamService();
        const entityResponse = await entityService.get({
          all: true,
        });

        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Error al obtener la data', 'error');
          return;
        }

        setEntities(entityResponse.result);
      } catch (error) {
        openSnackbar('Ocurrio un error', 'error');
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteEntity = (entity) => {
    setEntities((prevState) => {
      const newEntities = prevState.filter(
        (_entity) => _entity.id !== entity.id,
      );

      return newEntities;
    });
  };

  const handleCreateEntity = (entity) => {
    setEntities((prevEntities) => [...prevEntities, ...entity]);
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateModal(false);
        handleCreateEntity(entity);
        openSnackbar('Asignación exitosa', 'success');
        break;
      case 'delete':
        setIsOpenDeleteModal(false);
        handleDeleteEntity(entity);
        openSnackbar('Eliminación exitosa', 'success');
        break;
      default:
        break;
    }
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateModal(true);
        break;
      case 'delete':
        setIsOpenDeleteModal(true);
        setSelectedEntity(entity);
        break;
      default:
        break;
    }
  };

  const handleExportAll = () => {
    getExportData();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {(!entities || !entities.length) && (
            <div className="col-12 col-xl-8 col-lg-10 mx-auto d-block">
              <div className="card-body">
                <div className="container pt-2 text-center">
                  <h3 className="text-center mb-4">
                    No existen datos, crea un usuario.
                  </h3>

                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/private/img/empty-content.svg`}
                    alt="empty content"
                    className="d-block mx-auto w-50 img-fluid"
                  />

                  <button
                    className="btn btn-primary text-wrap"
                    onClick={() => itemOnAction('create')}
                  >
                    Click aquí para crear un usuario
                  </button>
                </div>
              </div>
            </div>
          )}

          {entities && entities.length > 0 && (
            <div className="card-body pt-2">
              <article className="mb-3 d-flex">
                <h4 className="card-title mt-3 me-auto">
                  Lista de examenes asignados
                </h4>
                <button
                  className="btn btn-primary my-2 me-3"
                  onClick={handleExportAll}
                  href="/api/user-exam/export?columns=NOMBRES,APELLIDOS,CEDULA,CAPACITACIÓN,CORREO,PROFESIÓN,TELEFONO,FECHA%20EVALUACION,FECHA%20CURSO&select=user.firstname,user.lastname,user.dni,exam.course.name,user.email,user.occupation,user.phone,last_modification,date_creation"
                >
                  Descargar todo
                </button>
                <button
                  type="button"
                  className="btn btn-primary my-2 me-3"
                  onClick={() => itemOnAction('create')}
                >
                  Crear nuevo
                </button>
              </article>
              <div className="table-responsive mt-3">
                <UserExamDataTable
                  data={entities}
                  itemOnAction={itemOnAction}
                />
              </div>
            </div>
          )}
        </>
      )}
      <PopUp
        title="Crear una nueva asgnación"
        data-testid="create-feedback-modal"
        id="create-feedback-modal"
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        styles={{ width: '50%' }}
      >
        <>
          <UserExamCreate
            setIsOpen={setIsOpenCreateModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('create', entity)}
          />
        </>
      </PopUp>
      <PopUp
        data-testid="delete-feedback-modal"
        id="delete-feedback-modal"
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        styles={{ width: 700 }}
      >
        <>
          <UserExamDelete
            setIsOpen={setIsOpenDeleteModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('delete', entity)}
            selectedEntity={selectedEntity}
          />
        </>
      </PopUp>
    </>
  );
}

export default UserExamList;
