import React, { useState, useEffect } from 'react';
import { FeedbackService } from '@services/index';
import { useParams } from 'react-router-dom';
import Alert from '@components/shared/alert/Alert';
import SpinnerLoader from '@components/shared/loaders/spinner/SpinnerLoader';

const FeedbackDetails = () => {
  const { id: feedbackId } = useParams();
  const [entity, setEntity] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirm, setIsConfirm] = useState(null);
  const [entityList, setEntityList] = useState([]);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  useEffect(() => {
    if (!feedbackId) {
      setAlertInfo({
        message: 'Por favor regresa y selecciona el elemento de nuevo',
        type: 'error',
      });
      return;
    }

    const fetchFeedbackByQuery = async (query) => {
      try {
        const entityService = new FeedbackService();
        const entityResponse = await entityService.get(query);

        if (!entityResponse || !entityResponse.success) {
          setAlertInfo({
            message: 'Ha ocurrido un error. Por favor intenta mas tarde',
            type: 'error',
          });
          return;
        }

        const entityAdapter = Object.keys(entityResponse.result).map(
          (_entity) => {
            let value = entityResponse.result[_entity];
            if (typeof value === 'object') {
              value = JSON.stringify(value);
            }

            return {
              key: _entity,
              value,
            };
          },
        );

        setEntityList(entityAdapter);
        setEntity(entityResponse.result);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedbackByQuery({ id: feedbackId });
  }, []);

  const getAlertConfig = (value) => {
    switch (value) {
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          {alertInfo.type && (
            <Alert
              config={getAlertConfig(alertInfo.type)}
              setConfirm={setIsConfirm}
            />
          )}
          <div className=" card-body col-12 mx-auto d-block">
            <div className="mb-4">
              <h2 className="text-center">
                {entity.user.firstname} {entity.user.lastname}
              </h2>
            </div>
            <div className="border-top pt-3">
              <div className="overflow-auto row table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entityList?.map((_entity, index) => (
                      <tr key={index}>
                        <th>{_entity.key}</th>
                        <td>{`${_entity.value}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FeedbackDetails;
