import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '@components/shared/loaders/spinner/SpinnerLoader';
import { CourseModuleService } from '@services';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

function CourseModuleDetailViewComponent() {
  const { courseModuleId } = useParams();
  const { openSnackbar } = useSnackbar();

  const [entity, setEntity] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getEntityById = async () => {
    try {
      const entityService = new CourseModuleService();
      const entityResponse = await entityService.get({
        id: courseModuleId,
      });

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        setIsLoading(false);
        return;
      }

      setEntity(entityResponse.result);
      setIsLoading(false);
    } catch (error) {
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEntityById();
  }, [courseModuleId]);

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }

    return value.toString();
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-body">
          <div className="mb-4">
            <h2 className="text-center">{entity.name}</h2>
          </div>
          <div className="border-top pt-3">
            <div className="overflow-auto row table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(entity).map(([key, value]) => (
                    <tr key={key}>
                      <th>{key}</th>
                      <td>{renderValue(value)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseModuleDetailViewComponent;
