import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { InterestedListComponent } from '@components/pages';

function InterestedManagement() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Interesados');
  }, []);

  return (
    <div className="card">
      <InterestedListComponent />
    </div>
  );
}

export default InterestedManagement;
