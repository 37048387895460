import React from "react";

function FooterAdmin () {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            {new Date().getFullYear()} &copy; Todos los derechos reservados a <a href="">Emcodesa</a>
          </div>
          <div className="col-md-6">
            <div className="text-md-end footer-links d-none d-sm-block">
              <a href="/tutorials">Tutoriales</a>
              <a href="/help">Ayuda</a>
              <a href="/contact">Contáctanos</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterAdmin;