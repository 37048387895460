import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UserExamList } from '@components/pages';

function UserExamManagement() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Examenes asignados');
  }, []);

  return (
    <section className="container-fluid">
      <article className="row card">
        <UserExamList />
      </article>
    </section>
  );
}

export default UserExamManagement;
