import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CourseModuleService } from '@services';
import {
  CourseModuleCreateComponent,
  CourseModuleDeleteComponent,
  CourseModuleBlockComponent,
} from '@components/pages';
import PageTablePlaceholder from '@components/shared/loaders/placeholder/PageTablePlaceholder';
import PageTitlePlaceholder from '@components/shared/loaders/placeholder/PageTitlePlaceholder';
import PageDescriptionPlaceholder from '@components/shared/loaders/placeholder/PageDescriptionPlaceholder';
import { PopUp } from '@components/shared/popup/PopUp';
import { useSnackbar } from '@components/utilities/SnackbarProvider';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Box,
  Pagination,
} from '@mui/material';
import { DataGrid, GridToolbarQuickFilter, esES } from '@mui/x-data-grid';

const Loader = () => (
  <PageTablePlaceholder count={10}>
    <PageTitlePlaceholder count={1}>
      <PageDescriptionPlaceholder count={1} />
    </PageTitlePlaceholder>
  </PageTablePlaceholder>
);

const CourseModuleListComponent = () => {
  const { openSnackbar } = useSnackbar();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [EntitySelected, setEntitySelected] = useState(null);
  const [menuActionsAnchorElement, setMenuActionsAnchorElement] =
    useState(null);
  const [menuActionsSelected, setActionsSelected] = useState(null);
  const [isOpenCreateCourseModuleModal, setIsOpenCreateCourseModuleModal] =
    useState(false);
  const [isOpenBlockCourseModuleModal, setIsOpenBlockCourseModuleModal] =
    useState(false);
  const [isOpenDeleteCourseModuleModal, setIsOpenDeleteCourseModuleModal] =
    useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 7,
    page: 0,
  });

  const { pageSize, page } = paginationModel;

  const getAllEntities = async () => {
    try {
      const entityService = new CourseModuleService();
      const entityResponse = await entityService.get({
        all: true,
      });

      if (!entityResponse || !entityResponse.success) {
        openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
        return;
      }

      setEntities(entityResponse.result);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      openSnackbar('Algo salió mal, por favor intente de nuevo.', 'error');
      setIsLoading(false);
      return null;
    }
  };

  useEffect(() => {
    getAllEntities();
  }, []);

  const handleDateFormat = (entityDate) => {
    const date = new Date(Number(entityDate));

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const itemOnSuccessCreated = (entity) => {
    setEntities((prevState) => {
      const newEntities = [...prevState, entity];

      return newEntities;
    });
  };

  const itemOnSuccessBlocked = (entity) => {
    setEntities((prevState) =>
      prevState.map((obj) => {
        if (obj.id === entity.id) {
          return { ...obj, status: entity.status };
        }

        return obj;
      }),
    );
  };

  const itemOnSuccessDeleted = (entity) => {
    setEntities((prevState) => {
      const newEntities = prevState.filter(
        (_entity) => _entity.id !== entity.id,
      );

      return newEntities;
    });
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'create':
        itemOnSuccessCreated(entity);
        openSnackbar('El módulo ha sido creado satisfactoriamente', 'success');
        break;
      case 'block':
        itemOnSuccessBlocked(entity);
        openSnackbar('El módulo ha sido bloqueado correctamente.', 'success');
        break;
      case 'delete':
        itemOnSuccessDeleted(entity);
        openSnackbar('El módulo ha sido eliminado correctamente.', 'success');
        break;
    }
  };

  const closeMenuActions = () => {
    setActionsSelected(null);
    setMenuActionsAnchorElement(null);
  };

  const actionsMenuOnClick = (selector, anchorElement) => {
    setActionsSelected(selector || null);
    setMenuActionsAnchorElement(anchorElement || null);
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'block':
        setIsOpenBlockCourseModuleModal(true);
        setEntitySelected(entity);
        break;
      case 'delete':
        setIsOpenDeleteCourseModuleModal(true);
        setEntitySelected(entity);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setRows(
      entities.map((item) => {
        const dateFormated = handleDateFormat(item.last_modification);
        return {
          ...item,
          course: item.course.name || item.course,
          last_modification: dateFormated,
          status: item.status.title,
        };
      }),
    );
  }, [entities]);

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 1, minWidth: 100 },
    { field: 'course', headerName: 'Curso', flex: 1, minWidth: 100 },
    {
      field: 'last_modification',
      headerName: 'Modificado',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: 'Estado',
      type: 'string',
      width: 150,
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return (
          <span className="badge bg-info fs-6 p-1">{params.row.status}</span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'string',
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      renderCell: (params) => {
        const menuItemOnClick = (event, action) => {
          event?.stopPropagation();

          itemOnAction(action, params.row);
        };

        return (
          <>
            <IconButton
              disableRipple
              data-testid="user-action-menu-btn"
              aria-label="actions button"
              id={`basic-menu-${params.row?.id}`}
              aria-haspopup="true"
              onClick={(event) =>
                actionsMenuOnClick(
                  `basic-menu-${params.row?.id}`,
                  event.currentTarget,
                )
              }
            >
              <i className="mdi mdi-dots-vertical fs-4"></i>
            </IconButton>
            <Menu
              elevation={1}
              id={'basic-menu-' + params.row?.id}
              anchorEl={menuActionsAnchorElement}
              open={menuActionsSelected === `basic-menu-${params.row?.id}`}
              onClose={closeMenuActions}
              MenuListProps={{
                'aria-labelledby': 'user-action-menu-btn',
              }}
            >
              <MenuItem
                component={Link}
                to={`/admin/course/module/detail/${params.row.id}`}
                target="_blank"
                onClick={(event) => {
                  closeMenuActions();
                }}
                data-testid="user-resend-invitation-action-btn"
              >
                <i className="fe-search me-1"></i>
                <ListItemText>Ver/Editar</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={(event) => {
                  menuItemOnClick(event, 'block');
                  closeMenuActions();
                }}
                data-testid="user-edit-action-btn"
              >
                <i className="fe-shield me-1"></i>
                <ListItemText>Bloquear módulo</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={(event) => {
                  menuItemOnClick(event, 'delete');
                  closeMenuActions();
                }}
              >
                <i className="fe-trash me-1"></i>
                <ListItemText>Eliminar módulo</ListItemText>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const handlePage = (event, value) => {
    setPaginationModel({ ...paginationModel, page: value - 1 });
  };

  const handlePageSizeChange = (e) => {
    setPaginationModel({ page: 0, pageSize: Number(e.target.value) });
  };

  const CustomPagination = () => (
    <div className="col-12 d-flex justify-content-center mt-3 mb-2">
      <Pagination
        count={Math.ceil(rows.length / pageSize)}
        page={page + 1}
        onChange={handlePage}
        variant="outlined"
        color="primary"
      />
    </div>
  );

  function CustomSearchToolbar() {
    return (
      <div className="mb-3 col-12 d-flex justify-content-between mt-3">
        <div className="d-flex justify-content-between mb-3 ms-3">
          <div className="d-flex align-items-center">
            <label htmlFor="resultsPerPage" className="form-label me-2">
              Resultados:
            </label>
            <select
              className="form-select"
              id="resultsPerPage"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={7}>7</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
        <GridToolbarQuickFilter
          className="me-3 border-1"
          placeholder="Buscar..."
        />
      </div>
    );
  }

  return (
    <div className="card-body">
      <PopUp
        title="Crear módulo"
        data-testid="create-module-modal"
        id="create-module-modal"
        isOpen={isOpenCreateCourseModuleModal}
        setIsOpen={setIsOpenCreateCourseModuleModal}
        styles={{ width: '70%' }}
      >
        <Box sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <CourseModuleCreateComponent
            onUpdatedEntity={onUpdatedEntity}
            setIsOpen={setIsOpenCreateCourseModuleModal}
          />
        </Box>
      </PopUp>
      <PopUp
        data-testid="block-module-modal"
        id="block-module-modal"
        isOpen={isOpenBlockCourseModuleModal}
        setIsOpen={setIsOpenBlockCourseModuleModal}
        styles={{ width: '40%' }}
      >
        <Box sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <CourseModuleBlockComponent
            onUpdatedEntity={onUpdatedEntity}
            entitySelected={EntitySelected}
            setIsOpen={setIsOpenBlockCourseModuleModal}
          />
        </Box>
      </PopUp>
      <PopUp
        data-testid="delete-module-modal"
        id="delete-module-modal"
        isOpen={isOpenDeleteCourseModuleModal}
        setIsOpen={setIsOpenDeleteCourseModuleModal}
        styles={{ width: '40%' }}
      >
        <Box sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <CourseModuleDeleteComponent
            onUpdatedEntity={onUpdatedEntity}
            entitySelected={EntitySelected}
            setIsOpen={setIsOpenDeleteCourseModuleModal}
          />
        </Box>
      </PopUp>
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          {(!entities || entities.length === 0) && (
            <div className="col-12 col-xl-8 col-lg-10 mx-auto">
              <div className="container pt-2 text-center">
                <h3 className="text-center">
                  No existen datos, crea un Módulo.
                </h3>
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/private/img/empty-content.svg`}
                  alt="empty content"
                  className="img-fluid mx-auto d-block"
                />
                <button
                  type="button"
                  className="btn btn-primary text-wrap mt-3"
                  onClick={() => setIsOpenCreateCourseModuleModal(true)}
                >
                  Click aquí para crear un Módulo
                </button>
              </div>
            </div>
          )}

          {entities && entities.length > 0 && (
            <div className="pt-2">
              <article className="mb-3 d-flex">
                <h4 className="card-title mt-3 ps-3 flex-grow-1">
                  Lista de Módulos
                </h4>
                <button
                  type="button"
                  className="btn btn-primary my-2 me-3"
                  onClick={() => setIsOpenCreateCourseModuleModal(true)}
                >
                  Crear módulo
                </button>
              </article>

              <Box sx={{ height: 600 }} className="w-100">
                <DataGrid
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={rows}
                  columns={columns}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  disableRowSelectionOnClick
                  slots={{
                    toolbar: CustomSearchToolbar,
                    pagination: CustomPagination,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Box>
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default CourseModuleListComponent;
