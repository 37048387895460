import React, { useEffect, useState } from 'react';
import { UserService } from '@services/index';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

async function createEntity(payload, Entity) {
  const entityService = new Entity();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const UserCreate = (props) => {
  const { setIsOpen, onUpdatedEntity } = props;
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [entity, setEntity] = useState({
    dni: '',
    email: '',
    phone: '',
    password: '',
    firstname: '',
    lastname: '',
    role: { id: 0 },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityService = new UserService();
        const entityResponse = await entityService.get({
          roles: true,
        });

        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Ocurrio un error obteniendo los roles', 'error');
          return;
        }

        const result = entityResponse.result;
        setRoles(Object.values(result));
      } catch (error) {
        console.log(error);
        openSnackbar('Ha ocurrido un error', 'error');
      }
    };

    fetchData();
  }, []);

  const confirmOnClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!entity.identity) {
        openSnackbar('Provee al menos una identidad', 'error');
        return;
      }

      const response = await createEntity(entity, UserService);

      if (!response || !response.success) {
        openSnackbar('Ocurrio un error al crear la entidad', 'error');
        return;
      }

      onUpdatedEntity(response.result);
      setIsOpen(false);
      resetForm();
    } catch (error) {
      console.error(error);
      openSnackbar('Ha ocurrido un error', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const selectRoleOnClick = (event) => {
    const { value } = event.target;

    if (!value) {
      openSnackbar('Seleccion un rol', 'error');
      return;
    }

    const role = roles.find((rle) => rle.id === +value);
    setEntity((prevEntity) => ({ ...prevEntity, role }));
  };

  function resetForm() {
    setEntity({
      identity: '',
      dni: '',
      email: '',
      phone: '',
      password: '',
      firstname: '',
      lastname: '',
      occupation: '',
      city: '',
      role: { id: 0 },
    });
  }

  const cancelOnClick = () => {
    resetForm();
    setIsOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEntity((prevEntity) => ({ ...prevEntity, [name]: value }));
  };

  return (
    <div className="card card-body">
      <form className="mt-3">
        <div className="row mb-3">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Email</label>
            <input
              type="email"
              autoComplete="off"
              className="form-control"
              id="email"
              name="email"
              placeholder="email@gmail.com"
              value={entity.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Constraseña</label>
            <input
              type="password"
              autoComplete="off"
              className="form-control"
              id="password"
              name="password"
              placeholder=""
              value={entity.password}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Nombres</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="firstname"
              placeholder=""
              value={entity.firstname}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Apellidos</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              id="name-input"
              name="lastname"
              placeholder=""
              value={entity.lastname}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">
              Identificación (Identificación nacional)
            </label>
            <input
              type="number"
              className="form-control"
              autoComplete="off"
              id="dni-input"
              name="identity"
              placeholder="Solo números"
              value={entity.identity}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Celular</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="phone"
              placeholder=""
              value={entity.phone}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Profesión</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="occupation"
              placeholder=""
              value={entity.occupation}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label className="form-label">Rol</label>
            <select
              className="form-control"
              value={entity.role.id}
              onChange={selectRoleOnClick}
            >
              <option value="0" disabled>
                Selecciona un rol...
              </option>
              {roles.map(({ id, title }) => (
                <option value={id} key={id}>
                  {title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12">
            <label className="form-label">Ciudad</label>
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              name="city"
              placeholder=""
              value={entity.city}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="mt-4">
          {isLoading ? (
            <button className="btn btn-primary" disabled type="button">
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <>
              <button
                title="Submit"
                type="submit"
                className="btn btn-primary btn-action mr-2"
                onClick={confirmOnClick}
              >
                <i className="fe-check"></i> Crear nuevo usuario
              </button>
              <button
                title="Cancel"
                type="button"
                className="btn btn-light btn-action ml-2"
                onClick={cancelOnClick}
              >
                <i className="fe-x"></i> Cancelar
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserCreate;
