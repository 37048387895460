import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  CertificationEdit,
  CertificationDetail,
} from '@components/pages/index';

function CertificationDetailView() {
  const [isEditMode, setIsEditMode] = useState(false);

  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Certificaciones');
  }, []);

  const handleEditChange = () => {
    setIsEditMode((prevEditMode) => !prevEditMode);
  };
  return (
    <article className="col-12">
      <section className="card">
        <section className="card-body">
          <header className="row">
            <section className="col-12 d-flex justify-content-end">
              <div className="form-check form-switch d-flex justify-content-center col-2 col-sm-1">
                <input
                  onChange={handleEditChange}
                  className="form-check-input form-control fs-4"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isEditMode}
                />
              </div>
              <label className="form-check-label col-3 col-sm-2 d-flex justify-content-start align-items-center">
                Modo Edición
              </label>
            </section>
          </header>
          <section className="row">
            {isEditMode ? <CertificationEdit setIsEditMode={setIsEditMode} /> : <CertificationDetail />}
          </section>
        </section>
      </section>
    </article>
  );
}

export default CertificationDetailView;
