import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ExamService, UserExamService, UserService } from '@services';
import { useAuth } from '@hooks/useAuth.hook';
import Alert from '@components/shared/alert/Alert';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.get(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }
  return entityResponse.result;
}
const ExamDetail = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    view: 'lobby',
    userexam: null,
  });

  const [time, setTime] = useState({
    mins: 0,
    secs: 0,
  });

  const [entity, setEntity] = useState({
    examEntity: {},
    status: {},
    questions: [],
  });

  const [isAlertActive, setIsAlertActive] = useState(null);
  const [isconfirm, setIsConfirm] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [examSubmitted, setExamSubmitted] = useState(false);

  useEffect(() => {
    if (isconfirm === false) {
      setIsAlertActive(null);
      setIsConfirm(null);
    }
  }, [isconfirm, isAlertActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [entityResponseExam, entityResponseUser, entityResponseUserExam] =
          await Promise.all([
            getEntity(
              {
                id: id,
              },
              ExamService,
            ),
            getEntity(
              {
                id: user.id,
              },
              UserService,
            ),
            getEntity(
              {
                userId: user.id,
              },
              UserExamService,
            ),
          ]);

        if (!entityResponseExam) {
          openAlert('Examen no encontrado.', '', 'error', true);
          return;
        }

        if (entityResponseExam.result.questions === '[]') {
          openAlert(
            'El Examen no tiene preguntas asignadas',
            'activa modo edicion para agregar',
            'warning',
            true,
          );
          return;
        }

        const findUserExam = () => {
          return entityResponseUserExam.result.find(
            (exam) => exam.exam_id === id,
          );
        };

        if (!entityResponseUserExam.result.length || !findUserExam()) {
          openAlert(
            '¡Aún no tienes evaluación asignada!',
            'Debes notificar a la dirección para q te asignen una evaluación',
            'warning',
            true,
          );
        }

        digestQuestions(entityResponseExam.result);
        digestTimeLimit(entityResponseExam.result);
        setModel((prevData) => ({
          ...prevData,
          user: entityResponseUser.result,
        }));

        setModel((prevData) => ({
          ...prevData,
          userexam: findUserExam(),
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      if (model.timeSecondsInterval) {
        clearInterval(model.timeSecondsInterval);
      }
      if (model.timeMinutesInterval) {
        clearInterval(model.timeMinutesInterval);
      }
    };
  }, [id]);

  const openAlert = (title, message, icon, isSuccess) => {
    setAlertMessage((prevData) => ({
      ...prevData,
      title,
      message,
      icon,
    }));
    setIsAlertActive(isSuccess);
  };
  const updateUserExam = async (userexam) => {
    if (!model.userexam) {
      openAlert(
        '¡Aún no tienes evaluación asignada!',
        'Debes notificar a la dirección para q te asignen una evaluación',
        'warning',
        true,
      );
      return;
    }

    const entityResult = await updateEntity(userexam, UserExamService);

    if (!entityResult) {
      openAlert('Ocurrio un error al enviar el examen', '', 'error', true);
      return;
    }
  };

  const attemptOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    window.location.reload();
  };

  const orderedQuestions = useMemo(() => {
    return entity.questions.sort((a, b) => +a.order - +b.order);
  }, [entity.questions]);

  const startExamOnClick = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (model.userexam.status.name === 'passedexam') {
      openAlert(
        'Ya aprobaste el examen',
        'Si quieres volver a intentarlo, comunicate con nosotros.',
        'success',
        true,
      );
      return;
    }

    if (+model.userexam.attempts >= +entity.examEntity.max_attempts) {
      const updateDataExam = {
        ...model.userexam,
        status: { id: 9, name: 'maxattempts', title: 'Máximo intentos' },
      };

      updateUserExam(updateDataExam);
      openAlert(
        'No tienes más intentos',
        'Has alcanzado el máximo de intentos.',
        'info',
        true,
      );

      return;
    }

    const updateDataExam = {
      ...model.userexam,
      attempts: model.userexam.attempts++ || 0,
      last_date_attempt: new Date().getTime() + '',
    };

    updateUserExam(updateDataExam);
    if (!examSubmitted) {
      setModel((prevData) => {
        if (prevData.timeSecondsInterval) {
          clearInterval(prevData.timeSecondsInterval);
        }

        const newInterval = setInterval(() => {
          setTime((prevTime) => {
            if (prevTime.secs <= 0) {
              return {
                ...prevTime,
                secs: 60,
              };
            }
            return {
              ...prevTime,
              secs: prevTime.secs - 1,
            };
          });
        }, 1000);

        return {
          ...prevData,
          view: 'exam',
          timeSecondsInterval: newInterval,
        };
      });

      setModel((prevData) => ({
        ...prevData,
        timeMinutesInterval: setInterval(() => {
          setTime((prevTime) => {
            if (prevTime.mins <= 0) {
              if (!examSubmitted) {
                gradeExamOnClick();
                clearInterval(prevData.timeMinutesInterval);
                return prevTime;
              }
            }
            return {
              ...prevTime,
              mins: prevTime.mins - 1,
            };
          });
        }, 1000 * 60),
      }));
    } else {
      setModel((prevData) => ({
        ...prevData,
        view: 'results',
      }));
    }
  };
  const gradeExamOnClick = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    gradeExam();
    setExamSubmitted(true);
  };
  const gradeExam = () => {
    setModel((prevData) => ({
      ...prevData,
      view: 'results',
    }));
    const newEntity = {
      ...entity,
      score: 0,
      incorrect_questions: 0,
      passed: false,
    };

    entity.questions.forEach((question) => {
      question.isCorrect = false;
      question.answerTokens = question.answer.split('|');
      let hits = 0;

      // Iterate over options
      for (let i = 0; i < question.items.length; i++) {
        const option = question.items[i];
        if (option.selected) {
          // has an hit
          if (
            question.answer
              .toLocaleLowerCase()
              .includes(indexToLetter(option.index).toLocaleLowerCase())
          ) {
            hits += 1;
          } else {
            question.isCorrect = false;
            break;
          }
        } else {
          // Option is not selected
          // If option is a valid answer
          if (
            question.answer
              .toLocaleLowerCase()
              .includes(indexToLetter(option.index).toLocaleLowerCase())
          ) {
            question.isCorrect = false;
            break;
          }
        }
      }

      if (question.answerTokens.length === hits) {
        question.isCorrect = true;
      }

      if (question.isCorrect) {
        newEntity.score += +question.score;
      } else if (!question.isCorrect && question.type !== 'textblock') {
        newEntity.incorrect_questions += 1;
      }
    });

    if (+newEntity.score >= +entity.examEntity.min_score) {
      setEntity((prevData) => ({
        ...prevData,
        score: newEntity.score,
        incorrect_questions: newEntity.incorrect_questions,
        passed: true,
      }));
      const updateDataExam = {
        ...model.userexam,
        status: { id: 7, name: 'passedexam', title: 'Examen aprobado' },
        last_score: newEntity.score,
        questions: JSON.stringify(entity.questions),
      };

      updateUserExam(updateDataExam);
    } else {
      setEntity((prevData) => ({
        ...prevData,
        score: newEntity.score,
        incorrect_questions: newEntity.incorrect_questions,
        passed: false,
      }));
      const updateDataExam = {
        ...model.userexam,
        status: { id: 8, name: 'examreprobated', title: 'Examen reprobado' },
        last_score: +entity.score,
        questions: JSON.stringify(entity.questions),
      };
      updateUserExam(updateDataExam);
    }
  };

  const getHtml = (body) => {
    try {
      if (!body) {
        return '';
      }

      return window.decodeURI(body);
    } catch (error) {
      return body;
    }
  };
  const indexToLetter = (index) => {
    var alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    index += 1;
    var printToLetter = function (number) {
      var charIndex = number % alphabet.length;
      var quotient = number / alphabet.length;
      if (charIndex - 1 === -1) {
        charIndex = alphabet.length;
        quotient--;
      }
      result = alphabet.charAt(charIndex - 1) + result;
      if (quotient >= 1) {
        printToLetter(parseInt(quotient));
      }
    };

    printToLetter(index);
    return result;
  };

  const digestQuestions = (entity) => {
    if (!entity || !entity.questions) {
      setEntity((prevData) => ({
        ...prevData,
        questions: [{ items: [{ body: '' }] }],
      }));

      return;
    }

    const questionsTemp = JSON.parse(entity.questions);

    if (questionsTemp && questionsTemp.length) {
      questionsTemp.forEach((question) => {
        question.items.forEach((item, index) => {
          item.index = index;
          item.selected = false;
        });
      });
    }
    setEntity((prevData) => ({
      ...prevData,
      questions: questionsTemp,
      examEntity: entity,
    }));
  };

  const digestTimeLimit = (entity) => {
    if (entity.time_limit > 0) {
      setTime((prevData) => ({
        ...prevData,
        mins: entity.time_limit,
        secs: 60,
      }));
    }
  };
  const handleCheckboxChange = (questionIndex, optionIndex) => {
    setEntity((prevData) => {
      const updatedQuestions = [...prevData.questions];
      const updatedOptions = [...updatedQuestions[questionIndex].items];
      updatedOptions[optionIndex].selected =
        !updatedOptions[optionIndex].selected;
      updatedQuestions[questionIndex].items = updatedOptions;
      return {
        ...prevData,
        questions: updatedQuestions,
      };
    });
  };

  return (
    <>
      {isAlertActive !== null && isAlertActive && (
        <Alert
          config={{
            title: alertMessage.title,
            description: alertMessage.message,
            typeIcon: alertMessage.icon,
            showCancelButton: false,
          }}
          setConfirm={setIsConfirm}
        />
      )}
      {/* Lobby */}
      <div className="col-12 col-md-10 grid-margin-md-0 mx-auto d-block">
        {model.view === 'lobby' && (
          <>
            <div className="mb-0">
              <h2 className="text-center">{entity.examEntity.name}</h2>
            </div>
            <div className="pt-3">
              <h5 className="text-center">
                ¿Estás listo para iniciar la evaluación?
              </h5>
              <p className="text-center">
                Recuerda la siguiente información que te será útil
              </p>

              <table className="table table-hover">
                <tbody>
                  <tr>
                    <th scope="row">Intentos máximos</th>
                    <td>{entity.examEntity.max_attempts}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tiempo máximo</th>
                    <td>{entity.examEntity.time_limit} mins</td>
                  </tr>
                  <tr>
                    <th scope="row">Puntaje mínimo para aprobar</th>
                    <td>{entity.examEntity.min_score}</td>
                  </tr>
                </tbody>
              </table>

              <div className="d-flex justify-content-center mb-4 mt-5">
                {model.userexam && model.user.id && (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => startExamOnClick(e)}
                  >
                    Iniciar
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Exam name */}
      <div className="col-12   mx-auto d-block">
        {(model.view === 'exam' || model.view === 'results') && (
          <div className="mb-0">
            <h2 className="text-center">{entity.examEntity.name}</h2>
          </div>
        )}
      </div>

      {/* Exam Results */}
      <div className="col-12 mx-auto d-block">
        {model.view === 'results' && (
          <>
            <div className="mb-0">
              <h4 className="text-center">
                Tuviste {entity.score} puntos en el examen
              </h4>
              {entity.incorrect_questions > 0 && (
                <h6 className="text-center text-danger">
                  Y tuviste {entity.incorrect_questions} preguntas erróneas
                </h6>
              )}

              <div className="mb-3 mt-4 text-center">
                {model.view === 'results' &&
                  entity.incorrect_questions > 0 &&
                  !entity.passed && (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => attemptOnClick(e)}
                    >
                      Tomar examen de nuevo
                    </button>
                  )}
              </div>
              <div className="mb-3 mt-4 text-center">
                {model.view === 'results' &&
                  entity.incorrect_questions > 0 &&
                  entity.passed && (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => attemptOnClick(e)}
                    >
                      Intentarlo de nuevo
                    </button>
                  )}
              </div>
              <div className="mb-3 mt-4 text-center">
                {model.view === 'results' && entity.passed && (
                  <a href="/student/dashboar" className="btn btn-primary">
                    Seguir aprendiendo
                  </a>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Exam questions */}
      <div className="col-12  mx-auto d-block">
        {model.view === 'exam' && (
          <>
            {orderedQuestions.map((_entity, index) => {
              if (_entity.type === undefined || _entity.type === 'question') {
                return (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="pt-3">
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getHtml(_entity.question_statement),
                            }}
                          ></span>
                        </p>
                        {_entity.items.map((_option, j) => (
                          <div
                            className="form-check mb-3 form-check-primary"
                            key={j}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input fs-4"
                              checked={_option.selected}
                              onChange={() => handleCheckboxChange(index, j)}
                              id={`question-${index}-option-${j}`}
                            />
                            <label
                              className="form-check-label text-black ms-1 fs-4"
                              htmlFor={`question-${index}-option-${j}`}
                            >
                              {indexToLetter(j)}) {_option.body}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              } else if (_entity.type === 'textblock') {
                return (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="pt-3">
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getHtml(_entity.question_statement),
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </>
        )}
      </div>

      {/* Exam question results */}
      {orderedQuestions.map((_entity, index) => {
        if (
          model.view === 'results' &&
          (_entity.type === undefined || _entity.type === 'question')
        ) {
          return (
            <div className="col-12 mx-auto d-block" key={index}>
              <div className="card">
                <div className="card-body">
                  <div className="pt-3">
                    <p>
                      <span
                        key={`question-${index}`}
                        dangerouslySetInnerHTML={{
                          __html: getHtml(_entity.question_statement),
                        }}
                      ></span>
                    </p>
                    {!_entity.isCorrect && (
                      <p>
                        Las respuestas correctas son:
                        <span className="ms-1">
                          {_entity.answerTokens.map((_answerToken) => (
                            <span key={`answer-${_answerToken}`}>
                              {_answerToken},
                            </span>
                          ))}
                        </span>
                      </p>
                    )}
                    {_entity.items.map((_option, j) => (
                      <div
                        className={`form-check ${
                          _entity.isCorrect
                            ? 'form-check-success'
                            : 'form-check-danger'
                        } `}
                        key={`option-${j}`}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input fs-4 "
                          checked={_option.selected}
                          disabled
                          id={`"question-" + ${index} + "-option-" + ${j}`}
                        />
                        <label
                          className="custom-control-label ms-1 fs-4"
                          htmlFor={`"question-" + ${index} + "-option-" + ${j}`}
                        >
                          {indexToLetter(j)}) {_option.body}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="question-overlay"></div>
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}

      {/* Grade exam */}
      <div className="col-12  mx-auto d-block">
        {model.view === 'exam' && (
          <div className="card">
            <div className="d-flex justify-content-center mb-2 mt-3">
              <button
                className="btn btn-primary"
                onClick={(e) => gradeExamOnClick(e)}
              >
                Calificar
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Exam Results */}
      <div className="col-12 mx-auto d-block">
        {model.view === 'results' && (
          <div className="card">
            <div className="mb-0">
              <h4 className="text-center">
                Tuviste {entity.score} puntos en el examen
              </h4>
              {entity.incorrect_questions > 0 && (
                <h6 className="text-center text-danger">
                  Y tuviste {entity.incorrect_questions} preguntas erróneas
                </h6>
              )}

              <div className="mb-3 mt-4 text-center">
                {model.view === 'results' &&
                  entity.incorrect_questions > 0 &&
                  !entity.passed && (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => attemptOnClick(e)}
                    >
                      Tomar examen de nuevo
                    </button>
                  )}
              </div>
              <div className="mb-3 mt-4 text-center">
                {model.view === 'results' &&
                  entity.incorrect_questions > 0 &&
                  entity.passed && (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => attemptOnClick(e)}
                    >
                      Intentarlo de nuevo
                    </button>
                  )}
              </div>
              <div className="mb-3 mt-4 text-center">
                {model.view === 'results' && entity.passed && (
                  <a href="/student/dashboard" className="btn btn-primary">
                    Seguir aprendiendo
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Counter time */}
      <div className="col-4 col-md-1 position-fixed top-3 end-0 me-md-4  rounded-circle  ">
        {model.view === 'exam' && (
          <div className="card mt-4 bg-secondary">
            <div className="">
              <div className="d-flex justify-content-center">
                <h2 className="text-center text-white">
                  {time.mins}:{time.secs}
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExamDetail;
