import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CertificationService } from '@services';
import Alert from '@components/shared/alert/Alert';

async function getEntity(payload, entities) {
  const entityService = new entities();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result || !entityResponse.success) {
    return null;
  }

  return entityResponse;
}

function CertificationDetail() {
  const { id } = useParams();

  const [model, setModel] = useState({
    user: { role: { name: 'regular' } },
    entity: {
      status: {},
      user: {},
      course: {},
    },
  });

  const [isAlertActive, setIsAlertActive] = useState(null);
  const [isconfirm, setIsConfirm] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (isconfirm === false) {
      setIsAlertActive(null);
      setIsConfirm(null);
    }
  }, [isconfirm, isAlertActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [entityResponseCertification] = await Promise.all([
          getEntity(
            {
              queryselector: 'certification',
              id: id,
            },
            CertificationService,
          ),
        ]);

        if (!entityResponseCertification) {
          openAlert('Certificado no encontrado.', '', 'error', true);
          return;
        }

        setModel((prevData) => ({
          ...prevData,
          entity: entityResponseCertification.result,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const openAlert = (title, message, icon, isSuccess) => {
    setAlertMessage((prevData) => ({
      ...prevData,
      title,
      message,
      icon,
    }));
    setIsAlertActive(isSuccess);
  };

  return (
    <>
      {isAlertActive !== null && isAlertActive && (
        <Alert
          config={{
            title: alertMessage.title,
            description: alertMessage.message,
            typeIcon: alertMessage.icon,
            showCancelButton: false,
          }}
          setConfirm={setIsConfirm}
        />
      )}
      <div className="col-12  mx-auto d-block mt-3">
        <div className="mb-4">
          <h4 className="text-center fs-3">Constancia que</h4>
          <h2 className="text-center fs-1">
            {model.entity.user?.firstname} {model.entity.user?.lastname}
          </h2>
          <h6 className="text-center fs-5 mt-3">
            Ha finalizado el curso "{model.entity.course.name}" en la fecha{' '}
            {new Date(+model.entity.date_creation).getDate() +
              '/' +
              (new Date(+model.entity.date_creation).getMonth() + 1) +
              '/' +
              new Date(+model.entity.date_creation).getFullYear()}
          </h6>
        </div>

        {/* Files */}
        <section className="d-flex flex-column justify-content-center">
          {model.entity.attachments
            ? model.entity.attachments.map((_entity, index) => (
                <div key={index} className="d-flex justify-content-center">
                  <a
                    className="btn btn-primary my-3"
                    target="_blank"
                    rel="noreferrer"
                    href={_entity.uri}
                  >
                    Descargar adjunto
                  </a>
                </div>
              ))
            : null}
        </section>
      </div>
    </>
  );
}

export default CertificationDetail;
