import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CourseListComponent } from '@components/pages';

function CourseManagement() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Cursos');
  }, []);

  return (
    <div className="card">
      <CourseListComponent />
    </div>
  );
}

export default CourseManagement;
