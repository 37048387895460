import React, { useEffect, useState } from 'react';
import { FeedbackService } from '@services/index';
import { FeedbackCreate, FeedbackDelete } from '@components/pages';
import { PopUp } from '@components/shared/popup/PopUp';
import FeedbackDataTable from './components/FeedbackDataTable';
import PageTablePlaceholder from '@components/shared/loaders/placeholder/PageTablePlaceholder';
import PageTitlePlaceholder from '@components/shared/loaders/placeholder/PageTitlePlaceholder';
import PageDescriptionPlaceholder from '@components/shared/loaders/placeholder/PageDescriptionPlaceholder';
import { useSnackbar } from '@components/utilities/SnackbarProvider';

const Loader = () => (
  <PageTablePlaceholder count={4}>
    <PageTitlePlaceholder count={1}>
      <PageDescriptionPlaceholder count={1} />
    </PageTitlePlaceholder>
  </PageTablePlaceholder>
);

function FeedbackList() {
  const { openSnackbar } = useSnackbar();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreateUserModal, setIsOpenCreateUserModal] = useState(false);
  const [isOpenDelteFeedbackModal, setIsOpenDeleteFeedbackModal] =
    useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entityService = new FeedbackService();
        const entityResponse = await entityService.get({
          all: true,
        });

        if (!entityResponse || !entityResponse.success) {
          openSnackbar('Error al obtener las recomendaciones', 'error');
          return;
        }

        setEntities(entityResponse.result);
      } catch (error) {
        console.log(error);
        openSnackbar('Ocurrio un error', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteEntity = (entity) => {
    setEntities((prevState) => {
      const newEntities = prevState.filter(
        (_entity) => _entity.id !== entity.id,
      );

      return newEntities;
    });
  };

  const handleCreateEntity = (entity) => {
    setEntities((prevEntities) => [...prevEntities, entity]);
  };

  const onUpdatedEntity = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateUserModal(false);
        handleCreateEntity(entity);
        openSnackbar('Recomendacion creada con exito!', 'success');
        break;
      case 'delete':
        setIsOpenDeleteFeedbackModal(false);
        handleDeleteEntity(entity);
        openSnackbar('Eliminación exitosa!', 'success');
        break;
      default:
        break;
    }
  };

  const itemOnAction = (action, entity) => {
    switch (action) {
      case 'create':
        setIsOpenCreateUserModal(true);
        break;
      case 'delete':
        setIsOpenDeleteFeedbackModal(true);
        setSelectedEntity(entity);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {(!entities || !entities.length) && (
            <div className="col-12 col-xl-8 col-lg-10 mx-auto d-block">
              <div className="card-body">
                <div className="container pt-2 text-center">
                  <h3 className="text-center mb-4">
                    No existen datos, crea una Recomendación.
                  </h3>

                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/private/img/empty-content.svg`}
                    alt="empty content"
                    className="d-block mx-auto w-50 img-fluid"
                  />

                  <button
                    type="button"
                    className="btn btn-primary my-2 me-3"
                    onClick={() => itemOnAction('create')}
                  >
                    Click aquí para crear una recomendación
                  </button>
                </div>
              </div>
            </div>
          )}

          {entities && entities.length > 0 && (
            <div className="pt-2 card-body">
              <article className="mb-3 d-flex">
                <h4 className="card-title mt-3 me-auto">
                  Lista de Recomendaciones
                </h4>
                <button
                  type="button"
                  className="btn btn-primary my-2 me-3"
                  onClick={() => itemOnAction('create')}
                >
                  Crear nuevo
                </button>
              </article>
              <div className="table-responsive mt-3">
                <FeedbackDataTable
                  data={entities}
                  setData={setEntities}
                  itemOnAction={itemOnAction}
                />
              </div>
            </div>
          )}
        </>
      )}
      <PopUp
        title="Crear una nueva recomendación"
        data-testid="create-feedback-modal"
        id="create-feedback-modal"
        isOpen={isOpenCreateUserModal}
        setIsOpen={setIsOpenCreateUserModal}
        styles={{ width: '50%' }}
      >
        <>
          <FeedbackCreate
            setIsOpen={setIsOpenCreateUserModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('create', entity)}
          />
        </>
      </PopUp>
      <PopUp
        data-testid="delete-feedback-modal"
        id="delete-feedback-modal"
        isOpen={isOpenDelteFeedbackModal}
        setIsOpen={setIsOpenDeleteFeedbackModal}
        styles={{ width: 700 }}
      >
        <>
          <FeedbackDelete
            setIsOpen={setIsOpenDeleteFeedbackModal}
            onUpdatedEntity={(entity) => onUpdatedEntity('delete', entity)}
            selectedEntity={selectedEntity}
          />
        </>
      </PopUp>
    </>
  );
}

export default FeedbackList;
